import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {map, switchMap} from 'rxjs';

import {UserService} from '../../services/user.service';
import {userActions} from '../actions';

@Injectable()
export class UserEffects {
  constructor(
    private actions$: Actions,
    private userService: UserService
  ) {}

  loadUserBalance = createEffect(() =>
    this.actions$.pipe(
      ofType(userActions.setUser),
      map((action) => {
        return userActions.loadUserBalance();
      })
    )
  );

  loadUserBalanceSuccess = createEffect(() =>
    this.actions$.pipe(
      ofType(userActions.loadUserBalance),
      switchMap((action) => {
        return this.userService.getUserBalance().pipe(
          map((result) => {
            return userActions.loadUserBalanceSuccess({balance: result});
          })
        );
      })
    )
  );

  loadUserAgreement = createEffect(() =>
    this.actions$.pipe(
      ofType(userActions.setUser),
      map((action) => {
        return userActions.loadUserAgreement();
      })
    )
  );

  loadUserAgreementSuccess = createEffect(() =>
    this.actions$.pipe(
      ofType(userActions.loadUserAgreement),
      switchMap((action) => {
        return this.userService.getUserAgreement().pipe(
          map((result) => {
            return userActions.loadUserAgreementSuccess({agreement: result});
          })
        );
      })
    )
  );

  loadUserPaymentsBlocks = createEffect(() =>
    this.actions$.pipe(
      ofType(userActions.setUser),
      map((action) => {
        return userActions.loadUserPaymentsBlocks();
      })
    )
  );

  loadUserPaymentsBlocksSuccess = createEffect(() =>
    this.actions$.pipe(
      ofType(userActions.loadUserPaymentsBlocks),
      switchMap((action) => {
        return this.userService.getUserPaymentsBlocks().pipe(
          map((result) => {
            return userActions.loadUserPaymentsBlocksSuccess({paymentsBlocks: result});
          })
        );
      })
    )
  );
}
