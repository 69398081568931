import {createReducer, on} from '@ngrx/store';

import {userActions} from '../actions';
import {initialUserState, UserState} from '../state/user.state';

export const userReducers = createReducer(
  initialUserState,
  on(userActions.setUser, (state: UserState, {user}) => {
    return {
      ...state,
      user: user
    };
  }),
  on(userActions.loadUserBalanceSuccess, (state: UserState, {balance}) => {
    return {
      ...state,
      user: {
        ...state.user,
        balance: balance
      }
    };
  }),
  on(userActions.loadUserAgreementSuccess, (state: UserState, {agreement}) => {
    return {
      ...state,
      user: {
        ...state.user,
        agreement: agreement
      }
    };
  }),
  on(userActions.loadUserPaymentsBlocksSuccess, (state: UserState, {paymentsBlocks}) => {
    return {
      ...state,
      user: {
        ...state.user,
        paymentsBlocks: paymentsBlocks
      }
    };
  })
);
