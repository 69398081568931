import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {DtMessage} from '@ui/translation/models/translate.model';

import {SectionDescriptionComponentEnum} from './section-description.component.enum';

@Component({
  selector: 'app-section-description',
  templateUrl: './section-description.component.html',
  styleUrls: ['./section-description.component.scss']
})
export class SectionDescriptionComponent implements OnInit {
  @Input() type: SectionDescriptionComponentEnum = SectionDescriptionComponentEnum.H3;
  @Input() align: 'start' | 'center' | 'end' = 'center';
  @Input() title: DtMessage;
  @Input() description: DtMessage;
  @Input() descriptionHtml: DtMessage;
  @Input() descriptionAlign: string;

  @Output() linkClick = new EventEmitter();

  descriptionHeaderTypes = SectionDescriptionComponentEnum;

  constructor() {}

  ngOnInit(): void {}
}
