<dt-dialog>
  <dt-dialog-title> {{ 'ADD_LOCATION' | dtTranslate }} </dt-dialog-title>
  <dt-dialog-body [fullWidth]="true" [removePadding]="true">
    <div #mapWrapper class="map-wrapper"></div>
    <div class="search-wrapper">
      <div class="autocomplete-wrapper">
        <ng-template #searchIcon>
          <i nz-icon nzType="ng-zorro:search"></i>
        </ng-template>
        <dt-input
          type="text"
          name="map-search"
          [placeholder]="'SEARCH' | dtTranslate"
          [prefixRef]="searchIcon"
          [suffixRef]="clearIcon"
          [control]="autocompleteControl">
        </dt-input>
        <ng-template #clearIcon>
          <i nz-icon nzType="ng-zorro:cross" (click)="clearAutoComplete()"></i>
        </ng-template>
        <div class="search-wrapper__autocomplete">
          <div
            *ngFor="let item of autocompleteResults"
            class="search-wrapper__autocomplete-item"
            (click)="selectAutocompleteOption(item)">
            {{ item.description }}
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="selectedLocation" class="selected-location">
      <div class="selected-location__item">
        <i nz-icon nzType="ng-zorro:map-pin"></i>
        <span>{{ selectedLocation }}</span>
        <button (click)="clearSelectedLocation()">
          <i nz-icon nzType="ng-zorro:close"></i>
        </button>
      </div>
      <div class="selected-location__select-button">
        <dt-button size="full" (click)="selectLocation()">
          {{ 'LOCATIONS.SELECT_LOCATION' | dtTranslate }}
        </dt-button>
      </div>
    </div>
    <div *ngIf="currentLocation" class="navigation-button" (click)="setCurrentLocationMarker()">
      <i nz-icon nzType="ng-zorro:navigation"></i>
    </div>
  </dt-dialog-body>
</dt-dialog>
