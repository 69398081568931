import {Injectable} from '@angular/core';
import {DtImageExtensions, DtVideoExtensions} from '@ui/constants/dt-media-content.constants';
import {DtMedia} from '@ui/models/dt-media-content.model';

@Injectable({
  providedIn: 'root'
})
export class DtMediaContentService {
  constructor() {}

  getMediaByUrl(mediaUrl: string): DtMedia {
    if (mediaUrl) {
      const isLowQualityImage = mediaUrl.includes('_low');
      const isBlurred = mediaUrl.includes('blured');
      const fileName = this.getMediaNameByUrl(mediaUrl);
      const separator = fileName.includes('.') ? '.' : '_';
      const fileExt = fileName.split(separator).pop();
      if (DtImageExtensions.includes(fileExt) || isLowQualityImage) {
        return {type: 'image', url: mediaUrl, isBlurred};
      }
      if (DtVideoExtensions.includes(fileExt)) {
        return {type: 'video', url: mediaUrl, isBlurred};
      }
      return {type: null, url: mediaUrl, isBlurred};
    }
    return null;
  }

  getMediaNameByUrl(mediaUrl: string): string {
    const url = new URL(mediaUrl);
    return url.pathname.split('/').pop();
  }
}
