<label *ngIf="label" class="flush-textarea-label" for="flush-textarea--{{ name }}">
  {{ label | dtTranslate }}
</label>
<div class="flush-textarea-field-wrapper">
  <div class="flush-textarea-wrapper">
    <textarea
      [style.height]="height"
      [style.resize]="resize"
      [placeholder]="placeholder | dtTranslate"
      [formControl]="control"
      [ngClass]="{
        'moderation-error': control.getError('moderationFailed')
      }"
      [maxlength]="maxLength">
    </textarea>
  </div>
</div>
