import {Action, combineReducers} from '@ngrx/store';

import {ActiveChatReducer} from '../../active-chat/store/reducers/active-chat.reducer';
import {ACTIVE_CHAT_FEATURE_KEY} from '../../active-chat/store/state/active-chat.state';
import {ChatListReducer} from '../../chat-list/store/reducers/chat-list.reducer';
import {CHAT_LIST_FEATURE_KEY} from '../../chat-list/store/state/chat-list.state';
import {ChatPageState, initialChatPageState} from '../state/chat-page.state';
import {USER_DATA_FEATURE_KEY} from '../state/user-data.state';
import {UserDataReducer} from './user-data.reducer';

const reducers = combineReducers({
  [CHAT_LIST_FEATURE_KEY]: ChatListReducer,
  [ACTIVE_CHAT_FEATURE_KEY]: ActiveChatReducer,
  [USER_DATA_FEATURE_KEY]: UserDataReducer
});

export function ChatPageReducer(state: ChatPageState = initialChatPageState, action: Action) {
  return reducers(state, action);
}
