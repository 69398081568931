import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';

import {DtDefaultAvatarPipe} from './dt-default-avatar.pipe';

@NgModule({
  declarations: [DtDefaultAvatarPipe],
  exports: [DtDefaultAvatarPipe],
  imports: [CommonModule]
})
export class DtDefaultAvatarModule {}
