import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';

import {DtButtonComponent} from './dt-button.component';

@NgModule({
  declarations: [DtButtonComponent],
  exports: [DtButtonComponent],
  imports: [CommonModule]
})
export class DtButtonModule {}
