import {ActionReducerMap} from '@ngrx/store';
import {ChatPageReducer} from 'projects/dating-app/src/app/features/main/pages/chat/store/reducers/chat-page.reducer';
import {CHAT_PAGE_FEATURE_KEY} from 'projects/dating-app/src/app/features/main/pages/chat/store/state/chat-page.state';

import {profileReducer} from '../../../features/main/pages/profile/store/reducers/profile.reducers';
import {PROFILE_FEATURE_KEY} from '../../../features/main/pages/profile/store/state/profile.state';
import {AppState} from '../state/app.state';
import {CHAT_FEATURE_KEY} from '../state/chat.state';
import {USER_FEATURE_KEY} from '../state/user.state';
import {chatReducer} from './chat.reducer';
import {userReducers} from './user.reducers';

export const appReducers: ActionReducerMap<AppState> = {
  [USER_FEATURE_KEY]: userReducers,
  [PROFILE_FEATURE_KEY]: profileReducer,
  [CHAT_PAGE_FEATURE_KEY]: ChatPageReducer,
  [CHAT_FEATURE_KEY]: chatReducer
};
