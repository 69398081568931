<div class="section-description-wrapper" [style.text-align]="align">
  <div *ngIf="title" class="section-description-title">
    <h1 *ngIf="type === descriptionHeaderTypes.H1">
      {{ title | dtTranslate }}
    </h1>
    <h2 *ngIf="type === descriptionHeaderTypes.H2">
      {{ title | dtTranslate }}
    </h2>
    <h3 *ngIf="type === descriptionHeaderTypes.H3">
      {{ title | dtTranslate }}
    </h3>
    <h4 *ngIf="type === descriptionHeaderTypes.H4">
      {{ title | dtTranslate }}
    </h4>
    <h5 *ngIf="type === descriptionHeaderTypes.H5">
      {{ title | dtTranslate }}
    </h5>
  </div>
  <div *ngIf="description" class="section-description-secondary-text">
    {{ description | dtTranslate }}
  </div>
  <div
    *ngIf="descriptionHtml"
    appHandleLink
    class="section-description-secondary-text"
    [style.text-align]="descriptionAlign"
    [innerHTML]="descriptionHtml | dtTranslate"
    (linkClick)="linkClick.emit()"></div>
</div>
