import {createAction, props} from '@ngrx/store';
import {User} from '@sendbird/chat';
import {GroupChannel} from '@sendbird/chat/groupChannel';
import {SendableMessage} from '@sendbird/chat/lib/__definition';

import {ProfileMainInfoV2} from '../../../profile/models/profile.model';
import {QuickMessage} from '../../models/chat.model';

const actor = '[CHAT PAGE]';

export const enterChatPage = createAction(`${actor} Enter chat page`);

export const chatsStartingDataLoadedSuccesfully = createAction(
  `${actor} Chat data loaded succesfully`,
  props<{quickMessages: QuickMessage[]}>()
);

export const addMessageToExistingChat = createAction(
  `${actor} Add message to existing chat`,
  props<{message: SendableMessage; chat: GroupChannel}>()
);

export const loadNewChat = createAction(`${actor} Load new chat`, props<{chat: GroupChannel}>());

export const newActiveChatLoadedSuccesfully = createAction(
  `${actor} New chat loaded succesfully`,
  props<{profile: ProfileMainInfoV2; chat: GroupChannel; sbUser: User}>()
);

export const addChatToTheList = createAction(
  `${actor} New chat added loaded succesfully`,
  props<{profile: ProfileMainInfoV2; chat: GroupChannel; sbUser: User}>()
);

export const toggleChatPin = createAction(`${actor} Toggle chat pin state`, props<{chat: GroupChannel}>());

export const toggleChatPinSuccess = createAction(
  `${actor} Toggle chat pin state success`,
  props<{chat: GroupChannel}>()
);

export const leaveChatPage = createAction(`${actor} Leave chat page`);

export const deleteChatSuccess = createAction(`${actor} delete chat success`, props<{chatUrl: string}>());

export const userMuteChanged = createAction(
  `${actor} user mute changed`,
  props<{chat: GroupChannel; user: User; myUserId: string}>()
);

export const currentUserProfileLoaded = createAction(
  `${actor} current user profile loaded`,
  props<{profile: ProfileMainInfoV2}>()
);
