import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild
} from '@angular/core';
import {FormControl} from '@angular/forms';
import {Subject, takeUntil} from 'rxjs';

@Component({
  selector: 'dt-input',
  templateUrl: './dt-input.component.html',
  styleUrls: ['./dt-input.component.scss']
})
export class DtInputComponent implements OnChanges, OnInit, OnDestroy {
  @Input() name: string;
  @Input() control: FormControl;
  @Input() isDisabled: boolean;
  @Input() placeholder: string;
  @Input() label: string;
  @Input() type: 'text' | 'password' | 'number' = 'text';
  @Input() inputMode: 'text' | 'numeric' = 'text';
  @Input() align: 'center' | 'default' = 'default';
  @Input() borderless = false;
  @Input() readonly = false;
  @Input() placeholderAlign: 'center';
  @Input() maxLength: number | string = 'unset';
  @Input() autocomplete: string;
  @Input() prefixRef: TemplateRef<any>;
  @Input() suffixRef: TemplateRef<any>;
  @Input() noMargin = false;
  @Input() copyable = false;
  @Input() testId: string;

  @Output() keydownEvent = new EventEmitter();
  @Output() pasteEvent = new EventEmitter();
  @Output() valueChange = new EventEmitter();
  @Output() focusChange = new EventEmitter();
  @Output() copyClicked = new EventEmitter();

  @ViewChild('inputRef') inputRef: ElementRef<HTMLInputElement>;

  private originalType: string;

  valueIsCopied = false;

  get isPasswordField(): boolean {
    return this.originalType === 'password' && !this.suffixRef;
  }

  get isCopyableField(): boolean {
    return this.copyable && !this.suffixRef;
  }

  private destroyed$ = new Subject<void>();

  constructor() {}

  ngOnChanges(changes: SimpleChanges): void {
    const {isDisabled} = changes;
    if (isDisabled) {
      if (isDisabled.currentValue) {
        this.control.disable();
      } else {
        this.control.enable();
      }
    }
  }

  ngOnInit() {
    this.originalType = this.type;
    this.control?.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe((value) => {
      this.valueChange.emit(value);
    });
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  keydownEmit(event: KeyboardEvent): void {
    this.keydownEvent.emit(event);
  }

  pasteEmit(event: ClipboardEvent): void {
    this.pasteEvent.emit(event);
  }

  focusChangeEmit(value: boolean) {
    this.focusChange.emit(value);
  }

  toggleType() {
    if (this.type === 'password') {
      this.type = 'text';
    } else {
      this.type = 'password';
    }
  }

  copyLink(input: HTMLInputElement) {
    this.valueIsCopied = true;
    input.select();
    document.execCommand('copy');
    input.setSelectionRange(0, 0);
    this.copyClicked.emit();
  }
}
