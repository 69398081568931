import {Injectable} from '@angular/core';
import {BehaviorSubject, delay, filter, MonoTypeOperatorFunction, pipe, repeat} from 'rxjs';

export interface DevToolsConfig {
  contentEmpty: boolean;
  translationsDisabled: boolean;
  translationsEditable: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class DevToolsService {
  private devToolsConfig$ = new BehaviorSubject<DevToolsConfig>({
    contentEmpty: false,
    translationsDisabled: false,
    translationsEditable: false
  });

  constructor() {}

  getDevToolsConfigChanges(): BehaviorSubject<DevToolsConfig> {
    return this.devToolsConfig$;
  }

  getDevToolsConfig(): DevToolsConfig {
    return this.devToolsConfig$.value;
  }

  updateDevToolsConfig(value: Partial<DevToolsConfig>): void {
    const newConfig = {...this.getDevToolsConfig(), ...value};
    this.devToolsConfig$.next(newConfig);
  }

  getEmptyContentPipe<T>(): MonoTypeOperatorFunction<T> {
    return pipe(
      repeat({
        delay: () =>
          this.getDevToolsConfigChanges().pipe(
            filter((config) => {
              return config?.contentEmpty;
            })
          )
      }),
      delay(50000)
    );
  }
}
