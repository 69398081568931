// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  MAP_API_KEY: 'AIzaSyAp7CrP4M2GOUslyw0AV9X774jxtl8zCa4',
  GOOGLE_AUTH_CLIENT_ID: '796663520590-8imuet61flsp2oj932osp0cgggm1aonm.apps.googleusercontent.com',
  FACEBOOK_APP_ID: '340450084474266',
  APPLE_CLIENT_ID: '123test',
  STRIPE_KEY:
    'pk_live_51LnGqFH9UQL3OnKqrlpaD0FPHyg7YAft6euTWvI5DUwwqsCEiW28abpya3W6xWoJHvK1rpGB4auHyaWxHTcR4yz800OT45cKou',
  APP_URL: 'https://app.hunsy.dev',
  API_URL: 'https://api.hunsy.dev',
  COOKIE_SCRIPT_ID: '8eacb260f337ebf6c8218ef71a9844d8',
  // API_URL: 'https://192.168.3.27:4200/api',
  WS_URL: 'wss://ws.hunsy.dev',
  SENDBIRD: {
    APP_ID: '859C40C8-FA4A-41ED-9A9D-3F9B77438E1D',
    GIPTHY_API_KEY: 'jsNTFzyPzeBIPQzLD42uf1dKiBeXWzPv'
  },
  USER_PILOT_KEY: 'NX-38778449',
  PAYMENT_PROVIDER: 'shift4'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
