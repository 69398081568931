import {createSelector} from '@ngrx/store';

import {ChatDisplayService} from '../../services/chat-display.service';
import {ChatPageState} from '../state/chat-page.state';
import {selectChatPageState} from './chat-page.selectors';

export const selectUserDataState = createSelector(selectChatPageState, (state: ChatPageState) => state?.userData);

export const selectActiveMobileTab = createSelector(selectUserDataState, (state) => {
  return state?.mobileTab;
});
export const selectAllQuickMessages = createSelector(selectUserDataState, (state) => {
  return state?.quickMessages;
});

export const selectSbUser = createSelector(selectUserDataState, (state) => {
  return state?.user;
});

export const selectPrefferedLanguage = createSelector(selectSbUser, (user) =>
  ChatDisplayService.getPrferredLanguage(user)
);

export const selectCurrentProfile = createSelector(selectUserDataState, (state) => state.profile);

export const selectIsMeInvisible = createSelector(selectUserDataState, (state) => !!state.profile?.invisible);
