<div
  class="flush-input-field-wrapper"
  [ngClass]="{
    'no-margin': removeMargin
  }">
  <label *ngIf="label" class="flush-input-label" for="flush-input--{{ name }}">
    {{ label | dtTranslate }}
  </label>
  <div class="flush-input-wrapper">
    <ng-container *ngIf="prefixRef">
      <div class="affix-holder prefix">
        <ng-container *ngTemplateOutlet="prefixRef"></ng-container>
      </div>
    </ng-container>
    <input
      #inputRef
      class="flush-input"
      id="flush-input--{{ name }}"
      [ngStyle]="{'text-align': align && placeholderAlign}"
      [ngClass]="{
        hasSuffix: isPasswordField ? control.value : !!suffixRef,
        hasPrefix: !!prefixRef,
        borderless: borderless,
        center: align === 'center' || placeholderAlign === 'center'
      }"
      [autocomplete]="autocomplete"
      [formControl]="control!"
      [placeholder]="placeholder | dtTranslate"
      [maxlength]="maxLength"
      [type]="type"
      [readonly]="readonly"
      (focus)="onFocusChange(true)"
      (blur)="onFocusChange(false)" />
    <div class="affix-holder suffix">
      <ng-container *ngIf="isPasswordField; then passwordIcon; else suffixRef"></ng-container>
    </div>
  </div>
</div>
<ng-template #passwordIcon>
  <app-icon
    *ngIf="control.value"
    class="password-icon"
    width="20px"
    height="15px"
    [name]="type === 'password' ? 'password-eye' : 'password-eye-crossed'"
    (click)="toggleType()">
  </app-icon>
</ng-template>
