export enum DtButtonTypes {
  PRIMARY = 'primary',
  PREMIUM = 'premium',
  VIOLET = 'violet',
  SECONDARY = 'secondary',
  DELETE = 'delete',
  RESET = 'reset'
}

export enum DtButtonSizes {
  FULL = 'full',
  MEDIUM = 'medium',
  CUSTOM = 'custom',
  THICK = 'thick'
}

export enum DtButtonActions {
  SUBMIT = 'submit',
  BUTTON = 'button',
  RESET = 'reset'
}
