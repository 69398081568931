export const SUPPORTED_LANGUAGES_LIST: SupportedLanguage[] = [
  {language: 'Afrikaans', isoCode: 'af'},
  {language: 'Albanian', isoCode: 'sq'},
  {language: 'Amharic', isoCode: 'am'},
  {language: 'Arabic', isoCode: 'ar'},
  {language: 'Armenian', isoCode: 'hy'},
  {language: 'Azerbaijani', isoCode: 'az'},
  {language: 'Basque', isoCode: 'eu'},
  {language: 'Belarusian', isoCode: 'be'},
  {language: 'Bengali', isoCode: 'bn'},
  {language: 'Bosnian', isoCode: 'bs'},
  {language: 'Bulgarian', isoCode: 'bg'},
  {language: 'Catalan', isoCode: 'ca'},
  {language: 'Cebuano', isoCode: 'ceb'},
  {language: 'Chinese (Simplified)', isoCode: 'zh-CN'},
  {language: 'Chinese (Traditional)', isoCode: 'zh-TW'},
  {language: 'Corsican', isoCode: 'co'},
  {language: 'Croatian', isoCode: 'hr'},
  {language: 'Czech', isoCode: 'cs'},
  {language: 'Danish', isoCode: 'da'},
  {language: 'Dutch', isoCode: 'nl'},
  {language: 'English', isoCode: 'en'},
  {language: 'Esperanto', isoCode: 'eo'},
  {language: 'Estonian', isoCode: 'et'},
  {language: 'Finnish', isoCode: 'fi'},
  {language: 'French', isoCode: 'fr'},
  {language: 'Frisian', isoCode: 'fy'},
  {language: 'Galician', isoCode: 'gl'},
  {language: 'Georgian', isoCode: 'ka'},
  {language: 'German', isoCode: 'de'},
  {language: 'Greek', isoCode: 'el'},
  {language: 'Gujarati', isoCode: 'gu'},
  {language: 'Haitian Creole', isoCode: 'ht'},
  {language: 'Hausa', isoCode: 'ha'},
  {language: 'Hawaiian', isoCode: 'haw'},
  {language: 'Hebrew', isoCode: 'he or iw'},
  {language: 'Hindi', isoCode: 'hi'},
  {language: 'Hmong', isoCode: 'hmn'},
  {language: 'Hungarian', isoCode: 'hu'},
  {language: 'Icelandic', isoCode: 'is'},
  {language: 'Igbo', isoCode: 'ig'},
  {language: 'Indonesian', isoCode: 'id'},
  {language: 'Irish', isoCode: 'ga'},
  {language: 'Italian', isoCode: 'it'},
  {language: 'Japanese', isoCode: 'ja'},
  {language: 'Javanese', isoCode: 'jv'},
  {language: 'Kannada', isoCode: 'kn'},
  {language: 'Kazakh', isoCode: 'kk'},
  {language: 'Khmer', isoCode: 'km'},
  {language: 'Kinyarwanda', isoCode: 'rw'},
  {language: 'Korean', isoCode: 'ko'},
  {language: 'Kurdish', isoCode: 'ku'},
  {language: 'Kyrgyz', isoCode: 'ky'},
  {language: 'Lao', isoCode: 'lo'},
  {language: 'Latin', isoCode: 'la'},
  {language: 'Latvian', isoCode: 'lv'},
  {language: 'Lithuanian', isoCode: 'lt'},
  {language: 'Luxembourgish', isoCode: 'lb'},
  {language: 'Macedonian', isoCode: 'mk'},
  {language: 'Malagasy', isoCode: 'mg'},
  {language: 'Malay', isoCode: 'ms'},
  {language: 'Malayalam', isoCode: 'ml'},
  {language: 'Maltese', isoCode: 'mt'},
  {language: 'Maori', isoCode: 'mi'},
  {language: 'Marathi', isoCode: 'mr'},
  {language: 'Mongolian', isoCode: 'mn'},
  {language: 'Myanmar (Burmese)', isoCode: 'my'},
  {language: 'Nepali', isoCode: 'ne'},
  {language: 'Nyanja (Chichewa)', isoCode: 'ny'},
  {language: 'Norwegian', isoCode: 'no'},
  {language: 'Pashto', isoCode: 'ps'},
  {language: 'Persian', isoCode: 'fa'},
  {language: 'Polish', isoCode: 'pl'},
  {language: 'Portuguese (Portugal, Brazil)', isoCode: 'pt'},
  {language: 'Punjabi', isoCode: 'pa'},
  {language: 'Romanian', isoCode: 'ro'},
  {language: 'Russian', isoCode: 'ru'},
  {language: 'Samoan', isoCode: 'sm'},
  {language: 'Scots Gaelic', isoCode: 'gd'},
  {language: 'Serbian', isoCode: 'sr'},
  {language: 'Sesotho', isoCode: 'st'},
  {language: 'Shona', isoCode: 'sn'},
  {language: 'Sindhi', isoCode: 'sd'},
  {language: 'Sinhala (Sinhalese)', isoCode: 'si'},
  {language: 'Slovak', isoCode: 'sk'},
  {language: 'Slovenian', isoCode: 'sl'},
  {language: 'Somali', isoCode: 'so'},
  {language: 'Spanish', isoCode: 'es'},
  {language: 'Sundanese', isoCode: 'su'},
  {language: 'Swahili', isoCode: 'sw'},
  {language: 'Swedish', isoCode: 'sv'},
  {language: 'Tagalog (Filipino)', isoCode: 'tl'},
  {language: 'Tajik', isoCode: 'tg'},
  {language: 'Tamil', isoCode: 'ta'},
  {language: 'Tatar', isoCode: 'tt'},
  {language: 'Telugu', isoCode: 'te'},
  {language: 'Thai', isoCode: 'th'},
  {language: 'Turkish', isoCode: 'tr'},
  {language: 'Turkmen', isoCode: 'tk'},
  {language: 'Ukrainian', isoCode: 'uk'},
  {language: 'Urdu', isoCode: 'ur'},
  {language: 'Uyghur', isoCode: 'ug'},
  {language: 'Uzbek', isoCode: 'uz'},
  {language: 'Vietnamese', isoCode: 'vi'},
  {language: 'Welsh', isoCode: 'cy'},
  {language: 'Xhosa', isoCode: 'xh'},
  {language: 'Yiddish', isoCode: 'yi'},
  {language: 'Yoruba', isoCode: 'yo'},
  {language: 'Zulu', isoCode: 'zu'}
];

export interface SupportedLanguage {
  language: string;
  isoCode: string;
}
