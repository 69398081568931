export interface AccessRequestPreviewConfig {
  title: string;
  description: string;
  avatarUrl: string;
  icon: string;
  button: string;
}

export enum AccessRequestMessageState {
  REQUESTED = 'REQUESTED',
  OPENED = 'OPENED',
  DECLINED = 'DECLINED',
  CLOSED = 'CLOSED'
}
