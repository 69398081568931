import {ApplicationUserListQuery} from '@sendbird/chat';
import {GroupChannelListQuery} from '@sendbird/chat/groupChannel';
import {MessageSearchQuery, UserMessage} from '@sendbird/chat/message';

import {ProfileMainInfoV2} from '../../../../profile/models/profile.model';
import {ChatListItemData, ConversationFilters, RequestState} from '../../../models/chat.model';

export const CHAT_LIST_FEATURE_KEY = 'chatList';

export interface ChatListState {
  filters: {
    segment: ConversationFilters;
  };
  listState: {
    list: ChatListItemData[];
    requestState: RequestState;
    prevChatsQuery: GroupChannelListQuery;
    prevPinnedChatsQuery: GroupChannelListQuery;
    messagesSearchQuery: MessageSearchQuery;
    usersSearchQuery: ApplicationUserListQuery;
    usersSearchResult: ProfileMainInfoV2[];
    messagesSearchResult: UserMessage[];
    allLoaded: boolean;
  };
}

export const initialChatListState: ChatListState = {
  filters: {
    segment: ConversationFilters.ALL
  },
  listState: {
    list: [],
    requestState: RequestState.INITIAL,
    usersSearchResult: [],
    prevChatsQuery: null,
    prevPinnedChatsQuery: null,
    messagesSearchResult: [],
    messagesSearchQuery: null,
    usersSearchQuery: null,
    allLoaded: false
  }
};
