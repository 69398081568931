import {createAction, props} from '@ngrx/store';
import {User} from '@sendbird/chat';
import {GroupChannel} from '@sendbird/chat/groupChannel';
import {BaseMessageCreateParams, SendableMessage} from '@sendbird/chat/lib/__definition';
import {MessageType} from '@sendbird/chat/message';

const actor = '[CHAT]';

export const userConnected = createAction(`${actor} User connected`, props<{user: User}>());

export const messageRecieved = createAction(
  `${actor} Message recieved`,
  props<{message: SendableMessage; chat: GroupChannel}>()
);

export const unreadMessagesCountUpdated = createAction(
  `${actor} Unread messages count updated`,
  props<{count: number}>()
);

export const startNewChatWithMessage = createAction(
  `${actor} Start new chat`,
  props<{messageToCreate: BaseMessageCreateParams; messageType: MessageType; profileId: string}>()
);

export const chatCreatedSuccesfully = createAction(
  `${actor} Chat created succesfully`,
  props<{chat: GroupChannel; messageToCreate: BaseMessageCreateParams; messageType: MessageType}>()
);
