import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';

import {ColorsModel} from '../../../core/models/colors.model';
import {IconName} from './legacy-icon.model';

@Component({
  selector: 'app-icon',
  templateUrl: './legacy-icon.component.html',
  styleUrls: ['./legacy-icon.component.scss']
})
export class LegacyIconComponent implements OnChanges {
  viewBox: string;
  @Input() name: IconName | undefined | null;
  @Input() width = '30px';
  @Input() height = '30px';
  @Input() fill: ColorsModel = 'secondary';
  @Input() className: string;
  @Input() hexColor: string;

  ngOnChanges(changes: SimpleChanges) {
    const {name} = changes;
    if (name) {
      const normilizedWidth = (this.width.match(/(\d+)/) as Array<string>)[0];
      const normilizedHeight = (this.height.match(/(\d+)/) as Array<string>)[0];
      this.viewBox = `0 0 ${normilizedWidth} ${normilizedHeight}`;
    }
  }
}
