import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss']
})
export class AlertComponent implements OnInit {
  @ViewChild('alertTemplate', {static: true}) template: TemplateRef<unknown>;

  constructor() {}

  ngOnInit(): void {}
}
