<dt-dialog>
  <dt-dialog-title>
    {{ 'CHAT.TRANSLATIONS_SETTINGS' | dtTranslate }}
  </dt-dialog-title>
  <dt-dialog-body [formGroup]="form">
    <div class="field">
      <div class="field__label">
        {{ 'SETTINGS.PROFILE_TAB.TRANSLATE-ALL-CONVERSATIONS' | dtTranslate }}
      </div>
      <div class="field__input">
        <nz-switch
          formControlName="globalTranlationEnabled"
          (ngModelChange)="onTranslationSwitchChange($event)"></nz-switch>
      </div>
    </div>
    <div class="field">
      <div class="field__label">
        {{ 'CHAT.TRANSLATE_TO' | dtTranslate }}
      </div>
      <div class="field__input">
        <nz-select formControlName="preferredLanguage" [nzCustomTemplate]="language">
          <nz-option *ngFor="let lang of languages" nzCustomContent [nzLabel]="lang.language" [nzValue]="lang.isoCode">
            <div class="select-option">
              <!-- <i nz-icon [nzType]="currency.flagIcon"></i> -->
              {{ lang.language }}
            </div>
          </nz-option>
        </nz-select>
        <ng-template #language let-selected>
          <div class="select-option">
            <!-- <i nz-icon [nzType]="selected.nzValue.flagIcon"></i> -->
            {{ selected.nzLabel }}
          </div>
        </ng-template>
      </div>
    </div>

    <div class="field">
      <div class="field__label">
        {{ 'CHAT.DISABLE_TRANSLATION_FOR_THIS_CONVERSATION' | dtTranslate }}
      </div>
      <div class="field__input">
        <nz-switch formControlName="channelTranslationDisabled"></nz-switch>
      </div>
    </div>
  </dt-dialog-body>
  <dt-dialog-footer>
    <dt-button [disabled]="form.invalid || !form.dirty" [size]="'full'" (click)="save()">
      {{ 'SAVE' | dtTranslate }}
    </dt-button>
  </dt-dialog-footer>
</dt-dialog>
