import {Profile} from '../../models/profile.model';

export const PROFILE_FEATURE_KEY = 'profile';

export interface ProfileState {
  profile: Profile;
}

export const initialProfileState: ProfileState = {
  profile: null
};
