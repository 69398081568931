import {HttpClient} from '@angular/common/http';
import {TranslateLoader} from '@ngx-translate/core';
import {catchError, forkJoin, map, Observable, of, take} from 'rxjs';

export class DtTranslateBrowserLoader implements TranslateLoader {
  constructor(
    private http: HttpClient,
    private urls: Array<string>
  ) {}

  public getTranslation(lang: string): Observable<any> {
    // const langKey = `LANDING-${lang}`;
    // const key: StateKey<number> = makeStateKey<number>('transfer-translate-' + langKey);
    let data: any;
    // if (this.transferState) {
    //   data = this.transferState.get(key, null);
    // }

    // First we are looking for the translations in transfer-state,
    // if none found, http load as fallback
    if (data) {
      return new Observable((observer) => {
        observer.next(data);
        observer.complete();
      });
    } else {
      return new Observable((observer) => {
        const request = [];
        for (const url of this.urls) {
          // adding dynamic param so avoid browser caching
          const langFileUrl = `${url}${lang}.json?cb=${new Date().getTime()}`;
          request.push(
            this.http
              .get(langFileUrl, {
                responseType: 'json',
                params: {
                  ignoreErrorWarning: true,
                  ignoreSessionRedirect: true
                }
              })
              .pipe(
                catchError(() => {
                  return of(null);
                })
              )
          );
        }
        forkJoin(request)
          .pipe(
            map((results) => {
              results.reverse();

              const translationJson = {};

              results.forEach((fileContent) => {
                Object.assign(translationJson, fileContent);
              });

              return translationJson;
            }),
            take(1)
          )
          .subscribe((res) => {
            observer.next(res);
            observer.complete();
          });
      });
    }
  }
}

export function dtTranslateBrowserLoaderFactory(httpClient: HttpClient, urls: string[]) {
  return new DtTranslateBrowserLoader(httpClient, urls);
}
