export class DateUtil {
  constructor() {}

  public static getAge(date: Date): number {
    const startYear = 1970;
    const currentDate = new Date();
    const dateDifference = new Date(currentDate.getTime() - date.getTime());
    return dateDifference.getUTCFullYear() - startYear;
  }

  public static isAdult(age: number): boolean {
    const adultAge = 18;
    return age >= adultAge;
  }

  public static isToday(date: Date, isUtc = false): boolean {
    const today = new Date();
    if (isUtc) {
      return (
        date.getUTCDate() == today.getUTCDate() &&
        date.getUTCMonth() == today.getUTCMonth() &&
        date.getUTCFullYear() == today.getUTCFullYear()
      );
    }
    return (
      date.getDate() == today.getDate() &&
      date.getMonth() == today.getMonth() &&
      date.getFullYear() == today.getFullYear()
    );
  }

  public static isPast(date: Date): boolean {
    const today = new Date();
    return date < today;
  }

  public static hoursBetweenDates(startDate: Date, endDate: Date): number {
    const difference = endDate.getTime() - startDate.getTime();
    return Math.floor(difference / (1000 * 3600));
  }

  public static daysBetweenDates(startDate: Date, endDate: Date): number {
    const difference = endDate.getTime() - startDate.getTime();
    return Math.floor(difference / (1000 * 3600 * 24));
  }

  public static secondsToTimestamp(seconds: number): string {
    seconds = Math.floor(seconds);
    let h: string | number = Math.floor(seconds / 3600);
    let m: string | number = Math.floor((seconds - h * 3600) / 60);
    let s: string | number = seconds - h * 3600 - m * 60;

    h = h < 10 ? '0' + h : h;
    m = m < 10 ? '0' + m : m;
    s = s < 10 ? '0' + s : s;
    return m + ':' + s;
  }

  public static getTimezoneInfo(): {time: string; code: string} {
    const timezoneCountryCode = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const userTimezoneOffsetInMinutes = new Date().getTimezoneOffset();
    const formattedTimezoneOffset = this.formatTimezoneOffset(userTimezoneOffsetInMinutes);
    return {time: formattedTimezoneOffset, code: timezoneCountryCode};
  }

  public static formatTimezoneOffset(offsetInMinutes: number): string {
    const hours = Math.floor(Math.abs(offsetInMinutes) / 60);
    const minutes = Math.abs(offsetInMinutes) % 60;
    const sign = offsetInMinutes >= 0 ? '-' : '+';
    return `${sign}${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
  }

  public static formatToYYYYMMDD(dateLike: any): string {
    return new Date(dateLike).toISOString().split('T')[0];
  }

  public static getTimezoneUTC(date: Date, offsetInSeconds = 0): string {
    const newDate = new Date(date.getTime() - offsetInSeconds * 1000);
    return new Date(
      Date.UTC(
        newDate.getFullYear(),
        newDate.getMonth(),
        newDate.getDate(),
        newDate.getHours(),
        newDate.getMinutes(),
        newDate.getSeconds()
      )
    ).toISOString();
  }
}
