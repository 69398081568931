import {Injectable, TemplateRef, Type, ViewContainerRef} from '@angular/core';
import {NzNotificationRef, NzNotificationService} from 'ng-zorro-antd/notification';

import {AlertComponent} from '../../shared/components/alerts/components/alert/alert.component';

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {
  constructor(private notification: NzNotificationService) {}

  createNotification(message: string, title?: string): NzNotificationRef {
    const backgroundColor = '#00000080';
    return this.notification.blank(null, message, {
      nzPlacement: 'bottomLeft',
      nzCloseIcon: 'ng-zorro:cross',
      nzStyle: {
        background: backgroundColor
      }
    });
  }

  createWarningNotification(message: string, title?: string): void {
    this.notification.warning(title, message, {
      nzPlacement: 'bottomLeft',
      nzCloseIcon: 'ng-zorro:cross',
      nzStyle: {
        backgroundColor: '#FF7236'
      }
    });
  }

  createInfoNotification(message: string, title?: string): void {
    this.notification.warning(title, message, {
      nzPlacement: 'bottomLeft',
      nzCloseIcon: 'ng-zorro:cross',
      nzStyle: {
        backgroundColor: '#2388e5'
      }
    });
  }

  createComponentNotification<ComponentType>(component: Type<ComponentType>, viewContainerRef: ViewContainerRef): void {
    const componentRef = viewContainerRef.createComponent(component);
    this.createBasicNotification((componentRef.instance as AlertComponent).template);
  }

  private createBasicNotification<Template>(template: TemplateRef<Template>): void {
    this.notification.template(template);
  }
}
