import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {DtTranslateModule} from '@ui/translation/dt-translate.module';

import {FlushRadioFieldComponent} from './flush-radio-field.component';

@NgModule({
  declarations: [FlushRadioFieldComponent],
  exports: [FlushRadioFieldComponent],
  imports: [CommonModule, FormsModule, ReactiveFormsModule, DtTranslateModule]
})
export class FlushRadioFieldModule {}
