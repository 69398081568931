import {User} from '../../models/user.model';

export const USER_FEATURE_KEY = 'user';

export interface UserState {
  user: User;
}

export const initialUserState: UserState = {
  user: null
};
