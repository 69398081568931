<div class="location-select-container">
  <nz-select
    id="location-select"
    nzOptionHeightPx="50"
    [nzPlaceHolder]="'LOCATIONS.SELECT_LOCATION' | dtTranslate"
    [nzSuffixIcon]="selectIcon"
    [nzDropdownRender]="locations.length ? mapOption : emptyOption"
    [nzNotFoundContent]="mapOption"
    [(ngModel)]="selectedLocationName"
    (ngModelChange)="setSelectedLocation($event)">
    <nz-option *ngFor="let location of locations" nzCustomContent [nzLabel]="location.name" [nzValue]="location.name">
      <div class="option">
        <div class="option__value">
          <i *ngIf="location.type === 'primary'" nz-icon nzType="ng-zorro:sidebar-home"></i>
          <i *ngIf="location.type === 'current'" nz-icon nzType="ng-zorro:navigation"></i>
          <i *ngIf="!location.type" nz-icon nzType="ng-zorro:location"></i>
          <div>{{ location.name }}</div>
        </div>
        <ng-container *ngIf="location.type">
          {{ location.type | uppercase | dtTranslate | lowercase }}
        </ng-container>
      </div>
    </nz-option>
  </nz-select>
  <ng-template #selectIcon>
    <i nz-icon nzType="ng-zorro:arrow-down"></i>
  </ng-template>
  <ng-template #mapOption>
    <nz-divider *ngIf="locations.length"></nz-divider>
    <div class="container">
      <div class="option" (click)="openMapDialog()">
        <div class="option__value">
          <i nz-icon nzType="ng-zorro:map-pin"></i>
          <div>{{ 'LOCATIONS.SELECT_OTHER_LOCATION' | dtTranslate }}</div>
        </div>
        <i nz-icon nzType="ng-zorro:arrow-right"></i>
      </div>
    </div>
  </ng-template>
  <ng-template #emptyOption></ng-template>
</div>
