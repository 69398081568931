import {Action, createReducer, on} from '@ngrx/store';

import {activeChatActions} from '../../../active-chat/store/actions';
import {ChatListItemData, RequestState} from '../../../models/chat.model';
import {chatPageActions} from '../../../store/actions';
import {chatListActions} from '../actions';
import {ChatListState, initialChatListState} from '../state/chat-list.state';

const featureReducer = createReducer(
  initialChatListState,

  on(chatPageActions.leaveChatPage, (state: ChatListState) => {
    return {
      ...initialChatListState
    };
  }),

  on(
    chatListActions.chatListLoadedSuccesfully,
    (state: ChatListState, {chats, prevChatsQuery, prevPinnedChatsQuery}) => {
      return {
        ...state,
        listState: {
          ...state.listState,
          prevChatsQuery,
          prevPinnedChatsQuery,
          requestState: RequestState.RESOLVED,
          list: [...state.listState.list, ...chats]
        }
      };
    }
  ),

  on(chatPageActions.deleteChatSuccess, (state, {chatUrl}) => {
    return {
      ...state,
      listState: {
        ...state.listState,
        list: state.listState.list.filter((item) => item.sbChat.url !== chatUrl)
      }
    };
  }),

  on(chatPageActions.toggleChatPinSuccess, (state) => {
    return {
      // to update list sorting
      ...state,
      listState: {
        ...state.listState,
        list: [...state.listState.list]
      }
    };
  }),

  on(chatPageActions.addMessageToExistingChat, (state) => {
    return {
      // to update list sorting
      ...state,
      listState: {
        ...state.listState,
        list: [...state.listState.list]
      }
    };
  }),

  on(activeChatActions.messagesMarkedAsRead, (state, {chat}) => {
    const listFromState = state.listState.list;
    const index = listFromState.findIndex((x) => x.sbChat.url === chat.url);
    let newList = [];
    if (index >= 0) {
      const copy = [...listFromState];
      copy.splice(index, 1, {
        ...listFromState[index],
        sbChat: chat
      });
      newList = copy;
    } else {
      newList = [...listFromState];
    }
    return {
      // to update list sorting
      ...state,
      listState: {
        ...state.listState,
        list: newList
      }
    };
  }),

  on(chatPageActions.enterChatPage, (state: ChatListState) => {
    return {
      ...initialChatListState,
      listState: {
        ...initialChatListState.listState,
        requestState: RequestState.LOADING
      }
    };
  }),

  on(chatListActions.cancelSearch, (state) => {
    return {
      ...state,
      listState: {
        ...state.listState,
        list: [],
        requestState: RequestState.LOADING,
        prevChatsQuery: null,
        prevPinnedChatsQuery: null,
        messagesSearchQuery: null,
        usersSearchQuery: null
      }
    };
  }),

  on(chatPageActions.addChatToTheList, (state: ChatListState, {chat, profile, sbUser}) => {
    const chatData: ChatListItemData = {
      sbChat: chat,
      profile,
      sbUser
    };
    return {
      ...state,
      listState: {
        ...state.listState,
        list: [...state.listState.list, chatData]
      }
    };
  }),

  on(chatPageActions.newActiveChatLoadedSuccesfully, (state: ChatListState, {chat, profile, sbUser}) => {
    const chatData: ChatListItemData = {
      sbChat: chat,
      profile,
      sbUser
    };
    return {
      ...state,
      listState: {
        ...state.listState,
        list: [...state.listState.list, chatData]
      }
    };
  }),

  on(chatListActions.segmentChanged, (state: ChatListState, {segment}) => {
    return {
      ...state,
      listState: {
        ...state.listState,
        list: [],
        prevChatsQuery: null,
        prevPinnedChatsQuery: null,
        messagesSearchQuery: null,
        usersSearchQuery: null,
        requestState: RequestState.LOADING
      },
      filters: {
        ...state.filters,
        segment
      }
    };
  }),

  on(chatListActions.searchQueryChanged, (state: ChatListState, {messagesQuery, usersQuery}) => {
    return {
      ...state,
      listState: {
        ...state.listState,
        messagesSearchQuery: messagesQuery,
        usersSearchQuery: usersQuery,
        requestState: RequestState.LOADING
      }
    };
  }),

  on(chatListActions.loadSearchResultsSuccess, (state: ChatListState, {messages, users}) => {
    return {
      ...state,
      listState: {
        ...state.listState,
        messagesSearchResult: messages,
        usersSearchResult: users,
        requestState: RequestState.RESOLVED
      }
    };
  }),

  on(chatListActions.loadMoreChats, (state: ChatListState) => {
    return {
      ...state,
      listState: {
        ...state.listState,
        requestState: RequestState.LOADING
      }
    };
  }),

  // on(chatListActions.loadMoreSearchedMessagesSuccess, (state: ChatListState, {messages}) => {
  //   return {
  //     ...state,
  //     listState: {
  //       ...state.listState,
  //       messagesSearchResult: [...state.listState.messagesSearchResult, ...messages],
  //       requestState: RequestState.RESOLVED
  //     }
  //   };
  // }),

  on(chatListActions.loadeMoreSearchedMessages, (state: ChatListState) => {
    return {
      ...state,
      listState: {
        ...state.listState,
        requestState: RequestState.LOADING
      }
    };
  }),

  on(chatListActions.loadMoreSearchedMessagesSuccess, (state: ChatListState, {messages}) => {
    return {
      ...state,
      listState: {
        ...state.listState,
        messagesSearchResult: [...state.listState.messagesSearchResult, ...messages],
        requestState: RequestState.RESOLVED
      }
    };
  })
);

export function ChatListReducer(state: ChatListState = initialChatListState, action: Action) {
  return featureReducer(state, action);
}
