import {Injectable} from '@angular/core';
import {DtScriptsService} from '@ui/services/dt-scripts.service';
import {catchError, forkJoin, from, Observable, of, switchMap} from 'rxjs';
import MapsLibrary = google.maps.MapsLibrary;
import MarkerLibrary = google.maps.MarkerLibrary;
import GeocodingLibrary = google.maps.GeocodingLibrary;
import PlacesLibrary = google.maps.PlacesLibrary;

@Injectable({
  providedIn: 'root'
})
export class DtMapDialogService {
  googleMapsApiKey = `AIzaSyAp7CrP4M2GOUslyw0AV9X774jxtl8zCa4`;

  constructor(private scriptsService: DtScriptsService) {}

  initGoogleMaps() {
    return this.loadGoogleMapsScript().pipe(
      switchMap(() => {
        return this.loadGoogleMapsLibraries();
      })
    );
  }

  private loadGoogleMapsScript(): Observable<HTMLElement> {
    return this.scriptsService
      .loadScript({
        id: `google-maps-api-script`,
        content:
          '(g=>{var h,a,k,p="The Google Maps JavaScript API",c="google",l="importLibrary",q="__ib__",m=document,b=window;b=b[c]||(b[c]={});var d=b.maps||(b.maps={}),r=new Set,e=new URLSearchParams,u=()=>h||(h=new Promise(async(f,n)=>{await (a=m.createElement("script"));e.set("libraries",[...r]+"");for(k in g)e.set(k.replace(/[A-Z]/g,t=>"_"+t[0].toLowerCase()),g[k]);e.set("callback",c+".maps."+q);a.src=`https://maps.${c}apis.com/maps/api/js?`+e;d[q]=f;a.onerror=()=>h=n(Error(p+" could not load."));a.nonce=m.querySelector("script[nonce]")?.nonce||"";m.head.append(a)}));d[l]?console.warn(p+" only loads once. Ignoring:",g):d[l]=(f,...n)=>r.add(f)&&u().then(()=>d[l](f,...n))})({key: "' +
          this.googleMapsApiKey +
          '",v: "weekly"});'
      })
      .pipe(
        catchError(() => {
          return of(null);
        })
      );
  }

  private loadGoogleMapsLibraries(): Observable<[MapsLibrary, PlacesLibrary, MarkerLibrary, GeocodingLibrary]> {
    return forkJoin([
      from(google.maps.importLibrary('maps') as unknown as Observable<MapsLibrary>),
      from(google.maps.importLibrary('places') as unknown as Observable<PlacesLibrary>),
      from(google.maps.importLibrary('marker') as unknown as Observable<MarkerLibrary>),
      from(google.maps.importLibrary('geocoding') as unknown as Observable<GeocodingLibrary>)
    ]);
  }
}
