import {ChangeDetectorRef, Injectable, Pipe, PipeTransform} from '@angular/core';
import {TranslatePipe, TranslateService as NgxTranslateService} from '@ngx-translate/core';

import {DtTranslateService} from '../services/dt-translate.service';

@Injectable()
@Pipe({
  name: 'dtTranslate',
  pure: false
})
export class DtTranslatePipe extends TranslatePipe implements PipeTransform {
  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private ngxTranslateService: NgxTranslateService,
    private translateService: DtTranslateService
  ) {
    super(ngxTranslateService, changeDetectorRef);
  }

  transform(value: any, params?: any): string {
    if (this.translateService.debugModeEnabled) {
      return value;
    }
    let message: any;
    if (typeof value === 'object') {
      message = this.translateService.resolveMessage(
        value.translateKey,
        value.translateParams,
        value.untranslated
      ) as any;
    } else {
      message = this.translateService.resolveMessage(value, params) as any;
    }

    return super.transform(message.translateKey, message.translateParams);
  }
}
