export const phoneCodes = [
  {country: 'Anguilla', code: '+1264'},
  {country: 'Antigua and Barbuda', code: '+1268'},
  {country: 'Aruba', code: '+297'},
  {country: 'Ascension', code: '+247'},
  {country: 'Bahamas', code: '+1242'},
  {country: 'Barbados', code: '+1246'},
  {country: 'Belize', code: '+501'},
  {country: 'Bermuda', code: '+1441'},
  {country: 'Canada', code: '+1'},
  {country: 'Cayman Islands', code: '+1345'},
  {country: 'Costa Rica', code: '+506'},
  {country: 'Cuba', code: '+53'},
  {country: 'Curaçao and Caribbean Netherlands', code: 'Bonaire, Sint Eustatius, Sint Maarten, Saba'},
  {country: 'Dominica', code: '+1767'},
  {country: 'Dominican Republic', code: '+1829'},
  {country: 'Dominican Republic', code: '+1849'},
  {country: 'Dominican Republic', code: '+1809201'},
  {country: 'Dominican Republic', code: '+1809'},
  {country: 'El Salvador', code: '+503'},
  {country: 'Greenland', code: '+299'},
  {country: 'Grenada', code: '+1473'},
  {country: 'Guadeloupe', code: '+590'},
  {country: 'Guatemala', code: '+502'},
  {country: 'Haiti', code: '+509'},
  {country: 'Honduras', code: '+504'},
  {country: 'Jamaica', code: '+1876'},
  {country: 'Martinique', code: '+596'},
  {country: 'Mexico', code: '+52'},
  {country: 'Montserrat', code: '+1664'},
  {country: 'Nicaragua', code: '+505'},
  {country: 'Panama', code: '+507'},
  {country: 'Puerto Rico', code: '+1787'},
  {country: 'St Kitts and Nevis', code: '+1869'},
  {country: 'St Lucia', code: '+1758'},
  {country: 'St Pierre and Miquelon', code: '+508'},
  {country: 'St Vincent Grenadines', code: '+1784'},
  {country: 'Trinidad and Tobago', code: '+1868'},
  {country: 'Turks and Caicos Islands', code: '+1649'},
  {country: 'United States', code: '+1'},
  {country: 'Virgin Islands, British', code: '+1284'},
  {country: 'Virgin Islands, U.S.', code: '+1340'},
  {country: 'Afghanistan', code: '+93'},
  {country: 'Armenia', code: '+374'},
  {country: 'Bhutan', code: '+975'},
  {country: 'Bahrain', code: '+973'},
  {country: 'Brunei', code: '+673'},
  {country: 'Cambodia', code: '+855'},
  {country: 'China', code: '+86'},
  {country: 'East Timor', code: '+670'},
  {country: 'Georgia', code: '+995'},
  {country: 'Hong Kong', code: '+852'},
  {country: 'India', code: '+91'},
  {country: 'Indonesia', code: '+62'},
  {country: 'Iran', code: '+98'},
  {country: 'Iraq', code: '+964'},
  {country: 'Israel', code: '+972'},
  {country: 'Japan', code: '+81'},
  {country: 'Jordan', code: '+962'},
  {country: "Korea Dem People's Rep", code: '+850'},
  {country: 'Korea Republic of', code: '+82'},
  {country: 'Kuwait', code: '+965'},
  {country: 'Kyrgyzstan', code: '+996'},
  {country: 'Laos PDR', code: '+856'},
  {country: 'Lebanon', code: '+961'},
  {country: 'Macau', code: '+853'},
  {country: 'Malaysia', code: '+60'},
  {country: 'Maldives', code: '+960'},
  {country: 'Mongolia', code: '+976'},
  {country: 'Myanmar', code: '+95'},
  {country: 'Nepal', code: '+977'},
  {country: 'Philippines', code: '+63'},
  {country: 'Qatar', code: '+974'},
  {country: 'Russia/Kazakhstan', code: '+7'},
  {country: 'Saudi Arabia', code: '+966'},
  {country: 'Syria', code: '+963'},
  {country: 'Taiwan', code: '+886'},
  {country: 'Singapore', code: '+65'},
  {country: 'Thailand', code: '+66'},
  {country: 'Turkiye', code: '+90'},
  {country: 'Turkmenistan', code: '+993'},
  {country: 'United Arab Emirates', code: '+971'},
  {country: 'Uzbekistan', code: '+998'},
  {country: 'Vietnam', code: '+84'},
  {country: 'Yemen', code: '+967'},
  {country: 'Albania', code: '+355'},
  {country: 'Andorra', code: '+376'},
  {country: 'Austria', code: '+43'},
  {country: 'Belarus', code: '+375'},
  {country: 'Belgium', code: '+32'},
  {country: 'Bosnia and Herzegovina', code: '+387'},
  {country: 'Bulgaria', code: '+359'},
  {country: 'Canary Islands', code: '+3491'},
  {country: 'Croatia', code: '+385'},
  {country: 'Cyprus', code: '+357'},
  {country: 'Czech Republic', code: '+420'},
  {country: 'Denmark', code: '+45'},
  {country: 'Estonia', code: '+372'},
  {country: 'Faroe Islands', code: '+298'},
  {country: 'Finland/Aland Islands', code: '+358'},
  {country: 'France', code: '+33'},
  {country: 'Germany', code: '+49'},
  {country: 'Gibraltar', code: '+350'},
  {country: 'Greece', code: '+30'},
  {country: 'Guernsey/Jersey', code: '+44'},
  {country: 'Hungary', code: '+36'},
  {country: 'Iceland', code: '+354'},
  {country: 'Ireland', code: '+353'},
  {country: 'Isle of Man', code: '+44'},
  {country: 'Italy', code: '+39'},
  {country: 'Kosovo', code: '+383'},
  {country: 'Latvia', code: '+371'},
  {country: 'Liechtenstein', code: '+423'},
  {country: 'Lithuania', code: '+370'},
  {country: 'Luxembourg', code: '+352'},
  {country: 'Malta', code: '+356'},
  {country: 'Moldova', code: '+373'},
  {country: 'Monaco', code: '+377'},
  {country: 'Montenegro', code: '+382'},
  {country: 'Netherlands', code: '+31'},
  {country: 'Norway', code: '+47'},
  {country: 'Poland', code: '+48'},
  {country: 'Portugal', code: '+351'},
  {country: 'Republic of North Macedonia', code: '+389'},
  {country: 'Romania', code: '+40'},
  {country: 'San Marino', code: '+378'},
  {country: 'Serbia', code: '+381'},
  {country: 'Slovakia', code: '+421'},
  {country: 'Slovenia', code: '+386'},
  {country: 'Spain', code: '+34'},
  {country: 'Sweden', code: '+46'},
  {country: 'Switzerland', code: '+41'},
  {country: 'Turkish Republic of Northern Cyprus', code: '+90'},
  {country: 'Ukraine', code: '+380'},
  {country: 'United Kingdom', code: '+44'},
  {country: 'Vatican City', code: '+379'},
  {country: 'Argentina', code: '+54'},
  {country: 'Bolivia', code: '+591'},
  {country: 'Brazil', code: '+55'},
  {country: 'Chile', code: '+56'},
  {country: 'Colombia', code: '+57'},
  {country: 'Ecuador', code: '+593'},
  {country: 'Falkland Islands', code: '+500'},
  {country: 'French Guiana', code: '+594'},
  {country: 'Guyana', code: '+592'},
  {country: 'Paraguay', code: '+595'},
  {country: 'Peru', code: '+51'},
  {country: 'Suriname', code: '+597'},
  {country: 'Uruguay', code: '+598'},
  {country: 'Venezuela', code: '+58'},
  {country: 'Angola', code: '+244'},
  {country: 'Benin', code: '+229'},
  {country: 'Botswana', code: '+267'},
  {country: 'Burkina Faso', code: '+226'},
  {country: 'Burundi', code: '+257'},
  {country: 'Cameroon', code: '+237'},
  {country: 'Cape Verde', code: '+238'},
  {country: 'Central Africa', code: '+236'},
  {country: 'Chad', code: '+235'},
  {country: 'Comoros', code: '+269'},
  {country: 'Congo', code: '+242'},
  {country: 'Congo, Dem Rep', code: '+243'},
  {country: 'Djibouti', code: '+253'},
  {country: 'Egypt', code: '+20'},
  {country: 'Equatorial Guinea', code: '+240'},
  {country: 'Eritrea', code: '+291'},
  {country: 'Ethiopia', code: '+251'},
  {country: 'Gabon', code: '+241'},
  {country: 'Gambia', code: '+220'},
  {country: 'Ghana', code: '+233'},
  {country: 'Guinea', code: '+224'},
  {country: 'Guinea-Bissau', code: '+245'},
  {country: 'Ivory Coast', code: '+225'},
  {country: 'Kenya', code: '+254'},
  {country: 'Lesotho', code: '+266'},
  {country: 'Liberia', code: '+231'},
  {country: 'Libya', code: '+218'},
  {country: 'Madagascar', code: '+261'},
  {country: 'Malawi', code: '+265'},
  {country: 'Mali', code: '+223'},
  {country: 'Mauritania', code: '+222'},
  {country: 'Mauritius', code: '+230'},
  {country: 'Morocco/Western Sahara', code: '+212'},
  {country: 'Mozambique', code: '+258'},
  {country: 'Namibia', code: '+264'},
  {country: 'Niger', code: '+227'},
  {country: 'Reunion/Mayotte', code: '+262'},
  {country: 'Rwanda', code: '+250'},
  {country: 'Sao Tome and Principe', code: '+239'},
  {country: 'Senegal', code: '+221'},
  {country: 'Seychelles', code: '+248'},
  {country: 'Sierra Leone', code: '+232'},
  {country: 'Somalia', code: '+252'},
  {country: 'South Africa', code: '+27'},
  {country: 'South Sudan', code: '+211'},
  {country: 'Sudan', code: '+249'},
  {country: 'Swaziland', code: '+268'},
  {country: 'Tanzania', code: '+255'},
  {country: 'Togo', code: '+228'},
  {country: 'Uganda', code: '+256'},
  {country: 'Zambia', code: '+260'},
  {country: 'Zimbabwe', code: '+263'},
  {country: 'American Samoa', code: '+1684'},
  {country: 'Australia/Cocos/Christmas Island', code: '+61'},
  {country: 'Cook Islands', code: '+682'},
  {country: 'Fiji', code: '+679'},
  {country: 'French Polynesia', code: '+689'},
  {country: 'Guam', code: '+1671'},
  {country: 'Kiribati', code: '+686'},
  {country: 'Marshall Islands', code: '+692'},
  {country: 'Micronesia', code: '+691'},
  {country: 'New Caledonia', code: '+687'},
  {country: 'New Zealand', code: '+64'},
  {country: 'Niue', code: '+683'},
  {country: 'Norfolk Island', code: '+672'},
  {country: 'Northern Mariana Islands', code: '+1670'},
  {country: 'Palau', code: '+680'},
  {country: 'Papua New Guinea', code: '+675'},
  {country: 'Samoa', code: '+685'},
  {country: 'Solomon Islands', code: '+677'},
  {country: 'Tonga', code: '+676'},
  {country: 'Tuvalu', code: '+688'},
  {country: 'Vanuatu', code: '+678'},
  {country: 'Wallis and Futuna', code: '+681'}
];

export const currencies = [
  {id: 'AUD', name: 'Australian Dollar', symbol: 'AUD'},
  {id: 'BRL', name: 'Brazilian Real', symbol: 'R$'},
  {id: 'GBP', name: 'British Pound Sterling', symbol: '£'},
  {id: 'BGN', name: 'Bulgarian Lev', symbol: 'лв'},
  {id: 'CAD', name: 'Canadian Dollar', symbol: 'CAD'},
  {id: 'CNY', name: 'Chinese Yuan', symbol: 'CNY '},
  {id: 'COP', name: 'Colombian Peso', symbol: 'COP'},
  {id: 'CZK', name: 'Czech Republic Koruna', symbol: 'Kč'},
  {id: 'DKK', name: 'Danish Krone', symbol: 'DKK'},
  {id: 'EUR', name: 'Euro', symbol: '€'},
  {id: 'HKD', name: 'Hong Kong Dollar', symbol: 'HKD'},
  {id: 'HUF', name: 'Hungarian Forint', symbol: 'HUF'},
  {id: 'INR', name: 'Indian Rupee', symbol: '₹'},
  {id: 'ILS', name: 'Israeli New Sheqel', symbol: '₪'},
  {id: 'JPY', name: 'Japanese Yen', symbol: 'JPY'},
  {id: 'KZT', name: 'Kazakhstani Tenge', symbol: '₸'},
  {id: 'MXN', name: 'Mexican Peso', symbol: 'MXN '},
  {id: 'NZD', name: 'New Zealand Dollar', symbol: 'NZD'},
  {id: 'NOK', name: 'Norwegian Krone', symbol: 'NOK'},
  {id: 'PHP', name: 'Philippine Peso', symbol: '₱ '},
  {id: 'PLN', name: 'Polish Zloty', symbol: 'zł'},
  {id: 'RON', name: 'Romanian Leu', symbol: 'lei'},
  {id: 'RUB', name: 'Russian Ruble', symbol: '₽'},
  {id: 'SAR', name: 'Saudi Riyal', symbol: 'SAR'},
  {id: 'SGD', name: 'Singapore Dollar', symbol: 'SGD'},
  {id: 'ZAR', name: 'South African Rand', symbol: 'Br'},
  {id: 'SEK', name: 'Swedish Krona', symbol: 'SEK'},
  {id: 'CHF', name: 'Swiss Franc', symbol: 'CHF'},
  {id: 'THB', name: 'Thai Baht', symbol: '฿'},
  {id: 'TRY', name: 'Turkish Lira', symbol: '₺'},
  {id: 'UAH', name: 'Ukrainian Hryvnia', symbol: '₴'},
  {id: 'AED', name: 'United Arab Emirates Dirham', symbol: 'AED'},
  {id: 'USD', name: 'United States Dollar', symbol: '$'}
];
