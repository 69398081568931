// import {FacebookLoginProvider} from './shared/components/social-auth/providers/facebook-login-provider';
// import {GoogleLoginProvider} from './shared/components/social-auth/providers/google-login-provider';
// import {SocialAuthModule} from './shared/components/social-auth/social-auth.module';
// import {SocialAuthServiceConfig, socialAuthServiceConfig} from './shared/components/social-auth/social-auth.service';
import {SocialAuthServiceConfig, SocialLoginModule} from '@abacritt/angularx-social-login';
import {FacebookLoginProvider, GoogleLoginProvider} from '@abacritt/angularx-social-login';
import {registerLocaleData} from '@angular/common';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import en from '@angular/common/locales/en';
import uk from '@angular/common/locales/uk';
import {ErrorHandler, Injector, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {EffectsModule} from '@ngrx/effects';
import {StoreModule} from '@ngrx/store';
import {StoreDevtoolsModule} from '@ngrx/store-devtools';
import {TranslateLoader} from '@ngx-translate/core';
import {DtTranslateModule} from '@ui/translation/dt-translate.module';
import {DtTranslateBrowserLoader} from '@ui/translation/loaders/dt-translate-browser.loader';
import {en_US, NZ_I18N} from 'ng-zorro-antd/i18n';
import {NzModalModule} from 'ng-zorro-antd/modal';
import {NzNotificationModule} from 'ng-zorro-antd/notification';

import {environment} from '../environments/environment';
import {AppComponent} from './app.component';
import {AppRoutingModule} from './app-routing.module';
import {GlobalErrorHandler} from './core/handlers/global-error.handler';
import {ErrorInterceptor} from './core/interceptors/error.interceptor';
import {SessionInterceptor} from './core/interceptors/session.interceptor';
import {UserEffects} from './core/store/effects/user.effects';
import {appReducers} from './core/store/reducers/app.reducers';
import {AlertsModule} from './shared/components/alerts/alerts.module';
import {DialogsModule} from './shared/components/dialogs/dialogs.module';

registerLocaleData(en);
registerLocaleData(uk);

export const APP_LANGUAGE_PATH = ['assets/i18n/', 'assets/i18n/FOOTER-'];

export function TranslateBrowserLoaderFactory(httpClient: HttpClient) {
  return new DtTranslateBrowserLoader(httpClient, APP_LANGUAGE_PATH);
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    AlertsModule,
    BrowserModule.withServerTransition({appId: 'serverApp'}),
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    DialogsModule,
    DtTranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: TranslateBrowserLoaderFactory,
        deps: [HttpClient]
      }
    }),
    // ServiceWorkerModule.register('ngsw-worker.js', {
    //   enabled: environment.production,
    // Register the ServiceWorker as soon as the app is stable
    // or after 30 seconds (whichever comes first).
    //   registrationStrategy: 'registerWhenStable:30000'
    // }),
    // SocialAuthModule,
    SocialLoginModule,
    StoreModule.forRoot(appReducers, {
      runtimeChecks: {
        strictStateImmutability: false,
        strictActionImmutability: false
      }
    }),
    EffectsModule.forRoot([UserEffects]),
    StoreDevtoolsModule.instrument({
      maxAge: 25, // Retains last 25 states
      logOnly: environment.production // Restrict extension to log-only mode
    }),
    NzModalModule,
    NzNotificationModule
  ],
  providers: [
    {
      provide: 'environment',
      useValue: environment
    },
    {
      provide: NZ_I18N,
      useValue: en_US
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: SessionInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true
    },
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandler
    },
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(environment.GOOGLE_AUTH_CLIENT_ID)
          },
          {
            id: FacebookLoginProvider.PROVIDER_ID,
            provider: new FacebookLoginProvider(environment.FACEBOOK_APP_ID)
          }
        ],
        onError: (err) => {
          console.error(err);
        }
      } as SocialAuthServiceConfig
    }
    // {
    //   provide: socialAuthServiceConfig,
    //   useValue: {
    //     autoLogin: true,
    //     providers: [
    //       {
    //         id: GoogleLoginProvider.PROVIDER_ID,
    //         provider: new GoogleLoginProvider(environment.GOOGLE_AUTH_CLIENT_ID)
    //       },
    //       {
    //         id: FacebookLoginProvider.PROVIDER_ID,
    //         provider: new FacebookLoginProvider(environment.FACEBOOK_APP_ID)
    //       }
    //     ]
    //   } as SocialAuthServiceConfig
    // }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  static injector: Injector;

  constructor(injector: Injector) {
    AppModule.injector = injector;
  }
}
