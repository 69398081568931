import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';

import {HandleLinkDirective} from './handle-link.directive';

@NgModule({
  declarations: [HandleLinkDirective],
  exports: [HandleLinkDirective],
  imports: [CommonModule]
})
export class HandleLinkModule {}
