import {Action, createReducer, on} from '@ngrx/store';
import {MutedState} from '@sendbird/chat/groupChannel';
import {BaseMessage, MessageSearchQuery} from '@sendbird/chat/message';
import {chatActions} from 'projects/dating-app/src/app/core/store/actions';

import {ActiveChatFooter, CHAT_MESSAGES_QUERY_SIZE, RequestState} from '../../../models/chat.model';
import {chatPageActions} from '../../../store/actions';
import {activeChatActions} from '../actions';
import {activeChatInitialState, ActiveChatState} from '../state/active-chat.state';

const featureReducer = createReducer(
  activeChatInitialState,
  on(activeChatActions.leaveConversation, (state: ActiveChatState) => {
    return {
      ...activeChatInitialState
    };
  }),

  on(activeChatActions.loadExistingChat, (state: ActiveChatState, {profileId, chatUrl}) => {
    return {
      ...state,
      isStarted: true,
      recipientId: profileId,
      chatUrl: chatUrl,
      messagesState: {
        ...state.messagesState,
        list: [] as BaseMessage[],
        requestState: RequestState.LOADING
      }
    };
  }),

  on(chatPageActions.deleteChatSuccess, (state, {chatUrl}) => {
    if (chatUrl === state.chatUrl) {
      return {
        ...state,
        isStarted: false,
        messagesState: {
          list: [],
          prevMessagesQuery: null,
          searchQuery: null,
          requestState: RequestState.INITIAL,
          selectedList: [],
          allLoaded: null
        },
        sbChat: null,
        replyMessage: null
      };
    } else {
      return {
        ...state
      };
    }
  }),

  on(activeChatActions.metaDataUpdated, (state, {chat}) => {
    return {
      ...state,
      sbChat: chat
    };
  }),

  on(chatPageActions.userMuteChanged, (state, {chat, user, myUserId}) => {
    const updatedMember = chat.members.find((m) => m.userId === user.userId);
    const isMe = updatedMember.userId === myUserId;
    if (state.chatUrl === chat.url && isMe) {
      return {
        ...state,
        footerState: updatedMember.isMuted ? ActiveChatFooter.NOT_ALLOWED : ActiveChatFooter.DEFAULT
      };
    }
    return {
      ...state
    };
  }),

  on(activeChatActions.loadChatPreview, (state: ActiveChatState, {profileId, chatUrl}) => {
    return {
      ...state,
      isStarted: false,
      chatUrl: chatUrl,
      recipientId: profileId
    };
  }),

  on(activeChatActions.chatPreviewProfileLoadedSuccesfully, (state: ActiveChatState, {profile, isAllowed}) => {
    return {
      ...state,
      isStarted: false,
      messagesState: {
        ...state.messagesState,
        allLoaded: true
      },
      // footerState: isAllowed ? ActiveChatFooter.DEFAULT : ActiveChatFooter.NOT_ALLOWED,
      profile
    };
  }),

  on(activeChatActions.recordVoice, (state: ActiveChatState) => {
    return {
      ...state,
      footerState: ActiveChatFooter.VOICE
    };
  }),

  on(activeChatActions.cancelVoiceRecording, (state: ActiveChatState) => {
    return {
      ...state,
      footerState: ActiveChatFooter.DEFAULT
    };
  }),

  on(
    activeChatActions.existingChatLoadedSuccesfully,
    (state: ActiveChatState, {messages, chat, profile, prevMessagesQuery, isAllowed}) => {
      return {
        ...state,
        isStarted: true,
        // footerState: ActiveChatFooter.DEFAULT,
        // footerType:
        // isAllowed && chat.myMutedState !== MutedState.MUTED ? ActiveChatFooter.DEFAULT : ActiveChatFooter.NOT_ALLOWED,
        messagesState: {
          ...state.messagesState,
          list: messages,
          prevMessagesQuery: prevMessagesQuery,
          requestState: RequestState.RESOLVED,
          allLoaded: messages?.length === 0
        },
        chatUrl: chat.url,
        sbChat: chat,
        profile: profile
      };
    }
  ),

  on(chatPageActions.newActiveChatLoadedSuccesfully, (state: ActiveChatState, {chat, profile, sbUser}) => {
    return {
      ...state,
      isStarted: true,
      // footerType: ActiveChatFooter.DEFAULT,
      footerState: chat.myMutedState !== MutedState.MUTED ? ActiveChatFooter.DEFAULT : ActiveChatFooter.NOT_ALLOWED,
      messagesState: {
        ...state.messagesState,
        list: [...state.messagesState.list, chat.lastMessage],
        prevMessagesQuery: null,
        requestState: RequestState.RESOLVED,
        allLoaded: false
      },
      chatUrl: chat.url,
      sbChat: chat,
      profile: profile
    };
  }),

  on(activeChatActions.loadPreviousMessages, (state: ActiveChatState) => {
    return {
      ...state,
      messagesState: {
        ...state.messagesState,
        requestState: RequestState.LOADING
      }
    };
  }),

  on(activeChatActions.loadPreviousMessagesSuccess, (state: ActiveChatState, {messages}) => {
    return {
      ...state,
      messagesState: {
        ...state.messagesState,
        list: [...messages, ...state.messagesState.list],
        requestState: RequestState.RESOLVED
      },
      allLoaded: messages?.length === 0 || messages?.length < CHAT_MESSAGES_QUERY_SIZE ? true : false
    };
  }),

  on(activeChatActions.selectMessage, (state: ActiveChatState, {message}) => {
    return {
      ...state,
      messagesState: {
        ...state.messagesState,
        selectedList: [...state.messagesState.selectedList, message]
      }
    };
  }),

  on(activeChatActions.deselectMessage, (state: ActiveChatState, {message}) => {
    const filtered = state.messagesState.selectedList.filter((m) => m.messageId !== message.messageId);
    return {
      ...state,
      messagesState: {
        ...state.messagesState,
        selectedList: filtered
      }
    };
  }),

  on(activeChatActions.cancelSelection, (state: ActiveChatState) => {
    return {
      ...state,
      messagesState: {
        ...state.messagesState,
        selectedList: [] as BaseMessage[]
      }
    };
  }),

  on(activeChatActions.messageDeleted, (state: ActiveChatState, {messageId}) => {
    const filteredMessages = state.messagesState.list.filter((m) => m.messageId !== messageId);
    return {
      ...state,
      messagesState: {
        ...state.messagesState,
        list: filteredMessages
      }
    };
  }),

  on(chatActions.chatCreatedSuccesfully, (state: ActiveChatState, {chat}) => {
    if (state.chatUrl === chat.url) {
      return {
        ...state,
        isStarted: true,
        chatUrl: chat.url
      };
    }
    return {
      ...state
    };
  }),

  on(activeChatActions.selectGifSearch, (state) => {
    return {
      ...state,
      footerState: ActiveChatFooter.GIF
    };
  }),

  on(activeChatActions.cancelGifSearch, (state) => {
    return {
      ...state,
      footerState: ActiveChatFooter.DEFAULT
    };
  }),

  on(activeChatActions.replyToMessage, (state, {message}) => {
    return {
      ...state,
      messagesState: {
        ...state.messagesState,
        selectedList: []
      },
      replyMessage: message
    };
  }),

  on(activeChatActions.cancelReply, (state) => {
    return {
      ...state,
      replyMessage: null
    };
  }),

  on(activeChatActions.searchQueryChanged, (state: ActiveChatState, {query}) => {
    return {
      ...state,
      messagesState: {
        ...state.messagesState,
        searchQuery: query,
        requestState: RequestState.LOADING
      }
    };
  }),

  on(activeChatActions.updateActiveProfileSuccess, (state: ActiveChatState, {profile}) => {
    return {
      ...state,
      profile
    };
  }),

  on(activeChatActions.loadSearchMessagesSuccess, (state: ActiveChatState, {messages}) => {
    return {
      ...state,
      messagesState: {
        ...state.messagesState,
        list: messages,
        requestState: RequestState.RESOLVED
      }
    };
  }),

  on(activeChatActions.cancelSearch, (state: ActiveChatState) => {
    return {
      ...state,
      messagesState: {
        ...state.messagesState,
        searchQuery: null as MessageSearchQuery
      }
    };
  }),

  on(activeChatActions.startingMessagseLoadedSuccesfully, (state: ActiveChatState, {messages, prevMessagesQuery}) => {
    return {
      ...state,
      messagesState: {
        ...state.messagesState,
        list: messages,
        requestState: RequestState.RESOLVED,
        prevMessagesQuery
      }
    };
  }),

  on(chatPageActions.addMessageToExistingChat, (state: ActiveChatState, {chat, message}) => {
    if (chat.url === state.chatUrl) {
      return {
        ...state,
        messagesState: {
          ...state.messagesState,
          list: [...state.messagesState.list, message]
        }
      };
    } else {
      return {
        ...state
      };
    }
  })
);

export function ActiveChatReducer(state: ActiveChatState = activeChatInitialState, action: Action) {
  return featureReducer(state, action);
}
