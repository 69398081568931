import {Component, Input, OnInit} from '@angular/core';
import {FormBuilder, Validators} from '@angular/forms';
import {NzModalRef} from 'ng-zorro-antd/modal';

import {SUPPORTED_LANGUAGES_LIST, SupportedLanguage} from '../../../models/supported-tranlsations';

@Component({
  selector: 'app-chat-translation-settings-dialog',
  templateUrl: './chat-translation-settings-dialog.component.html',
  styleUrls: ['./chat-translation-settings-dialog.component.scss']
})
export class ChatTranslationSettingsDialogComponent implements OnInit {
  @Input() preferredLanguage: string;
  @Input() channelTranslationDisabled: boolean;

  languages: SupportedLanguage[] = SUPPORTED_LANGUAGES_LIST;
  form = this.fb.group({
    globalTranlationEnabled: [false],
    preferredLanguage: [null],
    channelTranslationDisabled: [false]
  });

  constructor(
    private dialogRef: NzModalRef,
    private fb: FormBuilder
  ) {}

  ngOnInit(): void {
    this.form.patchValue({
      globalTranlationEnabled: !!this.preferredLanguage,
      preferredLanguage: this.preferredLanguage,
      channelTranslationDisabled: this.channelTranslationDisabled
    });

    this.onTranslationSwitchChange(!!this.preferredLanguage);
  }

  closeDialog() {
    this.dialogRef.close();
  }

  save() {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }
    this.dialogRef.close(this.form.value);
  }

  onTranslationSwitchChange(value: boolean) {
    const control = this.form.get('preferredLanguage');
    if (!value) {
      control.disable();
      control.removeValidators(Validators.required);
    } else {
      control.enable();
      control.setValidators(Validators.required);
    }
    control.updateValueAndValidity();
  }
}
