import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {ReactiveFormsModule} from '@angular/forms';
import {DtTranslateModule} from '@ui/translation/dt-translate.module';

import {FlushCheckboxFieldComponent} from './flush-checkbox-field.component';

@NgModule({
  declarations: [FlushCheckboxFieldComponent],
  exports: [FlushCheckboxFieldComponent],
  imports: [CommonModule, DtTranslateModule, ReactiveFormsModule]
})
export class FlushCheckboxFieldModule {}
