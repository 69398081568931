<div
  class="dt-input-field-wrapper"
  [ngClass]="{
    'no-margin': noMargin
  }">
  <label *ngIf="label" class="dt-input-label" for="dt-input--{{ name }}">
    {{ label | dtTranslate }}
  </label>
  <div class="dt-input-wrapper">
    <ng-container *ngIf="prefixRef">
      <div class="affix-holder prefix">
        <ng-container *ngTemplateOutlet="prefixRef"></ng-container>
      </div>
    </ng-container>
    <input
      #inputRef
      class="dt-input"
      [attr.test-id]="testId"
      [attr.inputmode]="inputMode"
      [class.has-suffix]="suffixRef || isCopyableField || isPasswordField"
      id="dt-input--{{ name }}"
      [ngStyle]="{'text-align': align && placeholderAlign}"
      [ngClass]="{
        hasSuffix: isPasswordField ? control.value : !!suffixRef,
        hasPrefix: !!prefixRef,
        borderless: borderless,
        center: align === 'center' || placeholderAlign === 'center',
        'moderation-error': control?.getError('moderationFailed')
      }"
      [autocomplete]="autocomplete"
      [formControl]="control"
      [placeholder]="placeholder | dtTranslate"
      [maxlength]="maxLength"
      [type]="type"
      [readonly]="readonly"
      (keydown)="keydownEmit($event)"
      (paste)="pasteEmit($event)"
      (focus)="focusChangeEmit(true)"
      (blur)="focusChangeEmit(false)" />
    <div class="affix-holder suffix">
      <ng-container *ngIf="isCopyableField; then copyIcon"></ng-container>
      <ng-container *ngIf="isPasswordField; then passwordIcon"></ng-container>
      <ng-container *ngIf="suffixRef; then suffixRef"></ng-container>
    </div>
  </div>
</div>
<ng-template #passwordIcon>
  <i
    *ngIf="control.value"
    class="password-icon"
    nz-icon
    nzType="ng-zorro:{{ type === 'password' ? 'eye' : 'eye-crossed' }}"
    (click)="toggleType()">
  </i>
</ng-template>
<ng-template #copyIcon>
  <div class="copy-icon" (click)="copyLink(inputRef)">
    <span *ngIf="valueIsCopied">{{ 'COPIED' | dtTranslate }}</span>
    <i nz-icon nzType="ng-zorro:copy"></i>
  </div>
</ng-template>
