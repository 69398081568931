<dt-dialog [closable]="closable">
  <dt-dialog-title> {{ dialogTitle | dtTranslate }}</dt-dialog-title>
  <dt-dialog-body [removePadding]="true" [fullWidth]="true">
    <div class="confirmation-container">
      <div *ngIf="icon" class="icons-container icons-container_{{ iconType }}">
        <div class="icon primary">
          <i nz-icon [nzType]="icon"></i>
        </div>
      </div>
      <app-section-description
        *ngIf="title || description"
        [type]="SectionDescriptionComponentEnum.H4"
        [align]="sectionAlign"
        [title]="title"
        [descriptionHtml]="description"
        [descriptionAlign]="descriptionAlign"
        (linkClick)="closeDialog()">
      </app-section-description>
      <div *ngIf="media" class="media-container">
        <dt-media-content [media]="media"></dt-media-content>
      </div>
      <ng-container *ngTemplateOutlet="content"></ng-container>
    </div>
    <div *ngIf="privacyConfirm" class="privacy-container">
      <app-flush-checkbox-field direction="column" [fields]="privacyCheckbox" [control]="privacyControl">
      </app-flush-checkbox-field>
    </div>
  </dt-dialog-body>
  <dt-dialog-footer>
    <dt-button
      *ngFor="let button of buttons"
      [size]="button.size"
      [type]="button.type"
      [disabled]="button.privacyCheck && !privacyConfirmed"
      (click)="submitDialog(button)">
      {{ button.title | dtTranslate }}
    </dt-button>
  </dt-dialog-footer>
</dt-dialog>
