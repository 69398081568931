import {createReducer, on} from '@ngrx/store';
import {chatActions} from 'projects/dating-app/src/app/core/store/actions';

import {ChatState, initialChatState} from '../state/chat.state';

export const chatReducer = createReducer(
  initialChatState,
  on(chatActions.userConnected, (state: ChatState, {user}) => {
    return {
      ...state,
      user: user
    };
  }),

  on(chatActions.unreadMessagesCountUpdated, (state: ChatState, {count}) => {
    return {
      ...state,
      unreadMessagesCount: count
    };
  })
);
