import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {NzAlertModule} from 'ng-zorro-antd/alert';

import {AlertComponent} from './components/alert/alert.component';

@NgModule({
  declarations: [AlertComponent],
  imports: [CommonModule, NzAlertModule]
})
export class AlertsModule {}
