import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {UserMessage} from '@sendbird/chat/message';
import {environment} from 'projects/dating-app/src/environments/environment';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ChatContentService {
  constructor(private http: HttpClient) {}

  extractMediaKeys(message: UserMessage): string[] {
    let keys = [];
    const parsedData = JSON.parse(message.data);
    if (parsedData) {
      keys = parsedData.mediaKeys;
    }
    return keys;
  }

  uploadFile(chatUrl: string, file: File, showPolicy: boolean): Observable<{key: string}> {
    const formData = new FormData();
    formData.set('files', file, file.name.toLowerCase());
    formData.append('consentCheckBox', 'true');
    formData.append('identityCheckBox', 'true');
    formData.append('TOSAgreementCheckBox', 'true');
    formData.append('doNotShowAgainCheckBox', String(showPolicy));

    return this.http.post<{key: string}>(`${environment.API_URL}/content/chats/${chatUrl}/files`, formData);
  }

  deleteFileByKey(chatUrl: string, fileKey: string): Observable<void> {
    return this.http.delete<void>(`${environment.API_URL}/content/chats/${chatUrl}/files`, {
      params: {fileKey: fileKey}
    });
  }

  getFileByKey(chatUrl: string, fileKey: string): Observable<{url: string}> {
    return this.http.get<{url: string}>(`${environment.API_URL}/content/chats/${chatUrl}/files`, {
      params: {fileKey: fileKey}
    });
  }

  async compressImage(file: File, quality = 0.7): Promise<Blob> {
    const promise = new Promise<Blob>((resolve) => {
      const blobURL = window.URL.createObjectURL(file);
      const img = new Image();
      img.src = blobURL;
      img.onload = function (ev) {
        window.URL.revokeObjectURL(blobURL); // release memory
        // Use the img
        const canvas = document.createElement('canvas');
        canvas.width = img.width;
        canvas.height = img.height;
        const ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0, img.width, img.height);
        canvas.toBlob(
          (blob) => {
            resolve(blob);
          },
          file.type,
          quality
        );
      };
    });
    return promise;
  }
}
