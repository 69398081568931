import {Injectable} from '@angular/core';
import {Store} from '@ngrx/store';
import {User} from '@sendbird/chat';
import {GroupChannel} from '@sendbird/chat/groupChannel';
import {BaseMessage, UserMessage, UserMessageCreateParams} from '@sendbird/chat/message';
import {DtDialogService} from '@ui/components/dt-dialogs/services/dt-dialog.service';
import {DtProfileType} from '@ui/models/dt-profile.model';
import {URL_REGEX} from 'projects/dating-app/src/app/core/constants/regex.constants';
import {Observable, Subject, take, withLatestFrom} from 'rxjs';

import {ProfileMainInfoV2} from '../../profile/models/profile.model';
import {AccessRequestMessageState} from '../active-chat/components/chat-acess-request-dialog/chat-access-request.model';
import {ChatTranslationSettingsDialogComponent} from '../active-chat/components/chat-translation-settings-dialog/chat-translation-settings-dialog.component';
import {AccessRequestType} from '../active-chat/components/messages/access-request-message/access-request-message.model';
import {activeChatActions} from '../active-chat/store/actions';
import {fromActiveChat} from '../active-chat/store/selectors';
import {GroupChannelMetadata, MessageCustomType, TranslationsSettings, UserMetadata} from '../models/chat.model';
import {fromUserData} from '../store/selectors';
import {ActiveChatService} from './active-chat.service';

@Injectable({
  providedIn: 'root'
})
export class ChatDisplayService {
  chatUpdate$ = new Subject<{chatUrl: string}>();
  private mediaPlaying$ = new Subject<{messageId: number}>();

  private scrollChat$ = new Subject<void>();
  private chatInputValue$ = new Subject<string>();

  constructor(
    private store: Store,
    private dialogService: DtDialogService,
    private activeChatService: ActiveChatService
  ) {}

  getScrollChatListenter(): Subject<void> {
    return this.scrollChat$;
  }

  triggerChatScroll(): void {
    this.scrollChat$.next();
  }

  updateInputValue(value: string): void {
    this.chatInputValue$.next(value);
  }

  getChatInputUpdates(): Subject<string> {
    return this.chatInputValue$;
  }

  mediaPlaying(messageId: number): void {
    this.mediaPlaying$.next({messageId});
  }

  getMediaPlayingListener(): Observable<{messageId: number}> {
    return this.mediaPlaying$.asObservable();
  }

  static isMessageReported(message: BaseMessage): boolean {
    if (!message) {
      return false;
    }
    const data = message.data?.length > 0 ? JSON.parse(message.data) : {};
    return !!data.isReported;
  }

  static hasInvisibleAvatar(targetProfile: ProfileMainInfoV2): boolean {
    return !!targetProfile?.visibility;
  }

  static canAccessMessages(chat: GroupChannel, targetProfile: ProfileMainInfoV2): boolean {
    const profileCouldAccesss = ChatDisplayService.canAccessMessagesByProfile(targetProfile);

    const myProfiledId = chat.url.replaceAll(targetProfile.profileId, '');
    const meBlocked = ChatDisplayService.isUserBlocked(chat, myProfiledId);
    const isRecipientBlocked = ChatDisplayService.isUserBlocked(chat, targetProfile.profileId);

    return profileCouldAccesss && !meBlocked && !isRecipientBlocked;
  }

  static canAccessMessagesByProfile(targetProfile: ProfileMainInfoV2): boolean {
    const isInvisible = targetProfile.visibility;
    if (isInvisible) {
      return false;
    }

    const isRecipientDaddy = targetProfile?.profileSummary?.type === DtProfileType.DADDY;
    const hasSubscription = targetProfile.isViewerFan || targetProfile.viewerUserHasPremium;

    if (isRecipientDaddy) {
      return true;
    } else {
      return hasSubscription;
    }
  }

  static isUserBlocked(chat: GroupChannel, profileId: string): boolean {
    if (chat && chat.cachedMetaData) {
      const metadata: GroupChannelMetadata = chat.cachedMetaData as GroupChannelMetadata;
      if (metadata.blocks) {
        const blockObject = JSON.parse(metadata.blocks);
        const key = `blocked_${profileId}`;
        const isBlocked = !!blockObject[key];
        return isBlocked;
      } else {
        return false;
      }
    }

    return true;
  }

  static shouldBeTranslated(chat: GroupChannel, user: User): boolean {
    const isDisabled = ChatDisplayService.isUserDisabledChannelTranslations(chat, user.userId);
    const preferredLanguage = ChatDisplayService.getPrferredLanguage(user);
    return !isDisabled && !!preferredLanguage;
  }

  static getAccessMessageRequestParams(
    profileType: DtProfileType,
    tabType: 'open' | 'request'
  ): UserMessageCreateParams {
    let state;
    let type;

    if (profileType === DtProfileType.DADDY) {
      if (tabType === 'open') {
        state = AccessRequestMessageState.OPENED;
        type = AccessRequestType.PRIVATE;
      }
      if (tabType === 'request') {
        state = AccessRequestMessageState.REQUESTED;
        type = AccessRequestType.FAN;
      }
    }

    if (profileType === DtProfileType.BABY) {
      if (tabType === 'open') {
        state = AccessRequestMessageState.OPENED;
        type = AccessRequestType.FAN;
      }
      if (tabType === 'request') {
        state = AccessRequestMessageState.REQUESTED;
        type = AccessRequestType.PRIVATE;
      }
    }

    return {
      customType: MessageCustomType.ACCESS_REQUEST,
      data: JSON.stringify({
        state,
        type
      }),
      message: 'CUSTOM_MESSAGE_TYPE'
    };
  }

  static getPrferredLanguage(user: User): string {
    const metaData = user.metaData as UserMetadata;
    return metaData.preferredLanguage;
  }

  static isUserDisabledChannelTranslations(chat: GroupChannel, userId: string): boolean {
    const disableTranslationForUsers: string[] = ChatDisplayService.getDisableTranslationForUsersMetaData(chat);
    const userDisabled = disableTranslationForUsers?.find((uId) => uId === userId);
    return !!userDisabled;
  }

  static getDisableTranslationForUsersMetaData(chat: GroupChannel): string[] {
    const disableTranslationForUsers: string[] = (chat.cachedMetaData as any).disableTranslationForUsers
      ? JSON.parse((chat.cachedMetaData as any).disableTranslationForUsers)
      : [];
    return disableTranslationForUsers;
  }

  openTranslationsSettingDialog(): void {
    this.activeChatService.getSbChat().pipe();

    this.store
      .select(fromUserData.selectSbUser)
      .pipe(withLatestFrom(this.store.select(fromActiveChat.selectSendbirdChat)), take(1))
      .subscribe(([user, chat]) => {
        const preferredLanguage = ChatDisplayService.getPrferredLanguage(user);
        const channelTranslationDisabled = ChatDisplayService.isUserDisabledChannelTranslations(chat, user.userId);
        this.dialogService
          .openDialog(ChatTranslationSettingsDialogComponent, {
            data: {
              preferredLanguage,
              globalTranlationEnabled: !!preferredLanguage,
              channelTranslationDisabled
            } as TranslationsSettings
          })
          .afterClose.subscribe((settings) => {
            if (settings) {
              this.store.dispatch(activeChatActions.saveTranslationSettings({settings}));
            }
          });
      });
  }

  static getTranslatedMessage(
    chat: GroupChannel,
    authUserProfileId: string,
    messageObject: UserMessage,
    formatLinks: boolean
  ) {
    let finalMessage = null;
    const me = chat?.members.find((m) => m.userId === authUserProfileId);
    const shouldBeTranslated = ChatDisplayService.shouldBeTranslated(chat, me);
    const isMine = messageObject?.sender?.userId === authUserProfileId;
    if (isMine && !shouldBeTranslated) {
      finalMessage = messageObject?.message;
      // this.isTranlated = false;
    } else {
      const lang = ChatDisplayService.getPrferredLanguage(me);
      const translationObject = messageObject?.translations as any;
      const tranlsatedMessage = translationObject ? translationObject[lang] : null;
      // this.isTranlated = !!tranlsatedMessage;
      finalMessage = tranlsatedMessage && !isMine ? tranlsatedMessage : messageObject.message;
    }
    if (formatLinks) {
      finalMessage = ChatDisplayService.detectAndReplaceLinks(finalMessage);
    }

    return finalMessage;
  }

  static detectAndReplaceLinks(messageText: string): string {
    if (!messageText) {
      return '';
    }
    const formatted = messageText?.replace(URL_REGEX, function (url) {
      return ' <a class="chat-link" target="_blank" href="' + url + '">' + url + '</a> ';
    });
    return formatted;
  }
}
