import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';

import {DtMediaContentComponent} from './dt-media-content.component';

@NgModule({
  declarations: [DtMediaContentComponent],
  exports: [DtMediaContentComponent],
  imports: [CommonModule]
})
export class DtMediaContentModule {}
