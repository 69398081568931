import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';

import {MeasurementPipe} from './measurement.pipe';

@NgModule({
  declarations: [MeasurementPipe],
  exports: [MeasurementPipe],
  imports: [CommonModule]
})
export class MeasurementModule {}
