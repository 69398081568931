import {MediaMatcher} from '@angular/cdk/layout';
import {Injectable} from '@angular/core';
import {NavigationStart, Router, Scroll} from '@angular/router';
import {BehaviorSubject, debounceTime, fromEvent, map, Observable, startWith} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ScreenService {
  private tabletMediaQuery: MediaQueryList;
  private isTabletMode = false;
  private screenMode$ = new BehaviorSubject(this.isTabletMode);

  canRestoreScroll: boolean;
  private shouldRestoreScroll: boolean;

  constructor(
    private media: MediaMatcher,
    private router: Router
  ) {}

  init(): void {
    this.handleTabletModeUpdates();
    this.handleScrollPosition();
    this.handleDoubleClick();
  }

  private handleTabletModeUpdates(): void {
    this.tabletMediaQuery = this.media.matchMedia('(max-width: 1023px)');
    this.isTabletMode = this.tabletMediaQuery.matches;
    this.screenMode$.next(this.isTabletMode);
    this.tabletMediaQuery.addListener((mediaQuery) => {
      this.isTabletMode = mediaQuery.matches;
      this.screenMode$.next(this.isTabletMode);
    });
  }

  getScreenMode(): BehaviorSubject<boolean> {
    return this.screenMode$;
  }

  getWindowHeight(): Observable<number> {
    return fromEvent(window, 'resize').pipe(
      debounceTime(100),
      map(() => {
        return window.innerHeight;
      }),
      startWith(window.innerHeight)
    );
  }

  private handleScrollPosition(): void {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.canRestoreScroll = event.navigationTrigger === 'popstate';
      }
      if (event instanceof Scroll) {
        if (this.canRestoreScroll && this.shouldRestoreScroll) {
          const [xPosition, yPosition] = event.position;
          window.scroll({top: yPosition, left: xPosition});
        } else {
          window.scroll({top: 0, left: 0});
        }
      }
    });
  }

  restoreScroll(): void {
    this.shouldRestoreScroll = true;
  }

  setScrollTop(): void {
    window.scroll({
      top: 0,
      left: 0
    });
  }

  scrollToElement(element: Element): void {
    element.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
      inline: 'nearest'
    });
  }

  scrollToTop(): void {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }

  private handleDoubleClick(): void {
    document.addEventListener('dblclick', () => {});
  }

  // private handleOverScroll(): void {
  //   let isTouching = false;
  //   window.addEventListener('touchstart', () => {
  //     isTouching = true;
  //   });
  //   window.addEventListener('touchend', () => {
  //     isTouching = false;
  //     this.checkOverScroll();
  //   });
  //   window.addEventListener('scroll', () => {
  //     if (!isTouching) {
  //       this.checkOverScroll();
  //     }
  //   });
  // }
  //
  // private checkOverScroll() {
  //   const scrollHeight = document.documentElement.scrollHeight;
  //   const currentScrollPosition = window.scrollY + document.documentElement.clientHeight;
  //   const overScroll = scrollHeight - currentScrollPosition;
  //   const safariSpaceSize = 255;
  //
  //   if (overScroll < safariSpaceSize + -1) {
  //     window.scrollTo({
  //       top: document.documentElement.scrollHeight - document.documentElement.clientHeight + safariSpaceSize
  //     });
  //   }
  // }
}
