import {createSelector} from '@ngrx/store';
import {DtProfileType} from '@ui/models/dt-profile.model';
import {DateUtil} from 'projects/dating-app/src/app/core/utils/date.util';

import {ActiveChatFooter, MessagesGroupByDate, RequestState} from '../../../models/chat.model';
import {ChatDisplayService} from '../../../services/chat-display.service';
import {selectChatPageState} from '../../../store/selectors/chat-page.selectors';
import {selectIsMeInvisible} from '../../../store/selectors/user-data.selectors';
import {ChatPageState} from '../../../store/state/chat-page.state';

export const selectActiveChatState = createSelector(selectChatPageState, (state: ChatPageState) => state.activeChat);

export const selectMessagesState = createSelector(selectActiveChatState, (state) => state.messagesState);

export const selectProfile = createSelector(selectActiveChatState, (state) => state.profile);
export const selectProfileAge = createSelector(selectProfile, (profile) => {
  const born = profile?.profileSummary?.born;
  if (born) {
    return new Date(born).getFullYear();
  }
  return null;
});

export const selectProfileFirstOccuptaion = createSelector(selectProfile, (profile) => {
  const {occupations} = profile.aboutMe;
  if (Object.keys(occupations).length) {
    return 'OCCUPATIONS.' + occupations[0].title;
  }
  return null;
});

export const selectIsRecipientDaddy = createSelector(selectProfile, (profile) => {
  if (profile?.profileSummary?.type === DtProfileType.DADDY) {
    return true;
  }
  return false;
});

export const selectProfileLocationName = createSelector(selectProfile, (profile) => {
  return profile?.profileSummary?.primaryLocation?.position.name;
});

export const selectSearchQuery = createSelector(selectMessagesState, (state) => state.searchQuery);

export const selectPrevMessagesQuery = createSelector(selectMessagesState, (state) => state.prevMessagesQuery);

export const selectSendbirdReciepient = createSelector(selectActiveChatState, (activeChatState) => {
  const profileId = activeChatState?.recipientId;
  const recepient = activeChatState.sbChat?.members.find((m) => m.userId === profileId);
  return recepient || null;
});

export const selectReciepientPrefferedLangulage = createSelector(selectSendbirdReciepient, (user) =>
  ChatDisplayService.getPrferredLanguage(user)
);

export const selectRecipientId = createSelector(selectActiveChatState, (state) => state.recipientId);

export const selectSendbirdChat = createSelector(selectActiveChatState, (state) => state.sbChat);

export const selectMessages = createSelector(selectMessagesState, (state) => state.list);

export const selectIsRecipientBlocked = createSelector(selectActiveChatState, (state) => {
  if (state.sbChat) {
    return ChatDisplayService.isUserBlocked(state.sbChat, state.recipientId);
  }

  return false;
});

export const selectIsMeBlocked = createSelector(selectActiveChatState, (state) => {
  if (state.sbChat) {
    const myProfiledId = state.sbChat.url.replaceAll(state.recipientId, '');
    return ChatDisplayService.isUserBlocked(state.sbChat, myProfiledId);
  }

  return false;
});

export const selectMessagesGroupesByDate = createSelector(selectMessages, (messages) => {
  const groups: MessagesGroupByDate[] = [];
  let currentGroup: MessagesGroupByDate = {
    date: null,
    messages: []
  };
  messages = messages.filter((m) => !ChatDisplayService.isMessageReported(m));
  messages.forEach((m, index) => {
    if (currentGroup?.messages.length) {
      const messageDay = DateUtil.formatToYYYYMMDD(m.createdAt);
      if (currentGroup.date === messageDay) {
        currentGroup.messages.push(m);
      } else {
        groups.push({...currentGroup});
        currentGroup = {
          date: DateUtil.formatToYYYYMMDD(m.createdAt),
          messages: [m]
        };
      }
    } else {
      currentGroup.date = DateUtil.formatToYYYYMMDD(m.createdAt);
      currentGroup.messages.push(m);
    }

    if (index === messages.length - 1) {
      if (currentGroup?.messages.length) {
        groups.push({...currentGroup});
      }
    }
  });
  return groups;
});

export const selectMessagesIsLoading = createSelector(
  selectMessagesState,
  (state) => state.requestState === RequestState.LOADING
);

export const selectIsChatStarted = createSelector(selectActiveChatState, (state) => state.isStarted);

export const selectIsTargetUserInvisible = createSelector(selectProfile, (profile) => {
  return !!profile?.visibility || !!profile?.invisible;
});

export const selectAllMessgesAreLoaded = createSelector(selectMessagesState, (state) => state.allLoaded);

export const selectSelectedMessages = createSelector(selectMessagesState, (state) => state?.selectedList);

export const selectHasSelectedMessages = createSelector(selectSelectedMessages, (list) => list?.length > 0);

// export const selectShowSelectionBar = createSelector(selectHasSelectedMessages,)

export const selectIsMessageSelected = (messageId: number) =>
  createSelector(selectSelectedMessages, (messages) => !!messages.find((m) => m.messageId === messageId));

export const hasSelectedItems = createSelector(selectMessagesState, (state) => state?.selectedList?.length > 0);

export const selectIsEmptyChat = createSelector(
  selectMessagesIsLoading,
  selectMessagesGroupesByDate,
  selectPrevMessagesQuery,
  (loading, messages, query) => !loading && messages?.length === 0 && !query
);

export const selectIsEmptySearchResults = createSelector(
  selectMessagesIsLoading,
  selectMessagesGroupesByDate,
  selectSearchQuery,
  (loading, messages, query) => !loading && messages?.length === 0 && query
);

export const selectLastMessage = createSelector(selectSendbirdChat, (state) => {
  return state?.lastMessage;
});
export const isChatDataLoaded = createSelector(
  selectProfile,
  selectMessagesIsLoading,
  (user, selectMessagesIsLoading) => user && !selectMessagesIsLoading
);

export const selectTargerUserHaveSubsctiption = createSelector(selectProfile, (profile) => {
  return profile?.isFanToViewer || profile?.targetUserHasPremium;
});

export const selectViewerHaveSubsctiption = createSelector(selectProfile, (profile) => {
  return profile?.isViewerFan || profile?.viewerUserHasPremium;
});

export const selectTargetUserIsDeleted = createSelector(selectProfile, (profile) => {
  return !!profile?.deleted;
});

export const selectReplyMessage = createSelector(selectActiveChatState, (state) => state.replyMessage);
export const selectFooterState = createSelector(selectActiveChatState, (state) => state.footerState);

export const selectIsMeInvisibleBasedOnPlan = createSelector(
  selectIsMeInvisible,
  selectViewerHaveSubsctiption,
  (isInvisible, hasSubscription) => {
    return isInvisible && !hasSubscription;
  }
);

export const selectIsMeInvisibleForCurrectUser = createSelector(
  selectIsMeInvisible,
  selectTargerUserHaveSubsctiption,
  (isInvisible, hasSubscription) => {
    return isInvisible && !hasSubscription;
  }
);

export const selectIsAllowedToWrite = createSelector(
  selectIsTargetUserInvisible,
  selectIsRecipientDaddy,
  selectIsMeBlocked,
  selectIsRecipientBlocked,
  selectViewerHaveSubsctiption,
  selectTargetUserIsDeleted,
  (selectIsUserInvisible, isDaddy, meBlocked, recipientBlocked, hasSubscription, isDeleted) => {
    if (selectIsUserInvisible) {
      return false;
    }
    if (isDaddy) {
      return true;
    }
    return !meBlocked && !recipientBlocked && (hasSubscription || isDeleted);
  }
);

export const isShowQuickMessages = createSelector(
  isChatDataLoaded,
  selectIsAllowedToWrite,
  (isChatDataLoaded, isAllowed) => {
    return isChatDataLoaded && isAllowed;
  }
);

export const selectFooterType = createSelector(
  selectProfile,
  selectIsMeBlocked,
  selectIsRecipientBlocked,
  selectFooterState,
  selectHasSelectedMessages,
  selectViewerHaveSubsctiption,
  selectIsTargetUserInvisible,
  selectIsMeInvisibleForCurrectUser,
  selectIsRecipientDaddy,
  (
    profile,
    isMeBlocked,
    isRecipientBlocked,
    footerState,
    hasSelection,
    hasSubscription,
    isTargetInvisible,
    isMeInvisible,
    isDaddy
  ) => {
    if (!profile) {
      return ActiveChatFooter.INITIAL;
    }
    if (profile.deleted) {
      return ActiveChatFooter.ME_BLOCKED;
    }
    if (isTargetInvisible || isMeInvisible) {
      return ActiveChatFooter.USER_HIDDEN;
    }

    if (isMeBlocked) {
      return ActiveChatFooter.ME_BLOCKED;
    }

    if (isRecipientBlocked) {
      return ActiveChatFooter.USER_BLOCKED;
    }

    if (!hasSubscription && !isDaddy) {
      return ActiveChatFooter.NOT_ALLOWED;
    }

    if (hasSelection) {
      return ActiveChatFooter.SELECTION_ACTIONS;
    }

    if (footerState === ActiveChatFooter.VOICE || footerState === ActiveChatFooter.GIF) {
      return footerState;
    }

    return ActiveChatFooter.DEFAULT;
  }
);
