import {Component, EventEmitter, Input, Output} from '@angular/core';
import {AbstractControl, FormControl} from '@angular/forms';
import {DtMessage} from '@ui/translation/models/translate.model';

@Component({
  template: ''
})
export abstract class BaseFieldComponent {
  @Input() name: string;
  @Input() control: FormControl | AbstractControl;
  @Input() isDisabled: boolean;
  @Input() placeholder: DtMessage;
  @Input() label: DtMessage;

  @Output() valueChange = new EventEmitter();
}
