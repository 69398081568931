import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild
} from '@angular/core';
import {distinctUntilChanged, Subject, takeUntil} from 'rxjs';

import {BaseFieldComponent} from '../base-field/base-field.component';

@Component({
  selector: 'app-flush-input-field',
  templateUrl: './flush-input-field.component.html',
  styleUrls: ['./flush-input-field.component.scss']
})
export class FlushInputFieldComponent extends BaseFieldComponent implements OnInit, OnChanges, OnDestroy {
  originalType: string;
  destroyed$ = new Subject();

  @Input() type: 'text' | 'password' | 'number' = 'text';
  @Input() align: 'center' | 'default' = 'default';
  @Input() borderless = false;
  @Input() readonly = false;
  @Input() placeholderAlign: 'center';
  @Input() maxLength: number | string = 'unset';
  @Input() autocomplete: string;
  @Input() prefixRef: TemplateRef<any>;
  @Input() suffixRef: TemplateRef<any>;
  @Input() removeMargin = false;
  @Output() focusChange = new EventEmitter<any>();

  @ViewChild('inputRef') inputRef: ElementRef<HTMLInputElement>;

  get isPasswordField(): boolean {
    return this.originalType === 'password' && !this.suffixRef;
  }

  constructor() {
    super();
  }

  ngOnInit() {
    this.control?.valueChanges
      .pipe(takeUntil(this.destroyed$), distinctUntilChanged())
      .subscribe((value) => this.valueChange.emit(value));
  }

  ngOnChanges(changes: SimpleChanges): void {
    const {type} = changes;
    if (type) {
      this.originalType = type.currentValue;
    }
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  toggleType() {
    if (this.type === 'password') {
      this.type = 'text';
    } else {
      this.type = 'password';
    }
  }

  onFocusChange(value: boolean) {
    this.focusChange.emit(value);
  }
}
