import {User} from '@sendbird/chat';

export const CHAT_FEATURE_KEY = 'chat';

export interface ChatState {
  user: User;
  unreadMessagesCount: number;
}

export const initialChatState: ChatState = {
  user: null,
  unreadMessagesCount: 0
};
