import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {DtTranslateModule} from '@ui/translation/dt-translate.module';
import {NzDividerModule} from 'ng-zorro-antd/divider';
import {NzIconModule} from 'ng-zorro-antd/icon';
import {NzSelectModule} from 'ng-zorro-antd/select';

import {DtInputComponent} from './components/dt-input/dt-input.component';

@NgModule({
  declarations: [DtInputComponent],
  exports: [DtInputComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    NzIconModule,
    NzDividerModule,
    NzSelectModule,
    FormsModule,
    DtTranslateModule
  ]
})
export class DtFieldsModule {}
