import {User} from '@sendbird/chat';
import {GroupChannel} from '@sendbird/chat/groupChannel';
import {BaseMessage, UserMessage} from '@sendbird/chat/message';
import {DtProfileShort, DtProfileType} from '@ui/models/dt-profile.model';

import {ProfileMainInfoV2} from '../../profile/models/profile.model';

export enum ConversationFilters {
  ALL = 'all',
  UNREAD = 'unread',
  NEW_CONNECTIONS = 'newConnections'
}

export enum MessageCustomType {
  VIDEO = 'VIDEO',
  IMAGE = 'IMAGE',
  MEDIA = 'MEDIA',
  GIF = 'GIF',
  VOICE = 'VOICE',
  PROFILE = 'PROFILE',
  POST = 'POST',
  ACCESS_REQUEST = 'ACCESS_REQUEST',
  TIP = 'TIP'
}

export enum RequestState {
  INITIAL = 'initial',
  LOADING = 'loading',
  RESOLVED = 'resolved',
  ERROR = 'error'
}
export interface ChatListItemData {
  profile: ProfileMainInfoV2;
  sbUser: User;
  sbChat: GroupChannel;
}

export interface MessagesGroupByDate {
  date: string;
  messages: BaseMessage[];
}

export const CHAT_MESSAGES_QUERY_SIZE = 20;

export interface QuickMessage {
  message: string;
  language: string;
}

export interface UserMetadata {
  preferredLanguage: string;
  profileType: DtProfileType;
}

export interface GroupChannelMetadata {
  disableTranslationForUsers: string[];
  blocks: any;
}

export interface TranslationsSettings {
  preferredLanguage: string;
  channelTranslationDisabled: boolean;
}

export interface CheckAllowedResponse {
  conversationAllowed: boolean;
  isFanToViewer: boolean;
  isViewerFan: boolean;
  viewerUserHasPremium: boolean;
}

export enum ActiveChatFooter {
  DEFAULT = 'default',
  NOT_ALLOWED = 'not-allowed',
  ME_BLOCKED = 'me-blocked',
  USER_HIDDEN = 'user-hidden',
  USER_BLOCKED = 'user-blocked',
  SELECTION_ACTIONS = 'selection-actions',
  GIF = 'gif',
  INITIAL = 'initial',
  VOICE = 'voice'
}

export interface MessageSearchItemData {
  profile: ProfileMainInfoV2;
  message: BaseMessage;
}

export interface ChatPostMessageData {
  postId: string;
  ownerId: string;
  ownerProfileType: DtProfileType;
}

export interface AccessTokenResponse {
  expires_at: number;
  token: string;
}

export interface ReportMessagePayload {
  targetProfileId: string;
  data: {
    entityId: string;
    contentType: string;
    report: string;
    description: string;
  };
}

export interface ChatProfiletDisplayModel {
  // aboutMe: ProfileMainInfoV2AboutMe;
  // avatarUrl: string[];
  // followersCount: number;
  // followingCount: number;
  isFollowedByViewer: boolean;
  isFollowerToViewer: boolean;
  // isOnline: boolean;
  isFanToViewer: boolean;
  isViewerFan: boolean;
  likesFromMe: boolean;
  likesYou: boolean;
  profileId: string;
  profileShort: DtProfileShort;
  // profileSummary: ProfileMainInfoV2Summary;
  // seeking: any[];
  // statusHeading: any;
  targetUserHasPremium: boolean;
  viewerUserHasPremium: boolean;
  // visibility: any;
}

export type UserMessageWithProfile = UserMessage & {profile: ProfileMainInfoV2};
