import {createAction, props} from '@ngrx/store';

import {User, UserAgreement, UserBalance, UserPaymentsBlocks} from '../../models/user.model';

const actor = '[User]';

export const setUser = createAction(`${actor} User set`, props<{user: User}>());
export const loadUserBalance = createAction(`${actor} Load user balance`);
export const loadUserBalanceSuccess = createAction(
  `${actor} Load user balance success`,
  props<{balance: UserBalance}>()
);
export const loadUserAgreement = createAction(`${actor} Load user agreement`);
export const loadUserAgreementSuccess = createAction(
  `${actor} Load user agreement success`,
  props<{agreement: UserAgreement}>()
);
export const loadUserPaymentsBlocks = createAction(`${actor} Load user payments blocks`);
export const loadUserPaymentsBlocksSuccess = createAction(
  `${actor} Load user payments blocks success`,
  props<{paymentsBlocks: UserPaymentsBlocks}>()
);
