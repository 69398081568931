<ng-container *ngFor="let field of fields; let index = index">
  <label
    class="flush-radio-wrapper"
    [ngClass]="{
      checked: control?.value === field.value,
      column: direction === 'column'
    }"
    for="radio-field--{{ field.name }}-{{ index }}">
    <div class="flush-radio">
      <input
        #input
        type="radio"
        class="flush-radio-input"
        id="radio-field--{{ field.name }}-{{ index }}"
        [name]="field.name"
        [value]="field.value"
        [checked]="control?.value === field.value"
        (change)="onChange(field.value)" />
      <span class="flush-radio-inner"></span>
    </div>
    <div class="flush-radio-label-container">
      <span class="flush-radio-label-text">{{ field.label | dtTranslate }}</span>
      <ng-container *ngIf="field.description">
        <span class="flush-radio-label-description">{{ field.description | dtTranslate }}</span>
      </ng-container>
    </div>
  </label>
</ng-container>
