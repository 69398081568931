import {createAction, props} from '@ngrx/store';
import {ReportCategory} from '@sendbird/chat';
import {GroupChannel} from '@sendbird/chat/groupChannel';
import {MessageSearchQuery, PreviousMessageListQuery} from '@sendbird/chat/lib/__definition';
import {BaseMessage, ReactionEvent, UserMessage} from '@sendbird/chat/message';
import {ProfileMainInfoV2} from 'projects/dating-app/src/app/features/main/pages/profile/models/profile.model';

import {TranslationsSettings} from '../../../models/chat.model';

const actor = '[ACTIVE CHAT]';

export const enterChat = createAction(`${actor} Enter chat`, props<{profileId: string; chatUrl: string}>());

export const leaveConversation = createAction(`${actor} Leave chat`);

export const existingChatLoadedSuccesfully = createAction(
  `${actor} Chat loaded succesfully`,
  props<{
    messages: BaseMessage[];
    chat: GroupChannel;
    profile: ProfileMainInfoV2;
    prevMessagesQuery: PreviousMessageListQuery;
    isAllowed: boolean;
  }>()
);

export const chatPreviewProfileLoadedSuccesfully = createAction(
  `${actor} Chat preview profile loaded succesfully`,
  props<{profile: ProfileMainInfoV2; isAllowed: boolean}>()
);

export const messageDeleted = createAction(`${actor} Message deleted`, props<{messageId: number}>());

export const loadPreviousMessages = createAction(
  `${actor} Load previous messages`,
  props<{query: PreviousMessageListQuery | MessageSearchQuery}>()
);

export const loadPreviousMessagesSuccess = createAction(
  `${actor} Load previous messages success`,
  props<{messages: BaseMessage[]}>()
);

export const selectMessage = createAction(`${actor} Select message`, props<{message: UserMessage}>());

export const deselectMessage = createAction(`${actor} Deselect message`, props<{message: UserMessage}>());

export const cancelSelection = createAction(`${actor} Cancel selection`);

export const deleteSelectedMessages = createAction(`${actor} Delete selected messages`);

export const reportMessage = createAction(
  `${actor} Report message`,
  props<{reportType: ReportCategory; comment: string}>()
);

export const likeMessage = createAction(`${actor} Like message`, props<{message: UserMessage}>());

export const unlikeMessage = createAction(`${actor} Unlike message`, props<{message: UserMessage}>());

export const likeRecived = createAction(`${actor} Like recived`, props<{reactionEvent: ReactionEvent}>());

export const loadExistingChat = createAction(
  `${actor} Load existing chat`,
  props<{profileId: string; chatUrl: string}>()
);

export const loadChatPreview = createAction(
  `${actor} Load data for chat preview`,
  props<{profileId: string; chatUrl: string}>()
);

export const saveTranslationSettings = createAction(
  `${actor} Save translations settings`,
  props<{settings: TranslationsSettings}>()
);

export const messagesMarkedAsRead = createAction(`${actor} Messages marked as read`, props<{chat: GroupChannel}>());

export const replyToMessage = createAction(`${actor} Reply to message`, props<{message: UserMessage}>());

export const replyToSelected = createAction(`${actor} Reply to selected message`);

export const cancelReply = createAction(`${actor} Cancel reply`);

export const selectGifSearch = createAction(`${actor} Select Gif search`);
export const cancelGifSearch = createAction(`${actor} Cancel Gif search`);

export const searchQueryChanged = createAction(`${actor} Search query changed`, props<{query: MessageSearchQuery}>());

export const loadSearchMessagesSuccess = createAction(
  `${actor} Rearch results loaded`,
  props<{messages: BaseMessage[]}>()
);

export const cancelSearch = createAction(`${actor} Cancel search`);

export const recordVoice = createAction(`${actor} record voice`);

export const cancelVoiceRecording = createAction(`${actor} stop voice recording `);
export const startingMessagseLoadedSuccesfully = createAction(
  `${actor} Starting messages loaded succesfully`,
  props<{messages: BaseMessage[]; prevMessagesQuery: PreviousMessageListQuery}>()
);

export const updateActiveProfile = createAction(`${actor} update active profile`);
export const updateActiveProfileSuccess = createAction(
  `${actor} update active profile success`,
  props<{profile: ProfileMainInfoV2}>()
);

export const metaDataUpdated = createAction(`${actor} Chat metadata updated`, props<{chat: GroupChannel}>());
