import {Pipe, PipeTransform} from '@angular/core';
import {map, Observable, of} from 'rxjs';

import {AuthService} from '../../../features/auth/services/auth.service';
import {Measurements} from '../../../features/main/pages/settings/models/settings.model';
import {SettingsService} from '../../../features/main/pages/settings/services/settings.service';

@Pipe({
  name: 'measurement'
})
export class MeasurementPipe implements PipeTransform {
  constructor(
    private authService: AuthService,
    private settingsService: SettingsService
  ) {}

  transform(value: string | number, type: string): Observable<string> {
    if (value) {
      if (type === 'distance') {
        return this.transformDistance(String(value));
      }
      if (type === 'height') {
        return this.transformHeight(String(value));
      }
    }
    return of(String(value));
  }

  private transformDistance(value: string): Observable<string> {
    let result = `${value} km`;
    if (this.authService.user) {
      return this.settingsService.getMyGeneralInfo().pipe(
        map((settings) => {
          const mileInKm = 0.62137119;
          if (settings.measurement === Measurements.IMPERIAL) {
            const miles = (+value * mileInKm).toFixed(1);
            result = `${miles} mi`;
          }
          return result;
        })
      );
    }
    return of(result);
  }

  private transformHeight(value: string): Observable<string> {
    const result = `${value} cm`;
    if (this.authService.user) {
      return this.settingsService.getMyGeneralInfo().pipe(
        map((settings) => {
          const feetInCm = 0.0328084;
          if (settings.measurement === Measurements.IMPERIAL) {
            const miles = (+value * feetInCm).toFixed(1);
            return `${miles} ft`;
          }
          return result;
        })
      );
    }
    return of(result);
  }
}
