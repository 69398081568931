export const Lion = `<svg width="141" height="140" viewBox="0 0 141 140" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M71.1256 0.226562L59.6484 14.3198L70.4834 45.8398L83.2884 14.338L71.1256 0.226562Z" fill="#E4B8E8"/>
<path d="M51.7695 53.7184L59.6495 14.3184L70.4845 45.8384L51.7695 53.7184Z" fill="#AB65B0"/>
<path d="M83.2894 14.3184L70.4844 45.8384L91.1694 53.7184L83.2894 14.3184Z" fill="#AB65B0"/>
<path d="M108.898 22.2216L99.048 8.43164L91.168 53.7186L108.898 22.2216Z" fill="#AB65B0"/>
<path d="M91.168 53.7212L128.598 24.1904V43.8904L91.168 53.7212Z" fill="#AB65B0"/>
<path d="M51.7708 53.7207L0.550781 61.3414L37.9808 69.4999H55.7108L51.7708 53.7207Z" fill="#AB65B0"/>
<path d="M12.6417 76.9453L0.550781 61.1641L37.9808 69.4981L12.6417 76.9453Z" fill="#97459D"/>
<path d="M1.38281 102.693L12.9014 76.9563L37.9817 69.5L1.38281 102.693Z" fill="#AB65B0"/>
<path d="M1.38281 102.693L37.9817 69.5V89.2L1.38281 102.693Z" fill="#97459D"/>
<path d="M14.5203 120.134L37.9817 89.1982L1.38281 102.711L14.5203 120.134Z" fill="#AB65B0"/>
<path d="M34.0371 128.598L37.9771 89.1982L14.668 120.006L34.0371 128.598Z" fill="#97459D"/>
<path d="M57.6791 77.3779L37.9791 89.1979L34.0391 128.598L47.8291 112.838L57.6791 77.3779Z" fill="#AB65B0"/>
<path d="M69.502 73.4404L57.682 77.3804L47.832 112.84L60.8639 102.957L69.502 73.4404Z" fill="#C895CC"/>
<path d="M84.3439 77.5773L69.5 73.4395L79.5129 102.309L93.14 112.839L84.3439 77.5773Z" fill="#C895CC"/>
<path d="M93.142 112.839L79.24 101.861H61.4003L47.832 112.839L53.8888 123.526H58.114V124.716L54.593 132.334L61.4003 139.238H79.24L86.282 132.334L82.2915 124.716V123.526H86.7514L93.142 112.839Z" fill="#AB65B0"/>
<path d="M79.4566 102.107L69.5022 73.4395L61.1836 102.107L70.3201 103.525L79.4566 102.107Z" fill="#E4B8E8"/>
<path d="M70.3201 115.981L79.4566 101.861L70.3201 103.322L61.1836 101.861L70.3201 115.981Z" fill="#86288D"/>
<path d="M54.7005 67.8086L37.9805 69.5016V89.2016L57.6805 77.3816L54.7005 67.8086Z" fill="#C895CC"/>
<path d="M57.6833 77.3813L45.8633 68.5163L55.7133 67.5312L57.6833 77.3813Z" fill="#86288D"/>
<path d="M70.4845 45.8379L51.7695 53.7179L57.6795 77.381L69.4995 73.441L83.2895 77.381L91.1695 53.7179L70.4845 45.8379Z" fill="#E4B8E8"/>
<path d="M140.425 61.6209L91.175 53.7217L86.25 67.5309L95.115 69.5009H102.995L140.425 61.6209Z" fill="#AB65B0"/>
<path d="M83.2891 77.3813L86.2441 67.5312L95.1091 69.5013L83.2891 77.3813Z" fill="#86288D"/>
<path d="M102.989 69.498H95.1091L83.2891 77.378L103.547 89.402L102.989 69.498Z" fill="#C895CC"/>
<path d="M140.422 102.988L102.992 69.498L128.602 77.378L140.422 102.988Z" fill="#AB65B0"/>
<path d="M140.422 61.6182L128.602 77.3782L102.992 69.4982L140.422 61.6182Z" fill="#97459D"/>
<path d="M102.848 89.547L102.986 69.499L140.416 102.989L102.848 89.547Z" fill="#97459D"/>
<path d="M126.629 120.718L140.419 102.988L103.543 89.4023L126.629 120.718Z" fill="#AB65B0"/>
<path d="M106.929 128.598L103.543 89.4023L126.629 120.718L106.929 128.598Z" fill="#97459D"/>
<path d="M83.2891 77.3779L103.529 88.9751L106.929 128.598L93.1391 112.838L83.2891 77.3779Z" fill="#AB65B0"/>
<path d="M70.6162 119.305L63.6758 123.491L70.6162 125.119L77.7959 123.723L70.6162 119.305Z" fill="#97459D"/>
<path d="M99.0452 8.43164L83.2852 14.3416L91.1679 53.7186L99.0452 8.43164Z" fill="#E4B8E8"/>
<path d="M108.898 22.2197L91.168 53.7205L128.598 24.1897L108.898 22.2197Z" fill="#E4B8E8"/>
<path d="M128.598 43.8906L91.168 53.7214L140.418 61.6206L128.598 43.8906Z" fill="#E4B8E8"/>
<path d="M41.9258 8.4082L59.6558 14.3182L51.7758 53.7182L41.9258 8.4082Z" fill="#E4B8E8"/>
<path d="M32.0711 22.2002L51.7711 53.7202L12.3711 24.1702L32.0711 22.2002Z" fill="#E4B8E8"/>
<path d="M0.550781 61.1635L51.7708 53.7211L12.3708 43.8711L0.550781 61.1635Z" fill="#E4B8E8"/>
<path d="M12.3711 43.8709V24.1709L51.7711 53.7209L12.3711 43.8709Z" fill="#AB65B0"/>
<path d="M41.9203 8.4082L32.0703 22.1982L51.7703 53.7182L41.9203 8.4082Z" fill="#AB65B0"/>
</svg>
`;

export const Wolf = `<svg width="197" height="197" viewBox="0 0 197 197" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="197" height="197" rx="10" fill="#75147D"/>
<path d="M145.675 31.5195L118.156 51.3945L130.897 55.4714L145.675 31.5195Z" fill="#AB65B0"/>
<path d="M49.3558 31.5195L76.875 51.3945L64.1347 55.4714L49.3558 31.5195Z" fill="#AB65B0"/>
<path d="M145.677 31.5195L130.898 55.4714L154.341 49.8656L145.677 31.5195Z" fill="#E4B8E8"/>
<path d="M49.354 31.5195L64.1328 55.4714L40.6906 49.8656L49.354 31.5195Z" fill="#E4B8E8"/>
<path d="M154.341 49.8652L130.898 55.471L147.206 87.5767L154.341 49.8652Z" fill="#97459D"/>
<path d="M40.6906 49.8652L64.1328 55.471L47.8252 87.5767L40.6906 49.8652Z" fill="#97459D"/>
<path d="M163.513 128.856L147.205 87.5771L140.07 121.212L153.83 136.5L163.513 128.856Z" fill="#C895CC"/>
<path d="M31.5187 128.856L47.8263 87.5771L54.9609 121.212L41.2014 136.5L31.5187 128.856Z" fill="#C895CC"/>
<path d="M120.704 90.1252L147.204 87.5771L140.069 121.212L120.704 109.491L110.512 111.529L120.704 90.1252Z" fill="#E4B8E8"/>
<path d="M74.3273 90.1252L47.8273 87.5771L54.9619 121.212L74.3273 109.491L84.5195 111.529L74.3273 90.1252Z" fill="#E4B8E8"/>
<path d="M114.079 103.884L110.512 111.529L120.704 109.49L127.329 99.2979L114.079 103.884Z" fill="#86288D"/>
<path d="M80.9522 103.884L84.5195 111.529L74.3273 109.49L67.7023 99.2979L80.9522 103.884Z" fill="#86288D"/>
<path d="M118.156 51.3936L120.704 90.1242L147.204 87.5761L118.156 51.3936Z" fill="#AB65B0"/>
<path d="M76.875 51.3936L74.3269 90.1242L47.827 87.5761L76.875 51.3936Z" fill="#AB65B0"/>
<path d="M130.897 55.4705L118.156 51.3936L147.204 87.5761L130.897 55.4705Z" fill="#E4B8E8"/>
<path d="M64.1347 55.4705L76.875 51.3936L47.827 87.5761L64.1347 55.4705Z" fill="#E4B8E8"/>
<path d="M97.5156 85.5377L118.155 51.3936L120.703 90.1242L110.511 111.528L97.5156 85.5377Z" fill="#C895CC"/>
<path d="M97.5156 85.5377L76.8763 51.3936L74.3282 90.1242L84.5205 111.528L97.5156 85.5377Z" fill="#C895CC"/>
<path d="M110.512 111.529L120.704 109.49L140.069 121.211L119.175 128.346L110.512 111.529Z" fill="#C895CC"/>
<path d="M84.5195 111.529L74.3273 109.49L54.9619 121.211L75.8561 128.346L84.5195 111.529Z" fill="#C895CC"/>
<path d="M105.418 139.557L110.514 111.528L119.178 128.346L117.139 148.73L105.418 139.557Z" fill="#AB65B0"/>
<path d="M89.6133 139.557L84.5171 111.528L75.8537 128.346L77.8922 148.73L89.6133 139.557Z" fill="#AB65B0"/>
<path d="M140.069 121.211L119.175 128.346L117.137 148.73L125.8 133.951L140.069 121.211Z" fill="#97459D"/>
<path d="M54.9619 121.211L75.8561 128.346L77.8945 148.73L69.2311 133.951L54.9619 121.211Z" fill="#97459D"/>
<path d="M153.83 136.499L125.801 133.951L140.07 121.211L153.83 136.499Z" fill="#AB65B0"/>
<path d="M41.2017 136.499L69.2305 133.951L54.9613 121.211L41.2017 136.499Z" fill="#AB65B0"/>
<path d="M157.397 156.376L125.801 133.953L153.83 136.501L157.397 156.376Z" fill="#C895CC"/>
<path d="M37.6344 156.376L69.2305 133.953L41.2017 136.501L37.6344 156.376Z" fill="#C895CC"/>
<path d="M157.397 156.376L125.801 133.953L133.445 168.097L157.397 156.376Z" fill="#97459D"/>
<path d="M37.6344 156.376L69.2305 133.953L61.5863 168.097L37.6344 156.376Z" fill="#97459D"/>
<path d="M133.444 168.097L125.8 133.953L117.137 148.732L110.512 160.453L133.444 168.097Z" fill="#C895CC"/>
<path d="M61.5869 168.097L69.2311 133.953L77.8946 148.732L84.5195 160.453L61.5869 168.097Z" fill="#C895CC"/>
<path d="M97.5144 85.5377L118.154 51.3936L97.2596 62.6051L76.875 51.3936L97.5144 85.5377Z" fill="#E4B8E8"/>
<path d="M110.51 111.529L97.5147 85.5391L84.5195 111.529L89.6157 139.558H105.414L110.51 111.529Z" fill="#E4B8E8"/>
<path d="M105.411 139.559H89.6127L87.5742 146.184H107.449L105.411 139.559Z" fill="#97459D"/>
<path d="M103.882 149.241L107.449 146.184H87.5742L91.1415 149.241V152.809L96.7473 154.847H98.2761L103.882 152.809V149.241Z" fill="#86288D"/>
<path d="M117.135 148.732L105.414 139.559L107.452 146.184L103.885 149.241V152.809L98.2791 154.847H96.7502L91.1445 152.809V149.241L87.5772 146.184L89.6157 139.559L77.8945 148.732L84.5195 160.453H110.51L117.135 148.732Z" fill="#E4B8E8"/>
<path d="M97.2596 62.6055L118.154 51.394L97.2596 49.6104L76.875 51.394L97.2596 62.6055Z" fill="#E4B8E8"/>
<path d="M84.5195 160.452H110.51L97.2599 168.096L84.5195 160.452Z" fill="#AB65B0"/>
</svg>
`;

export const Dog = `<svg width="79" height="145" viewBox="0 0 79 145" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M66.8862 16.1632L64.6601 0.580078L61.0982 16.1632L53.084 39.7605L53.5293 46.439L39.5039 44.9051L46.4056 66.4744L59.3173 53.5627L65.9958 62.0221L61.0982 43.3224L66.8862 16.1632Z" fill="#C895CC"/>
<path d="M12.1254 16.1632L14.3515 0.580078L17.9134 16.1632L25.9276 39.7605L25.4823 46.439L39.5 44.9051L32.606 66.4744L19.6943 53.5627L13.0158 62.0221L17.9134 43.3224L12.1254 16.1632Z" fill="#C895CC"/>
<g filter="url(#filter0_d_7283_304987)">
<path d="M59.318 53.5625L73.5654 71.817L65.9965 72.7075L60.2084 66.4742L52.1943 71.3718L46.4062 66.4742L59.318 53.5625Z" fill="#AB65B0"/>
</g>
<g filter="url(#filter1_d_7283_304987)">
<path d="M19.6937 53.5625L5.4463 71.817L13.0153 72.7075L18.8033 66.4742L26.8175 71.3718L32.6055 66.4742L19.6937 53.5625Z" fill="#AB65B0"/>
</g>
<path d="M70.0062 18.3894L64.6634 0.580078L66.8896 16.1632L61.1016 43.3224L65.9991 62.0221L78.9109 55.7889L68.6705 42.8771L70.0062 18.3894Z" fill="#97459D"/>
<path d="M9.00551 18.3894L14.3483 0.580078L12.1221 16.1632L17.9102 43.3224L13.0126 62.0221L0.100868 55.7889L10.3412 42.8771L9.00551 18.3894Z" fill="#97459D"/>
<path d="M73.5689 71.8164L78.9117 55.7881L66 62.0213L73.5689 71.8164Z" fill="#C895CC"/>
<path d="M5.44277 71.8164L0.0999861 55.7881L13.0117 62.0213L5.44277 71.8164Z" fill="#C895CC"/>
<path d="M67.3318 98.5313L73.5651 71.8174L65.9961 72.7079L60.2081 66.4746L52.1939 71.3722V78.9411C52.1939 79.1192 47.6361 86.5786 45.4099 90.2146L44.625 101.648L53.2899 112.87L61.1699 106.96L67.3318 98.5313Z" fill="#C895CC"/>
<path d="M11.6799 98.5313L5.44662 71.8174L13.0156 72.7079L18.8036 66.4746L26.8178 71.3722C26.8178 73.8209 26.6991 79.2016 26.6991 79.3796C26.6991 79.5577 31.3679 86.5786 33.5941 90.2146L34.3867 101.648L25.7141 112.87L16.8491 106.96L11.6799 98.5313Z" fill="#C895CC"/>
<path d="M52.1964 71.3722L46.4084 66.4746L45.4102 90.2146L52.3052 79.3796L52.1964 71.3722Z" fill="#AB65B0"/>
<path d="M26.819 71.3722L32.607 66.4746L33.5898 90.2146L26.6948 79.3796L26.819 71.3722Z" fill="#AB65B0"/>
<path d="M53.2891 112.871L66.0007 72.709L61.1691 106.961L53.2891 112.871Z" fill="#E4B8E8"/>
<path d="M25.7109 112.871L13.0147 72.709L16.8459 106.961L25.7109 112.871Z" fill="#E4B8E8"/>
<path d="M60.2073 77.6051L52.3086 79.3782L54.8646 72.7075L57.9812 70.0361L62.4335 72.7075L60.2073 77.6051Z" fill="#86288D"/>
<path d="M18.8043 77.6051L26.6953 79.3782L24.147 72.7075L21.0304 70.0361L16.5781 72.7075L18.8043 77.6051Z" fill="#86288D"/>
<path d="M63.1391 126.661L61.1691 106.961L53.2891 112.871L63.1391 126.661Z" fill="#AB65B0"/>
<path d="M39.2828 144.39L33.5938 140.452C34.1132 139.561 35.4984 137.222 35.4984 137.044C35.4984 136.821 38.8376 135.931 39.2828 135.931C39.639 135.931 41.6574 136.673 42.6221 137.044L45.4137 140.452L39.2828 144.39Z" fill="#AB65B0"/>
<path d="M15.8609 126.661L16.8459 106.961L25.7109 112.871L15.8609 126.661Z" fill="#AB65B0"/>
<path d="M46.4073 66.4754L39.5033 44.9053L32.6051 66.4754L32.6083 85.2903L34.386 101.649L25.7133 112.87L15.8633 126.66L25.4814 141.274H27.7076L32.2576 140.45H33.5933L37.9479 139.048L38.8384 137.267H40.1741L40.6193 139.048L45.4133 140.45H46.749L50.8596 141.274H53.0858L63.1433 126.66L53.2933 112.87L44.6264 101.649L46.3983 85.2903L46.4073 66.4754Z" fill="#E4B8E8"/>
<path d="M45.5202 135.038H33.0537L27.4883 125.243L32.1632 115.448H47.3011L51.3082 125.243L45.5202 135.038Z" fill="#86288D"/>
<defs>
<filter id="filter0_d_7283_304987" x="42.4062" y="53.5625" width="35.1602" height="27.1445" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dy="4"/>
<feGaussianBlur stdDeviation="2"/>
<feComposite in2="hardAlpha" operator="out"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_7283_304987"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_7283_304987" result="shape"/>
</filter>
<filter id="filter1_d_7283_304987" x="1.44531" y="53.5625" width="35.1602" height="27.1445" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dy="4"/>
<feGaussianBlur stdDeviation="2"/>
<feComposite in2="hardAlpha" operator="out"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_7283_304987"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_7283_304987" result="shape"/>
</filter>
</defs>
</svg>
`;

export const Fox = `<svg width="119" height="132" viewBox="0 0 119 132" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M31.399 29.5998L18.3182 0.046875L9.59766 69.3266L31.399 29.5998Z" fill="#97459D"/>
<path d="M87.5995 29.6006H59.5V89.6753L73.0653 84.3461L87.5995 29.6006Z" fill="#E4B8E8"/>
<path d="M31.4005 29.6006H59.5V89.6753L45.9347 84.3461L31.4005 29.6006Z" fill="#C895CC"/>
<path d="M31.4011 29.5998L18.3203 0.046875L59.5006 29.5998H31.4011Z" fill="#AB65B0"/>
<path d="M87.6049 29.5998L100.686 0.046875L109.406 69.3266L87.6049 29.5998Z" fill="#97459D"/>
<path d="M87.5911 29.5998L100.672 0.046875L59.4916 29.5998H87.5911Z" fill="#AB65B0"/>
<path d="M9.59766 69.3274L31.399 29.6006L45.9332 84.3461L9.59766 69.3274Z" fill="#AB65B0"/>
<path d="M109.406 69.3274L87.6049 29.6006L73.0707 84.3461L109.406 69.3274Z" fill="#AB65B0"/>
<path d="M80.3257 81.4404L73.0586 84.3472L74.512 78.5335L87.1083 72.2354L80.3257 81.4404Z" fill="#86288D"/>
<path d="M38.6704 81.4404L45.9375 84.3472L44.4841 78.5335L31.8878 72.2354L38.6704 81.4404Z" fill="#86288D"/>
<path d="M118.601 85.3128L109.396 69.3252L81.2969 107.114L118.601 85.3128Z" fill="#C895CC"/>
<path d="M0.394745 85.3128L9.59974 69.3252L37.6992 107.114L0.394745 85.3128Z" fill="#C895CC"/>
<path d="M65.3164 128.431L109.404 69.3252L73.068 84.3439L65.3164 128.431Z" fill="#97459D"/>
<path d="M53.6797 128.431L9.59258 69.3252L45.9281 84.3439L53.6797 128.431Z" fill="#97459D"/>
<path d="M59.5028 89.6739L45.9375 84.3447L53.6891 128.432L59.5028 121.165L65.3164 128.432L73.068 84.3447L59.5028 89.6739Z" fill="#AB65B0"/>
<path d="M53.6797 128.434L59.4934 121.167L65.3071 128.434L59.4934 131.341L53.6797 128.434Z" fill="#C895CC"/>
</svg>
`;

export const Cat = `<svg width="115" height="123" viewBox="0 0 115 123" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M37.9158 25.3181L23.2233 9.89658L2.82031 0.429688L19.4178 18.2848L27.3065 34.785L37.9158 25.3181Z" fill="#97459D"/>
<path d="M2.81898 0.429688L0.914062 31.8121L6.62883 52.912L27.3052 34.785L19.4165 18.2848L2.81898 0.429688Z" fill="#E4B8E8"/>
<path d="M57.5012 22.8848V95.9276C54.5998 95.9276 51.6983 95.9276 48.7968 95.9276C45.1678 72.3941 41.5431 48.8562 37.9141 25.3227L57.5012 22.8848Z" fill="#E4B8E8"/>
<path d="M6.62823 52.9121L0.371094 84.8338L24.5839 65.0845L6.62823 52.9121Z" fill="#C895CC"/>
<path d="M37.9146 25.3184L6.62891 52.9122L24.5846 65.0846L36.8254 66.4395L45.5299 74.4199L37.9146 25.3184Z" fill="#AB65B0"/>
<path d="M0.371094 84.8334L31.1144 96.1945L45.5292 74.4193L36.8248 66.4389L24.5839 65.084L0.371094 84.8334Z" fill="#D3A6D7"/>
<path d="M31.1144 96.1951L34.3775 106.745L44.0344 111.345L46.8917 117.567L20.2317 110.126L0.371094 84.834L31.1144 96.1951Z" fill="#97459D"/>
<path d="M57.5 95.9232V107.556L44.0332 111.345L34.3763 106.745L31.1133 96.1951L45.5281 74.4199L49.6113 95.9232H57.5Z" fill="#E4B8E8"/>
<path d="M42.5625 71.7139C41.6938 73.0644 40.8251 74.4193 39.9564 75.7699C37.6723 75.8137 35.3926 75.862 33.1084 75.9058C31.6268 74.1913 30.1452 72.4812 28.6636 70.7668C27.2746 68.8725 25.8812 66.9782 24.4922 65.084C28.6327 65.5356 32.7733 65.9873 36.9139 66.4345C38.7967 68.1972 40.6796 69.9556 42.5625 71.7139Z" fill="#86288D"/>
<path d="M77.0898 25.3181L91.7824 9.89658L112.185 0.429688L95.5879 18.2848L87.6992 34.785L77.0898 25.3181Z" fill="#97459D"/>
<path d="M112.185 0.429688L114.09 31.8121L108.376 52.912L87.6992 34.785L95.5879 18.2848L112.185 0.429688Z" fill="#E4B8E8"/>
<path d="M57.5 22.8848V95.9276C60.4015 95.9276 63.303 95.9276 66.2044 95.9276C69.8335 72.3941 73.4581 48.8562 77.0872 25.3227L57.5 22.8848Z" fill="#E4B8E8"/>
<path d="M108.374 52.9121L114.631 84.8338L90.418 65.0845L108.374 52.9121Z" fill="#C895CC"/>
<path d="M77.0879 25.3184L108.374 52.9122L90.418 65.0846L78.1771 66.4395L69.4727 74.4199L77.0879 25.3184Z" fill="#AB65B0"/>
<path d="M114.631 84.8334L83.8874 96.1945L69.4727 74.4193L78.1771 66.4389L90.418 65.084L114.631 84.8334Z" fill="#D3A6D7"/>
<path d="M83.8867 96.1951L80.6237 106.745L70.9668 111.345L68.1094 117.567L94.7695 110.126L114.63 84.834L83.8867 96.1951Z" fill="#97459D"/>
<path d="M57.5 95.9232V107.556L70.9667 111.345L80.6236 106.745L83.8867 96.1951L69.4719 74.4199L65.3887 95.9232H57.5Z" fill="#E4B8E8"/>
<path d="M57.5019 107.556L44.0352 111.344L47.845 121.758L57.5019 122.569L67.1588 121.758L70.9686 111.344L57.5019 107.556Z" fill="#AB65B0"/>
<path d="M63.3495 93.3535H57.5333H57.498H51.6598L49.6094 95.923L51.0645 96.914C51.4437 96.6071 51.823 96.2957 52.2022 95.9888C52.7269 96.2387 53.2472 96.4843 53.772 96.7342C53.7808 97.9971 53.7852 99.2599 53.794 100.523L57.4936 102.312V102.329L57.5113 102.321L57.5289 102.329V102.312L61.2109 100.523C61.2197 99.2599 61.2241 97.9971 61.2329 96.7342C61.7533 96.4843 62.2736 96.2387 62.7983 95.9888C63.1775 96.2957 63.5523 96.6071 63.9316 96.914L65.3823 95.923L63.3495 93.3535Z" fill="#86288D"/>
<path d="M72.375 71.7139C73.2437 73.0644 74.1124 74.4193 74.981 75.7699C77.2652 75.8137 79.5449 75.862 81.8291 75.9058C83.3107 74.1913 84.7923 72.4812 86.2739 70.7668C87.6629 68.8725 89.0563 66.9782 90.4453 65.084C86.3047 65.5356 82.1642 65.9873 78.0236 66.4345C76.1363 68.1972 74.2535 69.9556 72.375 71.7139Z" fill="#86288D"/>
</svg>
`;

export const Panther = `<svg width="123" height="131" viewBox="0 0 123 131" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M61.5026 25.7808L38.6784 7.8916L36.2109 25.7808L52.2495 51.6893L61.5026 39.9688L70.7556 51.6893L86.7942 25.7808L83.7098 7.8916L61.5026 25.7808Z" fill="#C895CC"/>
<path d="M60.2701 6.04102L38.6797 7.89162L61.5038 25.7808L83.7111 7.89162L60.2701 6.04102Z" fill="#E4B8E8"/>
<path d="M104.068 0.490234L83.7109 7.89266L86.7953 25.7819L100.983 11.5939L122.574 10.3601L118.256 4.19145L104.068 0.490234Z" fill="#E4B8E8"/>
<path d="M18.9363 0.490234L39.293 7.89266L36.2086 25.7819L22.0206 11.5939L0.430244 10.3601L4.74833 4.19145L18.9363 0.490234Z" fill="#E4B8E8"/>
<path d="M115.173 31.9498L122.575 10.3594L100.985 11.5931L86.7969 25.7811L98.5174 36.2679L115.173 31.9498Z" fill="#97459D"/>
<path d="M7.83498 31.9498L0.432556 10.3594L22.023 11.5931L36.2109 25.7811L24.4904 36.2679L7.83498 31.9498Z" fill="#97459D"/>
<path d="M116.405 40.5883L115.171 31.9521L98.5156 36.2702L107.152 47.3739L116.405 40.5883Z" fill="#AB65B0"/>
<path d="M6.59909 40.5883L7.83283 31.9521L24.4883 36.2702L15.8521 47.3739L6.59909 40.5883Z" fill="#AB65B0"/>
<path d="M109 68.9625L107.15 47.3721L96.6628 48.6058L94.1953 51.6902L109 68.9625Z" fill="#AB65B0"/>
<path d="M14.0037 68.9625L15.8544 47.3721L26.3411 48.6058L28.8086 51.6902L14.0037 68.9625Z" fill="#AB65B0"/>
<path d="M96.6665 48.6073L107.153 47.3736L98.5171 36.27L86.7966 25.7832L78.7773 52.3086L88.0304 45.523L96.6665 48.6073Z" fill="#C895CC"/>
<path d="M26.3413 48.6073L15.8545 47.3736L24.4907 36.27L36.2112 25.7832L44.2305 52.3086L34.9774 45.523L26.3413 48.6073Z" fill="#C895CC"/>
<path d="M70.7578 51.6917L86.7964 25.7832L78.7771 52.3086L75.6928 60.9447L70.7578 51.6917Z" fill="#AB65B0"/>
<path d="M52.2461 51.6917L36.2075 25.7832L44.2268 52.3086L47.3111 60.9447L52.2461 51.6917Z" fill="#AB65B0"/>
<path d="M75.6953 60.9442L94.2014 51.6911L96.6688 48.6068L88.0327 45.5225L78.7797 52.308L75.6953 60.9442Z" fill="#EACEDA"/>
<path d="M47.3125 60.9442L28.8064 51.6911L26.339 48.6068L34.9751 45.5225L44.2282 52.308L47.3125 60.9442Z" fill="#EACEDA"/>
<path d="M80.0134 53.5407L75.6953 60.9432H88.0327L94.2014 51.6901L86.799 49.2227L80.0134 53.5407Z" fill="#86288D"/>
<path d="M70.7571 51.6912L61.5041 39.9707L52.251 51.6912L46.6992 95.4889L48.5498 97.3395L52.251 95.4889H53.4848L61.5041 98.5732L69.5234 95.4889H70.7571L74.4583 97.3395L75.692 95.4889L70.7571 51.6912Z" fill="#E4B8E8"/>
<path d="M75.6928 60.9435L70.7578 51.6904L75.6928 95.4881L81.8614 85.0013L75.6928 60.9435Z" fill="#97459D"/>
<path d="M47.3111 60.9435L52.2461 51.6904L47.3111 95.4881L41.1425 85.0013L47.3111 60.9435Z" fill="#97459D"/>
<path d="M42.9944 53.5407L47.3125 60.9432H34.9751L28.8064 51.6901L36.2089 49.2227L42.9944 53.5407Z" fill="#86288D"/>
<path d="M99.7439 85.6182L88.0234 60.9435L94.1921 51.6904L108.997 68.9628L99.7439 85.6182Z" fill="#C895CC"/>
<path d="M23.26 85.6182L34.9805 60.9435L28.8118 51.6904L14.0069 68.9628L23.26 85.6182Z" fill="#C895CC"/>
<path d="M99.7532 85.6171L88.0327 60.9424H75.6953L81.864 85.0003L99.7532 85.6171Z" fill="#AB65B0"/>
<path d="M23.2546 85.6171L34.9751 60.9424H47.3125L41.1438 85.0003L23.2546 85.6171Z" fill="#AB65B0"/>
<path d="M81.8594 85L99.7486 85.6169L88.0281 98.5711L81.8594 85Z" fill="#C895CC"/>
<path d="M41.1445 85L23.2553 85.6169L34.9758 98.5711L41.1445 85Z" fill="#C895CC"/>
<path d="M53.4818 95.4873H52.2481L48.5469 97.3379L50.3975 100.422L58.4168 104.123H59.0336L61.5011 109.058L63.3517 104.123H63.9686L71.9879 100.422L74.4554 97.3379L70.7541 95.4873H69.5204L61.5011 98.5716L53.4818 95.4873Z" fill="#86288D"/>
<path d="M88.0293 98.5711L81.8606 85L71.9907 100.422L63.9714 104.123H63.3545L61.5039 109.058L68.2895 117.077L88.0293 110.908V98.5711Z" fill="#AB65B0"/>
<path d="M34.9786 98.5711L41.1472 85L51.0171 100.422L59.0364 104.123H59.6533L61.5039 109.058L54.7184 117.077L34.9786 110.908V98.5711Z" fill="#AB65B0"/>
<path d="M47.3181 126.333L43 113.379H80.0121L75.694 126.333L61.5061 130.034L47.3181 126.333Z" fill="#C895CC"/>
<path d="M68.2899 117.08L61.5043 109.061L54.7188 117.08L61.5043 121.089L68.2899 117.08Z" fill="#97459D"/>
</svg>
`;
