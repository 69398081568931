import {OverlayModule} from '@angular/cdk/overlay';
import {PortalModule} from '@angular/cdk/portal';
import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {DtButtonModule} from '@ui/components/dt-button/dt-button.module';
import {DtDialogModule} from '@ui/components/dt-dialogs/dt-dialog.module';
import {DtFieldsModule} from '@ui/components/dt-fields/dt-fields.module';
import {DtLocationSelectModule} from '@ui/components/dt-location-select/dt-location-select.module';
import {DtMediaContentModule} from '@ui/components/dt-media-content/dt-media-content.module';
import {DtTranslateModule} from '@ui/translation/dt-translate.module';
import {NzIconModule} from 'ng-zorro-antd/icon';
import {NzSelectModule} from 'ng-zorro-antd/select';
import {NzSliderModule} from 'ng-zorro-antd/slider';

import {MeasurementModule} from '../../pipes/measurement/measurement.module';
import {EmptyContentModule} from '../empty-content/empty-content.module';
import {FlushCheckboxFieldModule} from '../fields/flush-checkbox-field/flush-checkbox-field.module';
import {FlushDatepickerRangeFieldModule} from '../fields/flush-datepicker-range-field/flush-datepicker-range-field.module';
import {FlushInputFieldModule} from '../fields/flush-input-field/flush-input-field.module';
import {FlushRadioFieldModule} from '../fields/flush-radio-field/flush-radio-field.module';
import {FlushTextareaFieldModule} from '../fields/flush-textarea-field/flush-textarea-field.module';
import {LegacyIconModule} from '../legacy-icon/legacy-icon.module';
import {ProfileAliasModule} from '../profile-section/profile-alias.module';
import {SectionDescriptionModule} from '../section-description/section-description.module';
import {ConfirmDialogModule} from './components/confirm-dialog/confirm-dialog.module';
import {FilterDialogComponent} from './components/filter-dialog/filter-dialog.component';
import {ShareDialogComponent} from './components/share-dialog/share-dialog.component';

@NgModule({
  declarations: [ShareDialogComponent, FilterDialogComponent],
  imports: [
    OverlayModule,
    PortalModule,
    LegacyIconModule,
    DtTranslateModule,
    DtButtonModule,
    NzIconModule,
    ProfileAliasModule,
    FlushInputFieldModule,
    CommonModule,
    FlushTextareaFieldModule,
    NzSliderModule,
    FormsModule,
    FlushCheckboxFieldModule,
    FlushRadioFieldModule,
    SectionDescriptionModule,
    FlushDatepickerRangeFieldModule,
    NzSelectModule,
    EmptyContentModule,
    DtDialogModule,
    ReactiveFormsModule,
    MeasurementModule,
    DtLocationSelectModule,
    ConfirmDialogModule,
    DtFieldsModule,
    DtMediaContentModule
  ]
})
export class DialogsModule {}
