import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {DtProfileShort} from '@ui/models/dt-profile.model';

@Component({
  selector: 'app-profile-alias',
  templateUrl: './profile-alias.component.html',
  styleUrls: ['./profile-alias.component.scss']
})
export class ProfileAliasComponent implements OnInit {
  @Input() profile: DtProfileShort;
  @Input() description: string;
  @Input() onlyTitle = false;
  @Input() hideAvatar = false;

  @Output() redirectToProfile = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}
}
