import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Store} from '@ngrx/store';
import {DtProfileType} from '@ui/models/dt-profile.model';
import {BehaviorSubject, finalize, Observable, shareReplay, tap} from 'rxjs';

import {environment} from '../../../../environments/environment';
import {User, UserAvailability} from '../../../core/models/user.model';
import {userActions} from '../../../core/store/actions';
import {SignInResponse} from '../models/auth.model';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private _user: BehaviorSubject<User> = new BehaviorSubject<User>(null);

  private refreshRequest: Observable<void>;

  get user(): User {
    return this._user.getValue();
  }

  set user(user: User) {
    this._user.next(user);
  }

  get userChanges(): BehaviorSubject<User> {
    return this._user;
  }

  get userIsDaddy(): boolean {
    return this.user?.profileType === DtProfileType.DADDY;
  }

  constructor(
    private http: HttpClient,
    private store: Store
  ) {}

  setUser(user: User) {
    this._user.next(user);
    this.store.dispatch(userActions.setUser({user}));
  }

  authCheck(): Observable<User> {
    return this.http.get<User>(`${environment.API_URL}/authorization/auth`, {
      params: {
        ignoreErrorWarning: true,
        ignoreSessionRedirect: true
      }
    });
  }

  refreshToken(): Observable<void> {
    if (!this.refreshRequest) {
      this.refreshRequest = this.http.put<void>(`${environment.API_URL}/authorization/auth/token/refresh`, {}).pipe(
        shareReplay(1),
        finalize(() => {
          this.refreshRequest = null;
        })
      );
    }
    return this.refreshRequest;
  }

  signIn(form: {email: string; password: string}): Observable<SignInResponse> {
    return this.http.post<SignInResponse>(`${environment.API_URL}/authorization/auth/sign-in`, form);
  }

  signInProvider(token: string, provider: string): Observable<void> {
    return this.http.post<void>(
      `${environment.API_URL}/authorization/auth/sign-in/${provider}`,
      {},
      {
        params: {token, ignoreErrorWarning: true, ignoreSessionRedirect: true}
      }
    );
  }

  signInOtp(email: string, otp: string, type: 'email' | 'phone'): Observable<void> {
    return this.http.post<void>(
      `${environment.API_URL}/authorization/auth/two-factor`,
      {
        email,
        otp,
        type
      },
      {
        params: {
          ignoreSessionRedirect: true
        }
      }
    );
  }

  signUp(form: object, params: object): Observable<User> {
    return this.http.post<User>(`${environment.API_URL}/authorization/auth/sign-up`, {...form}, {params: {...params}});
  }

  forgotPassword(email: string): Observable<void> {
    return this.http.post<void>(
      `${environment.API_URL}/authorization/auth/forgot-password`,
      {email: email},
      {
        params: {
          ignoreErrorWarning: true
        }
      }
    );
  }

  setPassword(newPassword: string, linkKey: string): Observable<void> {
    return this.http.post<void>(`${environment.API_URL}/authorization/auth/forgot-password/change`, {
      // email: this.authService.user.email,
      password: newPassword,
      linkKey: linkKey
    });
  }

  checkUserName(username: string): Observable<UserAvailability> {
    return this.http.get<UserAvailability>(`${environment.API_URL}/profiles/profile/username/available`, {
      params: {username}
    });
  }

  checkUserEmail(email: string): Observable<string> {
    return this.http.get<string>(`${environment.API_URL}/profiles/profile/email/available`, {params: {email}});
  }

  registerUserEmail(email: string): Observable<void> {
    return this.http.post<void>(`${environment.API_URL}/authorization/auth/email-code/register`, {
      email
    });
  }

  validateUserEmail(email: string, code: string): Observable<void> {
    return this.http.post<void>(
      `${environment.API_URL}/authorization/auth/email-code/register/validate`,
      {
        email,
        code
      },
      {
        params: {
          ignoreSessionRedirect: true
        }
      }
    );
  }

  signOut(): Observable<void> {
    return this.http.delete<void>(`${environment.API_URL}/authorization/auth/sign-out`, {}).pipe(
      tap(() => {
        this.user = null;
      })
    );
  }
}
