import {
  ACTIVE_CHAT_FEATURE_KEY,
  activeChatInitialState,
  ActiveChatState
} from '../../active-chat/store/state/active-chat.state';
import {CHAT_LIST_FEATURE_KEY, ChatListState, initialChatListState} from '../../chat-list/store/state/chat-list.state';
import {USER_DATA_FEATURE_KEY, userDataInitailState, UserDataState} from './user-data.state';

export const CHAT_PAGE_FEATURE_KEY = 'chatPage';

export interface ChatPageState {
  [CHAT_LIST_FEATURE_KEY]: ChatListState;
  [ACTIVE_CHAT_FEATURE_KEY]: ActiveChatState;
  [USER_DATA_FEATURE_KEY]: UserDataState;
}

export const initialChatPageState: ChatPageState = {
  [CHAT_LIST_FEATURE_KEY]: initialChatListState,
  [ACTIVE_CHAT_FEATURE_KEY]: activeChatInitialState,
  [USER_DATA_FEATURE_KEY]: userDataInitailState
};
