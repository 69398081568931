import {Injectable} from '@angular/core';
import {GroupChannel} from '@sendbird/chat/groupChannel';
import {BehaviorSubject, filter, Observable, shareReplay} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ActiveChatService {
  private activeSbChat$ = new BehaviorSubject<GroupChannel>(null);

  constructor() {}

  get sbChatValue(): GroupChannel {
    return this.activeSbChat$.getValue();
  }

  getSbChat(): Observable<GroupChannel> {
    return this.activeSbChat$.asObservable().pipe(filter(Boolean), shareReplay());
  }

  getSbChatValue(): GroupChannel {
    return this.activeSbChat$.getValue();
  }

  updateSbChat(value: GroupChannel): void {
    // if (value?.url === this.sbChatValue?.url) {
    this.activeSbChat$.next(value);
    // }
  }
}
