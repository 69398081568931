import {createReducer, on} from '@ngrx/store';

import {Profile} from '../../models/profile.model';
import {profileActions} from '../actions';
import {initialProfileState, ProfileState} from '../state/profile.state';

export const profileReducer = createReducer(
  initialProfileState,
  on(profileActions.loadProfile, (state: ProfileState) => {
    return {
      ...state,
      profile: null as Profile
    };
  }),
  on(profileActions.loadProfileSuccess, (state: ProfileState, {profile}) => {
    return {
      ...state,
      profile: profile
    };
  }),
  on(profileActions.unsetProfile, (state: ProfileState) => {
    return {
      ...state,
      profile: null as Profile
    };
  }),
  on(profileActions.toggleProfileFollowed, (state: ProfileState) => {
    return {
      ...state,
      profile: {
        ...state.profile,
        isFollowedByViewer: !state.profile.isFollowedByViewer
      }
    };
  }),
  on(profileActions.setProfileSentFanRequest, (state: ProfileState, {value}) => {
    return {
      ...state,
      profile: {
        ...state.profile,
        sentFanRequest: value
      }
    };
  }),
  on(profileActions.setProfileIsViewerFan, (state: ProfileState, {value}) => {
    return {
      ...state,
      profile: {
        ...state.profile,
        isViewerFan: value
      }
    };
  }),
  on(profileActions.toggleProfileLiked, (state: ProfileState) => {
    return {
      ...state,
      profile: {
        ...state.profile,
        likes: {
          ...state.profile.likes,
          likesYou: !state.profile.likes.likesYou
        }
      }
    };
  })
);
