<ng-container *ngFor="let field of fields; let index = index">
  <label
    class="flush-checkbox-wrapper"
    [ngClass]="{
      disabled: field.disabled,
      checked: control.value.includes(field.value),
      column: direction === 'column'
    }"
    for="checkbox-field--{{ field.name }}-{{ index }}">
    <div class="flush-checkbox">
      <input
        class="flush-checkbox-input"
        id="checkbox-field--{{ field.name }}-{{ index }}"
        [type]="'checkbox'"
        [name]="field.name"
        [disabled]="field.disabled"
        [checked]="control.value.includes(field.value)"
        [value]="field.value"
        (change)="onChange(field)" />
      <span class="flush-checkbox-inner" [class.disabled]="field.disabled"></span>
    </div>
    <ng-container *ngIf="field.label">
      <span class="flush-checkbox-label-text" [innerHtml]="field.label | dtTranslate"></span>
    </ng-container>
  </label>
</ng-container>
