import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {RouterLink} from '@angular/router';
import {DtDefaultAvatarModule} from '@ui/pipes/default-avatar/dt-default-avatar.module';
import {NzIconModule} from 'ng-zorro-antd/icon';

import {ProfileAliasComponent} from './profile-alias.component';

@NgModule({
  declarations: [ProfileAliasComponent],
  exports: [ProfileAliasComponent],
  imports: [CommonModule, DtDefaultAvatarModule, RouterLink, NzIconModule]
})
export class ProfileAliasModule {}
