import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {DtTranslationsResolver} from '@ui/translation/resolvers/dt-translations.resolver';

import {UserLoaderGuard} from './core/guards/user-loader.guard';

const routes: Routes = [
  {
    path: 'auth',
    resolve: [DtTranslationsResolver],
    loadChildren: () => import('./features/auth/auth.module').then((m) => m.AuthModule)
  },
  {
    path: 'frontend/livez',
    loadChildren: () => import('./features/health-check/health-check.module').then((m) => m.HealthCheckModule)
  },
  {
    path: '',
    canActivate: [UserLoaderGuard],
    resolve: [DtTranslationsResolver],
    loadChildren: () => import('./features/main/main.module').then((m) => m.MainModule)
  },
  {
    path: '**',
    redirectTo: 'auth',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation: 'enabledBlocking',
      scrollPositionRestoration: 'disabled'
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
