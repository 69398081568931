export const StarReaction = `<svg width="145" height="135" viewBox="0 0 145 135" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M25.4824 134.226L57.3505 89.5673L72.0298 115.152L25.4824 134.226Z" fill="#750077"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M57.3506 89.5673L118.577 134.226L72.0298 115.152L57.3506 89.5673Z" fill="#48004B"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M57.3506 89.5674L97.4068 60.6641L108.424 86.452L57.3506 89.5674Z" fill="#630067"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M108.424 86.4518L57.3506 89.5672L118.577 134.226L108.424 86.4518Z" fill="#730175"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M97.4068 60.6639L48.9951 40.9614L72.0298 0.110352L97.4068 60.6639Z" fill="#7C017E"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M97.4073 60.6639L95.0648 40.9614L72.0303 0.110352L97.4073 60.6639Z" fill="#B05DAF"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M97.4069 60.6639L144.06 50.0605L95.0645 40.9614L97.4069 60.6639Z" fill="#7D017F"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M108.424 86.4518L144.06 50.0604L97.4072 60.6638L108.424 86.4518Z" fill="#6D0170"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M48.9954 40.9614L57.3508 89.5673L0 50.0605L48.9954 40.9614Z" fill="#7B007C"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M35.6358 86.4518L57.3508 89.5672L0 50.0604L35.6358 86.4518Z" fill="#5A005D"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M57.3505 89.5672L35.6355 86.4518L25.4824 134.226L57.3505 89.5672Z" fill="#9F459F"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M48.9951 40.9614L57.3505 89.5673L97.4068 60.6639L48.9951 40.9614Z" fill="#720074"/>
</svg>
`;

export const GiftReaction = `<svg width="166" height="159" viewBox="0 0 166 159" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M154.982 45.9466L82.3091 7.65771L10.9912 44.9906L83.6637 83.2796L154.982 45.9466Z" fill="#FADC36"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M73.1804 64.3823L83.6642 83.2787L49.4834 65.2355L73.1804 64.3823Z" fill="#99389A"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M82.202 67.8554L73.1797 64.3823L83.6301 83.2243L83.6635 83.2787L83.6568 83.2424L82.202 67.8554Z" fill="#B66AB5"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M96.2428 57.2688L82.2021 67.8576L83.6569 83.2445L83.6636 83.2808V83.2445L83.6903 83.2324L96.2428 57.2688Z" fill="#D19CD0"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M82.2021 67.8566L96.2428 57.2679L82.349 48.0284L82.2021 67.8566Z" fill="#B970B9"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M82.202 67.8549L82.3488 48.0267L82.202 47.9299L73.1797 64.3818L82.202 67.8549Z" fill="#AF5DAF"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M73.1801 64.382L82.2024 47.9301L49.4297 65.2049L49.3896 65.223L49.4163 65.2351H49.4831L73.1801 64.382Z" fill="#D09BCF"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M96.243 57.2688L83.6904 83.2324L116.51 66.0484L116.163 65.8669L96.243 57.2688Z" fill="#7B007D"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M82.3496 48.0327L96.2435 57.2721L116.163 65.8702L82.3496 48.0145V48.0327Z" fill="#D09BCF"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M137.958 39.7268L129.016 59.5006L154.921 45.9409L137.958 39.7268Z" fill="#7B007D"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M128.928 43.1939L137.957 39.7268L129.048 59.4461L129.014 59.5006L129.021 59.4642L128.928 43.1939Z" fill="#99389A"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M114.895 32.6095L128.929 43.1922L129.022 59.4626L129.015 59.4989L128.989 59.4505L114.895 32.6095Z" fill="#9A3A9B"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M128.929 43.1936L114.895 32.6109L120.473 27.7582L128.929 43.1936Z" fill="#670069"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M128.929 43.1936L120.474 27.7582L137.958 39.7265L128.929 43.1936Z" fill="#B260B2"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M137.958 39.7265L120.474 27.7582L154.981 45.9103L155.015 45.9285L154.988 45.9406H154.921L137.958 39.7265Z" fill="#D39ED2"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M114.895 32.6095L129.015 59.4989L94.6211 41.3891L94.9681 41.2015L114.895 32.6095Z" fill="#4A004D"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M120.474 27.7582L114.895 32.6109L94.9688 41.2029L120.481 27.7582H120.474Z" fill="#E0A602"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M28.1812 37.7122L36.9833 58.69L11.0508 45.0214L28.1812 37.7122Z" fill="#670069"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M37.204 41.1853L28.1816 37.7122L36.9571 58.6355L36.9838 58.69V58.6476L37.204 41.1853Z" fill="#810A84"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M51.2376 30.5973L37.2036 41.186L36.9834 58.6484V58.6908L37.0101 58.6363L51.2376 30.5973Z" fill="#670069"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M37.2041 41.1847L51.2381 30.596L43.9108 27.7582L37.2041 41.1847Z" fill="#F2BF0C"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M37.204 41.1847L43.9107 27.7582L28.1816 37.7116L37.204 41.1847Z" fill="#A54EA4"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M28.1819 37.7117L43.9109 27.7583L10.9914 44.9907L10.958 45.0089L10.9847 45.021H11.0514L28.1819 37.7117Z" fill="#D09BCF"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M51.2376 30.5973L36.9834 58.6908L69.7828 41.3918L69.4358 41.2042L51.2376 30.5973Z" fill="#4A004D"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M43.9102 27.7582L51.2375 30.596L69.4356 41.2029L43.9102 27.7582Z" fill="#999999"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M89.2082 13.6974L81.8008 34.887L107.56 21.2366L89.2082 13.6974Z" fill="#670069"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M80.1865 12.7247L89.2089 13.6989L81.8282 34.8341L81.8015 34.8885V34.8462L80.1865 12.7247Z" fill="#7B007D"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M75.1543 14.0861L80.186 12.7247L81.8009 34.8462V34.8885L81.7742 34.8341L75.1543 14.0861Z" fill="#99389A"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M80.186 12.7222L75.1543 14.0836L81.9077 7.65771L80.186 12.7222Z" fill="#670069"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M80.1865 12.7222L81.9082 7.65771L89.2089 13.6963L80.1865 12.7222Z" fill="#B260B2"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M89.2088 13.6963L81.9082 7.65771L107.621 21.2053L107.654 21.2234L107.627 21.2355H107.56L89.2088 13.6963Z" fill="#9A3A9B"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M75.1546 14.085L81.8012 34.8873L55.9821 21.2309L55.9688 21.2369L75.1546 14.085Z" fill="#660169"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M81.908 7.65771L55.9688 21.2355L75.1546 14.0836L81.9146 7.65771H81.908Z" fill="#D19CD0"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M83.377 157.266L83.8508 82.8843L106.82 94.2536L130.037 105.744L83.377 157.266Z" fill="#D09BCF"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M100.714 112.51L83.4023 157.431L83.6038 82.7651L100.714 112.51Z" fill="#A54EA4"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M130.038 105.745L106.821 94.2542L83.6045 82.7639L139.36 71.2373L130.038 105.745Z" fill="#7B007D"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M139.36 71.237L83.6045 82.7636L154.922 45.939L139.36 71.237Z" fill="#D09BCF"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M145.006 89.2137L139.36 71.237L154.923 45.939L154.689 120.054L145.006 89.2137Z" fill="#B05DAF"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M130.038 105.745L154.689 120.055L83.4121 157.431L130.038 105.745Z" fill="#AE5CAE"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M130.037 105.745L100.714 112.51L83.3848 157.474L130.037 105.745Z" fill="#7B007D"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M130.037 105.745L154.688 120.054L145.005 89.214L139.36 71.2373L130.037 105.745Z" fill="#983899"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M83.6163 82.772L83.3667 157.448L60.1862 121.599L36.9697 85.7066L83.6163 82.772Z" fill="#830C85"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M66.293 109.758L83.6169 82.772L83.4034 157.492L66.293 109.758Z" fill="#7B007D"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M36.9701 85.7051L60.1866 121.598L83.4031 157.491L27.6475 110.404L36.9701 85.7051Z" fill="#8B1F8C"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M27.6478 110.405L83.4035 157.491L10.9912 120.056L27.6478 110.405Z" fill="#4A004D"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M22.0022 86.5001L27.6478 110.406L10.9912 120.057L11.0513 45.0225L22.0022 86.5001Z" fill="#7B007D"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M36.97 85.7075L11.0508 45.0225L83.6165 82.7729L36.97 85.7075Z" fill="#810A84"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M36.9697 85.7066L66.2923 109.758L83.6163 82.772L36.9697 85.7066Z" fill="#670069"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M36.97 85.7075L11.0508 45.0225L22.0017 86.5001L27.6473 110.407L36.97 85.7075Z" fill="#670069"/>
<path d="M56.5372 21.1714L44.1182 27.7183L116.25 65.9104L128.843 59.4119L128.856 59.3877L56.5372 21.1714Z" fill="#F7DDF8"/>
<path d="M49.7032 65.1098L120.714 27.6861L108.268 21.1514L37.084 58.5629L49.7032 65.1098Z" fill="#F7DDF8"/>
<path d="M116.257 65.9031L116.591 140.055L129.07 133.532L128.843 59.4106L128.789 59.4348L116.257 65.9031Z" fill="#F7DDF8"/>
<path d="M119.893 54.5498L122.956 45.1954C122.956 45.1954 119.92 42.7207 114.454 41.6195C114.454 41.6195 120.527 38.8664 123.257 33.3602L119.353 31.1154C114.862 35.2965 107.548 40.0281 97.2774 42.636L119.887 54.5498H119.893ZM47.8347 52.9948L53.4002 50.0602C47.3409 49.7758 42.2491 48.5596 38.8457 47.2164C38.5654 49.7819 38.5654 52.0751 38.5654 52.0751C38.5654 52.0751 42.3559 52.7165 47.828 52.9948H47.8347ZM82.3892 34.7882L82.4025 34.7821C82.8229 32.9972 83.4569 31.3151 84.2577 29.7359C83.7972 29.1005 83.25 28.3865 82.5827 27.5818C80.7809 25.4035 78.185 24.3931 75.7626 24.3931C73.0131 24.3931 70.4973 25.694 69.6298 28.0719L82.3825 34.7942L82.3892 34.7882Z" fill="black" fill-opacity="0.2"/>
<path d="M52.6997 53.1222C61.4818 53.1222 72.466 51.8576 79.8334 46.533L78.1184 45.6314C70.3706 48.9412 62.7163 50.1271 56.0363 50.1271C55.2555 50.1271 54.4814 50.109 53.7273 50.0787C53.6139 50.0727 53.5071 50.0666 53.3937 50.0666L47.8281 53.0012C49.3363 53.0799 50.9779 53.1283 52.6997 53.1283V53.1222ZM73.6072 43.2535L71.7654 42.2854C71.4717 42.5274 71.1314 42.7392 70.751 42.9268C71.7453 43.0538 72.6996 43.1627 73.6072 43.2535ZM90.6909 39.1693C96.2298 35.6296 103.03 30.7225 107.508 25.5249C103.277 26.8984 99.3128 28.7076 95.8427 30.668C93.0733 32.2291 90.6242 33.8991 88.5955 35.5207C87.9281 36.0531 87.3275 36.5796 86.7803 37.0999L90.6976 39.1632L90.6909 39.1693ZM82.396 34.8006C82.396 34.7946 82.396 34.7885 82.4026 34.7885L82.3893 34.7946L82.4026 34.8006H82.396Z" fill="#4A004D" fill-opacity="0.4"/>
<path d="M112.76 55.9677C114.989 55.9677 117.285 55.7862 119.62 55.3808L119.894 54.5519L97.2848 42.638C92.6001 43.8239 87.2948 44.5742 81.3622 44.6226C81.3622 44.6226 81.449 44.5742 81.6225 44.4895C81.6225 44.3201 81.6292 44.1507 81.6292 43.9873C80.4613 44.5863 79.2935 45.1309 78.1257 45.6331L79.8407 46.5347C79.9475 46.456 80.0543 46.3773 80.161 46.2987L81.9095 44.9615C81.9095 44.9615 95.3295 55.9617 112.76 55.9617V55.9677ZM81.6625 44.4653C82.6969 43.9208 86.3005 41.9724 90.6916 39.1649L86.7743 37.1016C83.758 39.9696 82.41 42.5896 81.8961 43.836C81.9095 43.836 81.9161 43.836 81.9161 43.836C81.9095 43.8421 81.9028 43.8421 81.8894 43.8481C81.776 44.1265 81.7026 44.3383 81.6559 44.4653H81.6625ZM81.6292 43.83C81.6759 40.7562 81.7226 39.183 82.0029 36.9987C82.0963 36.2484 82.2298 35.5103 82.3966 34.7963L82.3833 34.7902L69.6305 28.0679C68.9499 29.9375 69.2902 32.4728 71.3523 35.5647C73.5878 38.9168 73.3342 40.9922 71.766 42.281L73.6078 43.2491C77.7053 43.6727 80.7216 43.7997 81.6292 43.83Z" fill="#D0A7D1"/>
<path d="M55.4226 7.16919C50.5644 8.82708 57.1577 28.4496 81.6688 44.6473C81.6688 44.6473 54.088 12.2457 72.6798 1.88086C72.6798 1.88086 68.449 4.83361 63.3305 6.40074C60.7813 7.18129 57.0309 6.61857 55.4226 7.16919Z" fill="#D0A7D1"/>
<path d="M55.4227 7.16825C68.5691 12.6139 75.1824 20.6674 78.4657 27.9283C82.5631 36.9862 81.4954 44.8158 81.4954 44.8158C86.5604 34.221 86.8007 25.6653 85.0122 19.0458C81.7957 7.13799 72.0259 1.49268 72.0259 1.49268C72.0259 1.49268 67.8484 3.60438 64.8054 4.52408C61.769 5.44379 55.416 7.16825 55.416 7.16825H55.4227Z" fill="#F7DDF8"/>
<path d="M81.4955 44.8179C86.5606 34.223 86.8008 25.6673 85.0124 19.0479C85.7464 22.6783 79.6737 27.8335 78.4658 27.9303C82.5632 36.9882 81.4955 44.8179 81.4955 44.8179Z" fill="#D0A7D1"/>
<path d="M124.471 22.9256C127.087 23.7485 113.413 44.3633 81.3613 44.6295C81.3613 44.6295 121.328 24.0026 110.437 10.1768C110.437 10.1768 114.881 19.9063 124.471 22.9256Z" fill="#D0A7D1"/>
<path d="M124.384 22.9305C121.608 22.8397 118.832 23.0394 116.089 23.4569C115.221 23.59 114.36 23.7473 113.5 23.9289C112.826 24.068 112.152 24.2254 111.484 24.3887C111.391 24.4069 111.291 24.4311 111.204 24.4553C111.097 24.4795 110.997 24.5158 110.884 24.54C105.385 25.9498 100.22 28.1886 95.842 30.6694C93.0725 32.2304 90.6234 33.9004 88.5947 35.522C82.849 40.1145 81.6211 44.6042 81.6211 44.6042C81.6745 41.004 81.7012 39.3582 82.0015 36.9984C82.8089 30.621 86.3525 25.4778 90.6701 21.4662C93.0992 19.2214 95.7619 17.3275 98.3178 15.7786C98.7982 15.4881 99.272 15.2098 99.7459 14.9375C100.046 14.7741 100.34 14.6168 100.627 14.4595C100.794 14.3687 100.947 14.278 101.107 14.1933C101.574 13.9452 102.028 13.7092 102.475 13.4853C106.94 11.2466 110.343 10.1816 110.343 10.1816C110.343 10.1816 113.159 13.3764 117.163 17.388C121.034 21.2787 124.377 22.9305 124.377 22.9305H124.384Z" fill="#F7DDF8"/>
<path d="M98.3195 15.7795C95.7636 17.3345 93.101 19.2223 90.6719 21.4672C92.0199 23.8269 94.6492 28.7583 95.8437 30.6643C99.5074 28.5889 103.718 26.6889 108.216 25.2912L108.336 25.2549C105.647 25.1036 99.5674 18.4358 98.3128 15.7734L98.3195 15.7795Z" fill="white"/>
<path d="M110.924 24.5574L111.491 24.3937L112.84 24.0907C112.84 24.0907 112.833 24.0847 112.82 24.0786C112.372 24.1816 111.932 24.2847 111.491 24.3937C111.398 24.4119 111.298 24.4362 111.211 24.4604C111.104 24.4846 111.004 24.521 110.891 24.5452L110.924 24.5634V24.5574Z" fill="#9785BC"/>
<path d="M53.7339 50.081C61.9821 50.4199 71.9854 49.004 81.9153 43.8427C81.9153 43.8427 61.8553 43.395 48.0549 37.2595C42.823 34.9421 38.4853 31.7958 36.49 27.5542C36.49 27.5542 36.51 40.9021 34.3946 43.6491C33.14 45.2828 41.5284 49.5849 53.7272 50.081H53.7339Z" fill="#DDB6DF"/>
<path d="M53.7348 50.081C51.6661 46.0331 49.6307 41.5737 48.0625 37.2595C42.8306 34.9421 38.4929 31.7958 36.4976 27.5542C36.4976 27.5542 36.4976 27.5602 36.4976 27.5724C36.4976 28.1956 36.4642 40.9626 34.4022 43.6431C34.3221 43.752 34.2754 43.873 34.2754 44C34.2754 45.8274 42.3434 49.6151 53.7281 50.075L53.7348 50.081Z" fill="#D0A7D1"/>
<path d="M34.4014 43.6465C36.6836 42.2428 39.0593 41.1657 41.4684 40.355C52.1124 36.7729 63.5905 38.334 71.5918 40.3731C76.7703 41.6982 80.2805 43.3864 81.3749 43.8583C81.5885 43.9491 81.6552 43.9975 81.6552 43.9975C81.5084 43.7978 81.4483 43.5074 81.3015 43.3077C77.1374 37.5777 72.3859 33.6689 67.3476 31.1397C59.5865 27.2309 51.5518 26.4141 45.6326 26.5351C40.1604 26.6501 36.4968 27.5516 36.4968 27.5516L34.4014 43.6465Z" fill="#F7DDF8"/>
<path d="M115.977 51.8097C115.876 55.6035 97.7116 55.543 81.4688 44.4581C81.4688 44.4581 111.779 53.9335 115.77 38.5103C115.77 38.5103 116.077 48.022 115.977 51.8097Z" fill="#DDB6DF"/>
<path d="M81.5684 44.6167C81.5684 44.6167 87.0805 41.549 95.3221 42.2872C98.7388 42.5837 102.609 43.5094 106.72 45.5304C109.703 46.9946 112.813 49.0216 115.976 51.811L116.323 38.8383C116.323 38.8383 114.621 37.8157 111.772 36.8839C107.841 35.6011 101.722 34.5059 94.8283 36.5027C90.564 37.737 86.0929 40.3146 81.5684 44.6106V44.6167Z" fill="#F7DDF8"/>
<path d="M95.2168 36.4038L95.6639 42.3335C99.0807 42.6299 102.951 43.5557 107.062 45.5766L112.114 36.9362C108.19 35.6535 102.117 34.407 95.2168 36.4038Z" fill="white"/>
<path d="M34.4012 43.6476C36.6835 42.2438 39.0592 41.1668 41.4683 40.356L45.6257 26.5361C40.1536 26.6511 36.49 27.5527 36.49 27.5527L34.3945 43.6476H34.4012Z" fill="white"/>
<path d="M37.0835 58.5635L36.7031 133.362L49.4158 139.897L49.7028 65.1104L37.0835 58.5635Z" fill="#D0A7D1"/>
</svg>
`;

export const BabyCrownReaction = `<svg width="166" height="158" viewBox="0 0 166 158" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M91.4101 102.202H81.7461V110.416H92.5354L104.249 111.161L117.116 113.5L126.279 116.467L133.258 119.976L136.594 122.583L138.424 119.201L135.253 115.585L130.047 112.448L122.7 109.076L111.51 105.082L91.4101 102.202Z" fill="#B247A9"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M91.3518 103.3H81.7461V109.316H92.5617L104.349 110.068L117.319 112.425L126.594 115.431L133.716 119.007L136.313 121.037L137.216 119.367L134.689 116.483L129.655 113.451L122.386 110.114L111.311 106.16L91.3518 103.3Z" fill="#482525"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M83.2509 72.3832L85.2649 77.4932L90.8916 81.7254L96.8207 81.1787L100.648 76.9813L101.485 74.6703L102.087 76.8071L105.106 80.6625L109.434 82.8254L115.882 80.9914L118.356 78.7348V82.0064L121.368 87.3494L127.89 89.9589L133.681 88.2795L134.59 87.3407L134.5 88.567L136.852 94.7422L141.678 98.0878L146.726 97.8831L149.033 96.6873L151.229 95.5307L152.486 93.2066L146.42 96.3518L142.024 96.5304L137.898 93.6683L135.856 88.3057L136.202 83.6117L133.042 86.8746L127.952 88.3492L122.232 86.0621L119.688 81.5468V75.5568L115.284 79.5712L109.551 81.2048L105.897 79.3773L103.265 76.0142L101.641 70.253L99.5298 76.0839L96.2477 79.6823L91.2372 80.1462L86.3132 76.4433L83.2547 68.6803L81.7461 62.4595V68.2839L83.2509 72.3832Z" fill="#FCDCFA"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M102.105 87.6674L103.627 85.058L102.545 82.2307L101.252 80.9848L99.8862 81.9998L98.5166 84.6158L99.6213 87.454L100.844 88.3688L100.916 88.3296L102.105 87.6674Z" fill="#DBB1DB"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M98.5166 84.6159L99.9482 84.7401L100.91 85.668L100.837 88.3667L99.6156 87.4519L98.5166 84.6159Z" fill="#640067"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M103.612 85.0576L102.18 84.9334L101.109 85.6849L100.837 88.3662L102.161 87.6714L103.612 85.0576Z" fill="#932D95"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M99.9482 84.74L101.015 83.5681L101.252 80.9848L99.8862 81.9998L98.5166 84.6158L99.9482 84.74Z" fill="#FCDCFA"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M102.195 84.934L101.29 83.5923L101.252 80.985L102.606 82.2353L103.627 85.0582L102.195 84.934Z" fill="#C88AC5"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M131.484 97.225L133.138 95.7787L132.978 93.4219L132.326 92.1477L131.131 92.4875L129.585 93.9839L129.758 96.3516L130.432 97.3666L130.492 97.3601L131.484 97.225Z" fill="#DBB1DB"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M129.585 93.9839L130.581 94.4871L131.065 95.4346L130.427 97.3645L129.754 96.3516L129.585 93.9839Z" fill="#640067"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M133.125 95.7725L132.13 95.2693L131.204 95.5046L130.426 97.3647L131.521 97.2449L133.125 95.7725Z" fill="#932D95"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M130.581 94.4871L131.597 93.9469L132.326 92.1477L131.131 92.4875L129.585 93.9839L130.581 94.4871Z" fill="#FCDCFA"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M132.141 95.2756L131.786 94.0428L132.325 92.1478L133.02 93.4438L133.137 95.7788L132.141 95.2756Z" fill="#C88AC5"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M143.931 104.679L145.556 103.871L145.84 101.878L145.524 100.667H144.472L142.926 101.542L142.651 103.546L143.033 104.548L143.083 104.557L143.931 104.679Z" fill="#DBB1DB"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M142.926 101.543L143.664 102.194L143.899 103.096L143.029 104.546L142.649 103.547L142.926 101.543Z" fill="#640067"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M145.548 103.864L144.811 103.211L144.001 103.187L143.028 104.546L143.958 104.705L145.548 103.864Z" fill="#932D95"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M143.664 102.194L144.602 101.987L145.524 100.667H144.472L142.926 101.543L143.664 102.194Z" fill="#BD73BD"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M144.819 103.218L144.742 102.111L145.524 100.667L145.871 101.906L145.556 103.871L144.819 103.218Z" fill="#C88AC5"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M134.689 116.482L136.313 121.037L137.1 121.719L137.956 120.065L137.216 119.366L134.689 116.482Z" fill="#7A007C"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M136.313 121.037L134.689 116.482L130.793 117.539L133.716 119.007L136.313 121.037Z" fill="#640067"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M130.792 117.539L134.688 116.482L129.653 113.45L126.715 112.102L130.792 117.539Z" fill="#7A007C"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M130.793 117.539L126.716 112.102L121.196 113.681L126.594 115.43L130.793 117.539Z" fill="#640067"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M121.197 113.681L126.717 112.102L122.386 110.113L116.705 108.085L121.197 113.681Z" fill="#7A007C"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M121.196 113.681L116.704 108.085L111.602 111.385L117.318 112.424L121.196 113.681Z" fill="#640067"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M111.603 111.386L116.705 108.086L111.311 106.16L106.01 105.4L111.603 111.386Z" fill="#7A007C"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M111.602 111.386L106.009 105.4L100.749 109.839L104.349 110.068L111.602 111.386Z" fill="#640067"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M100.748 109.839L106.01 105.4L91.4258 103.311L100.748 109.839Z" fill="#7A007C"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M100.749 109.839L91.4273 103.311L88.8535 109.316H92.562L100.749 109.839Z" fill="#640067"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M88.8532 109.316L91.427 103.311L91.3518 103.3H81.7461L88.8532 109.316Z" fill="#7A007C"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M88.8532 109.316L81.7461 103.3V109.316H88.8532Z" fill="#640067"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M81.7461 102.202H91.4101L100.648 103.524L92.9412 98.0873L81.7461 102.202Z" fill="#5A0057"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M81.7461 102.202L92.9412 98.0873L81.7461 88.401V102.202Z" fill="#870081"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M81.7461 88.401L92.9412 98.0873L102.057 93.7375L92.201 89.3638L81.7461 88.401Z" fill="#932D95"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M102.057 93.7378L92.9414 98.0876L100.648 103.524L111.174 99.6515L102.057 93.7378Z" fill="#A52F9E"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M111.173 99.6518L100.646 103.525L111.509 105.082L117.96 107.384L111.173 99.6518Z" fill="#870081"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M111.174 99.6512L117.962 107.384L127.768 105.447L121.499 97.6865L111.174 99.6512Z" fill="#71006D"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M111.174 99.6517L121.499 97.687L114.467 90.7539L114.4 90.7713L114.396 90.767H114.392L102.057 93.738L111.174 99.6517Z" fill="#870081"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M102.056 93.7376L114.392 90.7687L113.492 89.7102L113.49 89.708L102.162 87.6714L101.065 88.2486L100.916 88.3292L100.845 88.3684L102.056 93.7376Z" fill="#A52F9E"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M103.627 85.0578L109.434 82.8252L105.105 80.6622L103.619 85.036L103.627 85.0578Z" fill="#7A007C"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M103.62 85.036L105.106 80.6622L102.607 82.2349L103.62 85.036Z" fill="#A52F9E"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M102.606 82.2348L105.105 80.6621L102.086 76.8068L101.485 74.67L101.252 80.9845L102.606 82.2348Z" fill="#B247A9"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M101.252 80.9845L101.485 74.67L100.647 76.981L96.8691 81.1261L99.8243 82.1172L99.8863 81.9995L101.252 80.9845Z" fill="#BD57B3"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M99.8243 82.117L96.8692 81.1259L96.8203 81.1782L98.5957 84.4629L99.8243 82.117Z" fill="#7A007C"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M98.5173 84.6155L92.2012 89.3639L99.6164 87.4515L98.5173 84.6155Z" fill="#BD57B3"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M92.2001 89.364L98.5162 84.6156L90.8906 81.7252L92.2001 89.364Z" fill="#A52F9E"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M90.8906 81.7253L98.5162 84.6157L98.5951 84.4633L96.8198 81.1786L90.8906 81.7253Z" fill="#DA98D2"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M99.6164 87.4515L92.2012 89.364L102.057 93.7377L100.845 88.3685L100.841 88.3642L100.838 88.3664L99.6201 87.4559L99.6164 87.4515Z" fill="#B247A9"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M92.2008 89.3643L90.8913 81.7255L85.2646 77.4933L92.2008 89.3643Z" fill="#BD57B3"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M115.597 90.4317L117.33 88.4865L116.858 85.7878L115.947 84.4243L114.607 85.0145L113.003 86.9989L113.493 89.7085L114.399 90.7715L114.466 90.754L115.597 90.4317Z" fill="#DBB1DB"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M113.003 86.9985L114.217 87.4145L114.893 88.4317L114.391 90.7689L113.49 89.7081L113.003 86.9985Z" fill="#640067"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M117.318 88.4821L116.106 88.066L115.063 88.4886L114.393 90.7692L115.646 90.449L117.318 88.4821Z" fill="#932D95"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M114.217 87.4149L115.325 86.6242L115.947 84.4243L114.607 85.0145L113.003 86.9989L114.217 87.4149Z" fill="#FCDCFA"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M116.118 88.0705L115.559 86.7048L115.947 84.4243L116.911 85.8074L117.33 88.4865L116.118 88.0705Z" fill="#C88AC5"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M113.494 89.7085L113.006 87.0032L109.436 82.8254L103.629 85.0581L103.301 85.6244L102.164 87.6741L113.496 89.7106L114.396 90.7692L114.398 90.7649L113.498 89.7106L113.494 89.7085Z" fill="#BD57B3"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M109.434 82.8252L113.005 87.0051L113.003 86.9986L113.629 81.6316L109.434 82.8252Z" fill="#DA98D2"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M113.629 81.6316L113.003 86.9986L114.607 85.0143L113.629 81.6316Z" fill="#7A007C"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M113.629 81.6316L114.608 85.0143L115.947 84.4241L118.356 78.7347L115.881 80.9913L113.629 81.6316Z" fill="#BD57B3"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M118.356 78.7347L115.947 84.4241L116.911 85.8072L119.862 84.6767L118.356 82.0063V78.7347Z" fill="#B247A9"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M119.863 84.6768L116.911 85.8073L117.302 88.3057L119.863 84.6768Z" fill="#A52F9E"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M119.862 84.6768L117.302 88.3057L117.33 88.4864L121.367 87.3494L119.862 84.6768Z" fill="#B247A9"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M121.367 87.3493L117.33 88.4863L116.958 88.9045L115.645 90.4488L114.608 90.7124L114.467 90.7538L121.499 97.6869L121.367 87.3493Z" fill="#71006D"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M121.367 87.3493L121.499 97.6869L127.89 89.9588L121.367 87.3493Z" fill="#B247A9"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M127.89 89.9584L121.499 97.6865L130.427 97.3642L129.754 96.3513L129.585 93.9836L127.89 89.9584Z" fill="#7A007C"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M127.89 89.9589L129.584 93.9842L131.13 92.4878L129.943 89.3643L127.89 89.9589Z" fill="#DA98D2"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M129.943 89.3636L131.131 92.4871L132.326 92.1473L134.589 87.3401L133.68 88.2789L129.943 89.3636Z" fill="#BD57B3"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M134.589 87.3401L132.325 92.1473L133.02 93.4433L136.12 92.8225L134.499 88.5664L134.589 87.3401Z" fill="#B247A9"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M136.12 92.8231L133.021 93.4439L133.137 95.7789L136.851 94.7421L136.12 92.8231Z" fill="#A52F9E"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M136.851 94.7415L133.137 95.7783L132.784 96.0876L131.521 97.2442L130.614 97.3444L130.492 97.3596L130.431 97.3662L130.43 97.364H130.426L134.224 102.101L136.851 94.7415Z" fill="#B247A9"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M136.851 94.7415L134.225 102.101L141.677 98.0871L136.851 94.7415Z" fill="#7A007C"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M134.225 102.102L130.427 97.3643L121.499 97.6867L127.768 105.448L137.006 105.572L134.225 102.102Z" fill="#870081"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M142.925 101.543L134.225 102.102L137.005 105.572L143.028 104.544L142.651 103.546L142.925 101.543Z" fill="#A52F9E"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M134.225 102.102L142.925 101.542L144.471 100.666H145.523L141.677 98.0873L134.225 102.102Z" fill="#B247A9"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M141.678 98.0875L145.523 100.666L151.229 95.5303L149.033 96.6869L146.726 97.8828L141.678 98.0875Z" fill="#7A007C"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M151.229 95.5303L145.523 100.666L145.871 101.906L145.555 103.871L145.854 105.467L151.229 95.5303Z" fill="#BD57B3"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M145.206 104.045L143.958 104.705L143.192 104.572L143.083 104.557L143.032 104.548V104.546L143.028 104.544L140.22 115.881L145.854 105.468L145.555 103.871L145.206 104.045Z" fill="#870081"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M140.22 115.881L143.029 104.543L137.006 105.571L140.22 115.881Z" fill="#BD57B3"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M140.22 115.881L137.005 105.572L127.768 105.448L132.013 113.633L140.22 115.881Z" fill="#A52F9E"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M132.014 113.633L127.768 105.448L117.961 107.384L122.699 109.077L130.047 112.448L132.014 113.633Z" fill="#5A0057"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M140.22 115.881L132.014 113.633L135.253 115.585L138.424 119.2L140.22 115.881Z" fill="#870081"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M92.201 89.3643L85.2649 77.4933L81.7461 88.4016L92.201 89.3643Z" fill="#B247A9"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M81.7461 88.4011L85.2649 77.4928L83.2509 72.3828L81.7461 68.2835V88.4011Z" fill="#BD57B3"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M72.0816 102.202H81.7456V110.416H70.9563L59.2426 111.161L46.3755 113.5L37.215 116.467L30.2338 119.976L26.8972 122.583L25.0674 119.201L28.2405 115.585L33.4445 112.448L40.792 109.076L51.9815 105.082L72.0816 102.202Z" fill="#B247A9"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M72.1399 103.3H81.7456V109.316H70.93L59.1431 110.068L46.1745 112.425L36.8975 115.431L29.7754 119.007L27.1809 121.037L26.2754 119.367L28.8022 116.483L33.839 113.451L41.1058 110.114L52.1806 106.16L72.1399 103.3Z" fill="#482525"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M80.2402 72.3832L78.2263 77.4932L72.5996 81.7254L66.6705 81.1787L62.8436 76.9813L62.0057 74.6703L61.4045 76.8071L58.3873 80.6625L54.0569 82.8254L47.6093 80.9914L45.135 78.7348V82.0064L42.1235 87.3494L35.6007 89.9589L29.8106 88.2795L28.9013 87.3407L28.9915 88.567L26.6393 94.7422L21.813 98.0878L16.7649 97.8831L14.4598 96.6873L12.2617 95.5307L11.0049 93.2066L17.0712 96.3518L21.4673 96.5304L25.5929 93.6683L27.6369 88.3057L27.2894 83.6117L30.4512 86.8746L35.5406 88.3492L41.2593 86.0621L43.8031 81.5468V75.5568L48.2067 79.5712L53.9405 81.2048L57.5945 79.3773L60.2284 76.0142L61.8497 70.253L63.9614 76.0839L67.2435 79.6823L72.2558 80.1462L77.178 76.4433L80.2365 68.6803L81.7451 62.4595V68.2839L80.2421 72.3832H80.2402Z" fill="#FCDCFA"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M61.387 87.668L59.8652 85.0585L60.9474 82.2313L62.2399 80.9854L63.6057 82.0004L64.9772 84.6164L63.8725 87.4545L62.6476 88.3694L62.5762 88.3301L61.387 87.668Z" fill="#DBB1DB"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M64.9774 84.6165L63.5439 84.7406L62.582 85.6685L62.6553 88.3673L63.8764 87.4524L64.9774 84.6165Z" fill="#640067"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M59.8809 85.0581L61.3124 84.934L62.3833 85.6854L62.6557 88.3668L61.3331 87.6719L59.8809 85.0581Z" fill="#932D95"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M63.544 84.7405L62.477 83.5687L62.2402 80.9854L63.606 82.0004L64.9775 84.6164L63.544 84.7405Z" fill="#FCDCFA"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M61.2968 84.9344L62.2023 83.5926L62.2399 80.9854L60.8854 82.2356L59.8652 85.0585L61.2968 84.9344Z" fill="#C88AC5"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M32.0068 97.225L30.3555 95.7787L30.5133 93.4219L31.1652 92.1477L32.36 92.4875L33.9062 93.9839L33.7352 96.3516L33.0589 97.3666L33.0007 97.3601L32.0068 97.225Z" fill="#DBB1DB"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M33.9062 93.9839L32.9105 94.4871L32.4258 95.4346L33.0645 97.3645L33.7371 96.3516L33.9062 93.9839Z" fill="#640067"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M30.3652 95.7725L31.3609 95.2693L32.2871 95.5046L33.0649 97.3647L31.9696 97.2449L30.3652 95.7725Z" fill="#932D95"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M32.9103 94.4871L31.8958 93.9469L31.165 92.1478L32.3599 92.4876L33.906 93.984L32.9103 94.4871Z" fill="#FCDCFA"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M31.3493 95.2756L31.7044 94.0427L31.1652 92.1477L30.4701 93.4437L30.3555 95.7787L31.3493 95.2756Z" fill="#C88AC5"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M19.5611 104.679L17.936 103.871L17.6523 101.878L17.9698 100.667H19.02L20.5662 101.543L20.8405 103.547L20.4591 104.548L20.4084 104.557L19.5611 104.679Z" fill="#DBB1DB"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M20.5659 101.543L19.8295 102.194L19.5928 103.096L20.4626 104.546L20.8421 103.547L20.5659 101.543Z" fill="#640067"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M17.9434 103.864L18.6798 103.211L19.4914 103.187L20.4627 104.546L19.5327 104.705L17.9434 103.864Z" fill="#932D95"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M19.8296 102.194L18.8903 101.987L17.9697 100.667H19.0199L20.5661 101.543L19.8296 102.194Z" fill="#FCDCFA"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M18.6722 103.217L18.7492 102.111L17.9696 100.667L17.6201 101.906L17.9357 103.871L18.6722 103.217Z" fill="#C88AC5"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M28.8029 116.482L27.1816 121.037L26.4057 121.673L25.5039 120.007L26.276 119.366L28.8029 116.482Z" fill="#7A007C"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M27.1816 121.037L28.8029 116.482L32.6994 117.539L29.7761 119.007L27.1816 121.037Z" fill="#640067"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M32.6991 117.539L28.8027 116.482L33.8395 113.45L36.7759 112.102L32.6991 117.539Z" fill="#7A007C"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M32.6992 117.539L36.776 112.102L42.2956 113.681L36.8981 115.43L32.6992 117.539Z" fill="#640067"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M42.295 113.681L36.7754 112.102L41.1058 110.113L46.7888 108.085L42.295 113.681Z" fill="#7A007C"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M42.2949 113.681L46.7887 108.085L51.8894 111.385L46.1744 112.424L42.2949 113.681Z" fill="#640067"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M51.8897 111.386L46.7891 108.086L52.1809 106.16L57.4826 105.4L51.8897 111.386Z" fill="#7A007C"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M51.8896 111.386L57.4825 105.4L62.7428 109.839L59.1433 110.068L51.8896 111.386Z" fill="#640067"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M62.7428 109.839L57.4824 105.4L72.0648 103.311L62.7428 109.839Z" fill="#7A007C"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M62.7422 109.839L72.0642 103.311L74.6381 109.316H70.9295L62.7422 109.839Z" fill="#640067"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M74.6383 109.316L72.0645 103.311L72.1396 103.3H81.7453L74.6383 109.316Z" fill="#7A007C"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M74.6387 109.316L81.7458 103.3V109.316H74.6387Z" fill="#640067"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M81.7452 102.202H72.0813L62.8438 103.524L70.5501 98.0873L81.7452 102.202Z" fill="#5A0057"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M81.7459 102.202L70.5508 98.0873L81.7459 88.401V102.202Z" fill="#71006D"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M81.7461 88.401L70.5509 98.0873L61.4355 93.7375L71.293 89.3638L81.7461 88.401Z" fill="#870081"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M61.4357 93.7378L70.5511 98.0876L62.8447 103.524L52.3203 99.6515L61.4357 93.7378Z" fill="#BD57B3"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M52.3199 99.6518L62.8443 103.525L51.9817 105.082L45.5303 107.384L52.3199 99.6518Z" fill="#870081"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M52.32 99.6512L45.5304 107.384L35.7236 105.447L41.9928 97.6865L52.32 99.6512Z" fill="#71006D"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M52.3193 99.6517L41.9922 97.687L49.0241 90.7539L49.0918 90.7713L49.0955 90.767H49.0993L61.4347 93.738L52.3193 99.6517Z" fill="#870081"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M61.4351 93.7376L49.0996 90.7687L49.9995 89.7102L50.0014 89.708L61.3318 87.6714L62.427 88.2486L62.5755 88.3292L62.6468 88.3684L61.4351 93.7376Z" fill="#A52F9E"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M59.8646 85.0578L54.0576 82.8252L58.388 80.6622L59.8722 85.036L59.8646 85.0578Z" fill="#7A007C"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M59.8728 85.036L58.3887 80.6622L60.8855 82.2349L59.8728 85.036Z" fill="#A52F9E"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M60.8855 82.2348L58.3887 80.6621L61.4058 76.8068L62.007 74.67L62.24 80.9845L60.8855 82.2348Z" fill="#B247A9"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M62.2388 80.9845L62.0059 74.67L62.8438 76.981L66.6218 81.1261L63.6666 82.1172L63.6046 81.9995L62.2388 80.9845Z" fill="#BD57B3"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M63.667 82.117L66.6222 81.1259L66.671 81.1782L64.8957 84.4629L63.667 82.117Z" fill="#7A007C"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M64.9759 84.6155L71.2921 89.3639L63.875 87.4515L64.9759 84.6155Z" fill="#BD57B3"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M71.2927 89.364L64.9766 84.6156L72.6003 81.7252L71.2927 89.364Z" fill="#A52F9E"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M72.599 81.7253L64.9753 84.6157L64.8945 84.4633L66.6699 81.1786L72.599 81.7253Z" fill="#DA98D2"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M63.876 87.4515L71.293 89.364L61.4355 93.7377L62.6473 88.3685L62.6511 88.3642L62.6548 88.3664L63.8722 87.4559L63.876 87.4515Z" fill="#B247A9"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M71.293 89.3643L72.6005 81.7255L78.2272 77.4933L71.293 89.3643Z" fill="#BD57B3"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M47.8943 90.4317L46.1621 88.4865L46.6337 85.7878L47.5448 84.4243L48.8843 85.0145L50.4887 86.9989L49.9984 89.7085L49.0929 90.7715L49.0252 90.754L47.8943 90.4317Z" fill="#DBB1DB"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M50.4886 86.9985L49.2768 87.4145L48.5986 88.4317L49.1002 90.7689L50.002 89.7081L50.4886 86.9985Z" fill="#640067"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M46.1758 88.4821L47.3875 88.066L48.4302 88.4886L49.1009 90.7692L47.8497 90.449L46.1758 88.4821Z" fill="#932D95"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M49.2761 87.4149L48.1677 86.6242L47.5439 84.4243L48.8835 85.0145L50.4879 86.9989L49.2761 87.4149Z" fill="#FCDCFA"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M47.3739 88.0705L47.9356 86.7048L47.5448 84.4243L46.5811 85.8074L46.1621 88.4865L47.3739 88.0705Z" fill="#C88AC5"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M49.9974 89.7085L50.4859 87.0032L54.0554 82.8254L59.8624 85.0581L60.1912 85.6244L61.3278 87.6741L49.9955 89.7106L49.0956 90.7692L49.0938 90.7649L49.9936 89.7106L49.9974 89.7085Z" fill="#BD57B3"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M54.0574 82.8252L50.486 87.0051L50.4879 86.9986L49.8623 81.6316L54.0574 82.8252Z" fill="#DA98D2"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M49.8626 81.6316L50.4882 86.9986L48.8838 85.0143L49.8626 81.6316Z" fill="#7A007C"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M49.8625 81.6316L48.8837 85.0143L47.5442 84.4241L45.1357 78.7347L47.61 80.9913L49.8625 81.6316Z" fill="#BD57B3"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M45.1357 78.7347L47.5442 84.4241L46.5804 85.8072L43.6309 84.6767L45.1357 82.0063V78.7347Z" fill="#B247A9"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M43.6309 84.6768L46.5804 85.8073L46.1896 88.3057L43.6309 84.6768Z" fill="#A52F9E"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M43.6307 84.6768L46.1895 88.3057L46.1613 88.4864L42.124 87.3494L43.6307 84.6768Z" fill="#B247A9"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M42.1237 87.3493L46.161 88.4863L46.533 88.9045L47.8481 90.4488L48.8832 90.7124L49.0241 90.7538L41.9922 97.6869L42.1237 87.3493Z" fill="#71006D"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M42.1234 87.3493L41.9919 97.6869L35.6006 89.9588L42.1234 87.3493Z" fill="#B247A9"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M35.6007 89.9584L41.992 97.6865L33.0645 97.3642L33.737 96.3513L33.9061 93.9836L35.6007 89.9584Z" fill="#7A007C"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M35.6021 89.9589L33.9075 93.9842L32.3613 92.4878L33.5487 89.3643L35.6021 89.9589Z" fill="#DA98D2"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M33.5483 89.3636L32.361 92.4871L31.1662 92.1473L28.9023 87.3401L29.8116 88.2789L33.5483 89.3636Z" fill="#BD57B3"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M28.9022 87.3401L31.166 92.1473L30.4709 93.4433L27.3711 92.8225L28.9924 88.5664L28.9022 87.3401Z" fill="#B247A9"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M27.3714 92.8231L30.4713 93.4439L30.3567 95.7789L26.6406 94.7421L27.3714 92.8231Z" fill="#A52F9E"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M26.6406 94.7415L30.3567 95.7783L30.7099 96.0876L31.9705 97.2442L32.8779 97.3444L33.0019 97.3596L33.0601 97.3662L33.062 97.364H33.0657L29.2689 102.101L26.6406 94.7415Z" fill="#B247A9"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M26.6389 94.7415L29.2671 102.101L21.8125 98.0871L26.6389 94.7415Z" fill="#7A007C"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M29.2687 102.102L33.0656 97.3643L41.9931 97.6867L35.7239 105.448L26.4883 105.572L29.2687 102.102Z" fill="#870081"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M20.5662 101.543L29.2683 102.102L26.4878 105.572L20.4629 104.544L20.8405 103.546L20.5662 101.543Z" fill="#A52F9E"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M29.2682 102.102L20.5661 101.542L19.0199 100.666H17.9697L21.8135 98.0873L29.2682 102.102Z" fill="#B247A9"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M21.814 98.0875L17.9702 100.666L12.2627 95.5303L14.4608 96.6869L16.7659 97.8828L21.814 98.0875Z" fill="#7A007C"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M12.2627 95.5303L17.9702 100.666L17.6207 101.906L17.9363 103.871L17.6395 105.467L12.2627 95.5303Z" fill="#BD57B3"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M18.2849 104.045L19.5324 104.705L20.2989 104.572L20.4079 104.557L20.4586 104.548V104.546L20.4623 104.544L23.271 115.881L17.6387 105.468L17.9355 103.871L18.2849 104.045Z" fill="#870081"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M23.2715 115.881L20.4629 104.543L26.4878 105.571L23.2715 115.881Z" fill="#BD57B3"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M23.2715 115.881L26.4878 105.572L35.7234 105.448L31.4776 113.633L23.2715 115.881Z" fill="#A52F9E"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M31.4766 113.633L35.7224 105.448L45.5292 107.384L40.7911 109.077L33.4435 112.448L31.4766 113.633Z" fill="#5A0057"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M23.2715 115.881L31.4776 113.633L28.2406 115.585L25.0675 119.2L23.2715 115.881Z" fill="#870081"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M71.293 89.3643L78.2272 77.4933L81.746 88.4016L71.293 89.3643Z" fill="#B247A9"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M81.7453 88.4011L78.2266 77.4928L80.2424 72.3828L81.7453 68.2835V88.4011Z" fill="#DA98D2"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M81.7456 70.7843L76.04 63.3546L81.7456 52.5835L87.4512 63.3546L81.7456 70.7843Z" fill="#BD57B3"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M81.7456 67.4825L78.1104 62.7493L81.7456 55.8859L85.3828 62.7493L81.7456 67.4825Z" fill="#FCDCFA"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M80.5264 65.8968L78.1104 62.7494L76.04 63.3549L79.7861 68.234L80.7293 69.4603L81.7456 70.7847V67.4826L81.5484 67.2277L80.5264 65.8968Z" fill="#FCDCFA"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M85.3832 62.7491L82.9654 65.8965L81.9434 67.2274L81.7461 67.4822V70.7843L82.7625 69.46L83.7056 68.2337L87.4517 63.3546L85.3363 62.6619L85.3832 62.7491Z" fill="#71006D"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M81.7461 55.8856L85.3363 62.6619L87.4517 63.3546L81.7461 52.5835V55.8856Z" fill="#FCDCFA"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M78.1104 62.749L81.7456 55.8856V52.5835L76.04 63.3546L78.1104 62.749Z" fill="#BD57B3"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M81.7457 64.0448L79.666 61.3374L81.7457 57.408L83.8273 61.3374L81.7457 64.0448Z" fill="#BD57B3"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M81.7456 67.4818V64.0447L81.4525 63.6657L79.6659 61.3372L78.1104 62.7487L80.5263 65.8961L80.8194 66.2773L81.5484 67.227L81.7456 67.4818Z" fill="#BD57B3"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M81.7461 64.0447V67.4818L81.9434 67.227L82.6723 66.2773L82.9654 65.8961L85.3832 62.7487L83.8277 61.3372L82.0392 63.6657L81.7461 64.0447Z" fill="#B247A9"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M101.485 73.0713L105.402 67.9722L101.485 60.5795L97.5684 67.9722L101.485 73.0713Z" fill="#BD57B3"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M101.485 70.8061L103.98 67.5562L101.485 62.8448L98.9883 67.5562L101.485 70.8061Z" fill="#FCDCFA"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M102.321 69.7166L103.98 67.5558L105.402 67.9719L102.83 71.3197L102.182 72.1627L101.485 73.071V70.8057L101.621 70.6292L102.321 69.7166Z" fill="#FCDCFA"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M98.9886 67.556L100.649 69.7167L101.35 70.6294L101.485 70.8058V73.0711L100.788 72.1628L100.14 71.3199L97.5684 67.972L99.0206 67.495L98.9886 67.556Z" fill="#FCDCFA"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M101.485 62.8448L99.0206 67.4952L97.5684 67.9722L101.485 60.5795V62.8448Z" fill="#FCDCFA"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M103.98 67.556L101.485 62.8446V60.5793L105.402 67.9721L103.98 67.556Z" fill="#BD57B3"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M101.484 68.4472L102.912 66.587L101.484 63.8904L100.057 66.587L101.484 68.4472Z" fill="#BD57B3"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M101.485 70.806V68.4471L101.686 68.1857L102.913 66.5869L103.98 67.5562L102.321 69.7169L102.12 69.9783L101.621 70.6296L101.485 70.806Z" fill="#BD57B3"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M101.485 68.4471V70.806L101.35 70.6296L100.85 69.9783L100.649 69.7169L98.9883 67.5562L100.057 66.5869L101.284 68.1857L101.485 68.4471Z" fill="#B247A9"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M118.756 77.881L123.875 74.507L122.21 66.0492L116.456 71.5948L118.756 77.881Z" fill="#BD57B3"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M119.383 75.7347L122.645 73.5849L121.583 68.1939L117.918 71.7291L119.383 75.7347Z" fill="#FCDCFA"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M120.477 75.0143L122.645 73.5854L123.875 74.5068L120.514 76.722L119.667 77.2796L118.756 77.8807L119.383 75.7352L119.558 75.6176L120.477 75.0143Z" fill="#FCDCFA"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M117.918 71.729L118.891 74.3907L119.302 75.5168L119.383 75.7346L118.756 77.8801L118.346 76.7606L117.967 75.7216L116.456 71.5939L117.965 71.6832L117.918 71.729Z" fill="#FCDCFA"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M121.583 68.1947L117.965 71.6841L116.456 71.5948L122.21 66.0492L121.583 68.1947Z" fill="#FCDCFA"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M122.644 73.5857L121.583 68.1947L122.21 66.0492L123.875 74.507L122.644 73.5857Z" fill="#BD57B3"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M120.035 73.5003L121.902 72.2696L121.294 69.1853L119.195 71.2088L120.035 73.5003Z" fill="#BD57B3"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M119.383 75.7348L120.035 73.5L120.298 73.3279L121.902 72.2693L122.644 73.585L120.476 75.0138L120.213 75.1859L119.558 75.6172L119.383 75.7348Z" fill="#BD57B3"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M120.035 73.4999L119.383 75.7347L119.303 75.5169L119.009 74.7153L118.891 74.3907L117.918 71.729L119.195 71.2084L119.917 73.1775L120.035 73.4999Z" fill="#B247A9"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M135.241 85.8072L139.592 83.6399L138.912 76.709L133.889 80.5447L135.241 85.8072Z" fill="#BD57B3"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M135.909 84.1561L138.68 82.7752L138.248 78.3578L135.045 80.8039L135.909 84.1561Z" fill="#FCDCFA"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M136.837 83.6945L138.68 82.7753L139.593 83.6401L136.736 85.0624L136.018 85.4218L135.242 85.8073L135.909 84.1563L136.058 84.0822L136.837 83.6945Z" fill="#FCDCFA"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M135.044 80.8038L135.619 83.0321L135.861 83.9752L135.908 84.156L135.241 85.8071L135.001 84.8683L134.777 83.9992L133.889 80.5446L135.085 80.7733L135.044 80.8038Z" fill="#FCDCFA"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M138.247 78.3579L135.085 80.7735L133.889 80.5447L138.912 76.709L138.247 78.3579Z" fill="#FCDCFA"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M138.68 82.7754L138.248 78.358L138.913 76.7092L139.593 83.6401L138.68 82.7754Z" fill="#BD57B3"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M136.602 82.4375L138.187 81.649L137.939 79.1202L136.107 80.5207L136.602 82.4375Z" fill="#BD57B3"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M135.908 84.1561L136.601 82.4375L136.823 82.3264L138.187 81.649L138.679 82.7751L136.836 83.6943L136.613 83.8054L136.057 84.082L135.908 84.1561Z" fill="#BD57B3"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M136.602 82.4374L135.909 84.1559L135.862 83.9751L135.689 83.3021L135.62 83.032L135.045 80.8037L136.108 80.5206L136.533 82.1694L136.602 82.4374Z" fill="#B247A9"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M151.024 95.5653L153.701 93.6246L153.282 87.4081L150.191 90.8496L151.024 95.5653Z" fill="#BD57B3"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M151.434 94.0868L153.14 92.8496L152.873 88.8896L150.904 91.0809L151.434 94.0868Z" fill="#FCDCFA"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M152.004 93.6724L153.139 92.8491L153.701 93.6245L151.942 94.8987L151.501 95.2211L151.023 95.5652L151.433 94.0863L151.525 94.0187L152.004 93.6724Z" fill="#FCDCFA"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M150.903 91.0803L151.255 93.0777L151.405 93.9228L151.433 94.0862L151.024 95.5652L150.875 94.7266L150.738 93.9468L150.191 90.8494L150.928 91.052L150.903 91.0803Z" fill="#FCDCFA"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M152.872 88.8892L150.928 91.0522L150.191 90.8496L153.282 87.4081L152.872 88.8892Z" fill="#FCDCFA"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M153.139 92.8493L152.872 88.8894L153.282 87.4082L153.701 93.6247L153.139 92.8493Z" fill="#BD57B3"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M151.86 92.5466L152.837 91.8387L152.683 89.5712L151.556 90.8259L151.86 92.5466Z" fill="#BD57B3"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M151.434 94.0866L151.86 92.5467L151.997 92.4465L152.837 91.8387L153.139 92.8494L152.005 93.6728L151.868 93.7708L151.526 94.0191L151.434 94.0866Z" fill="#BD57B3"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M151.861 92.5467L151.434 94.0866L151.406 93.9233L151.299 93.3221L151.256 93.0782L150.904 91.0808L151.556 90.8259L151.817 92.3049L151.861 92.5467Z" fill="#B247A9"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M61.8585 73.0713L57.9414 67.9722L61.8585 60.5795L65.7737 67.9722L61.8585 73.0713Z" fill="#BD57B3"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M61.8581 70.8061L59.3613 67.5562L61.8581 62.8448L64.353 67.5562L61.8581 70.8061Z" fill="#FCDCFA"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M61.0206 69.7166L59.3617 67.5558L57.9414 67.9719L60.5133 71.3197L61.1596 72.1627L61.8585 73.071V70.8057L61.7232 70.6292L61.0206 69.7166Z" fill="#FCDCFA"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M64.3533 67.556L62.6944 69.7167L61.9937 70.6294L61.8584 70.8058V73.0711L62.5554 72.1628L63.2035 71.3199L65.7736 67.972L64.3214 67.495L64.3533 67.556Z" fill="#FCDCFA"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M61.8584 62.8446L64.3214 67.495L65.7736 67.9721L61.8584 60.5793V62.8446Z" fill="#FCDCFA"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M59.3617 67.5562L61.8585 62.8448V60.5795L57.9414 67.9722L59.3617 67.5562Z" fill="#BD57B3"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M61.8584 68.4472L60.4287 66.587L61.8584 63.8904L63.2862 66.587L61.8584 68.4472Z" fill="#BD57B3"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M61.8581 70.806V68.4471L61.6571 68.1857L60.4284 66.5869L59.3613 67.5562L61.0202 69.7169L61.2212 69.9783L61.7228 70.6296L61.8581 70.806Z" fill="#BD57B3"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M61.8584 68.4471V70.806L61.9937 70.6296L62.4934 69.9783L62.6944 69.7169L64.3533 67.5562L63.2862 66.5869L62.0575 68.1857L61.8584 68.4471Z" fill="#B247A9"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M44.5871 77.881L39.4658 74.507L41.1322 66.0492L46.8866 71.5948L44.5871 77.881Z" fill="#BD57B3"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M43.9596 75.7347L40.6963 73.5849L41.7577 68.1939L45.425 71.7291L43.9596 75.7347Z" fill="#FCDCFA"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M42.8663 75.0143L40.6964 73.5854L39.4658 74.5068L42.8287 76.722L43.6741 77.2796L44.5871 77.8807L43.9596 75.7352L43.783 75.6176L42.8663 75.0143Z" fill="#FCDCFA"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M45.4244 71.729L44.4493 74.3907L44.0379 75.5168L43.959 75.7346L44.5865 77.8801L44.996 76.7606L45.3755 75.7216L46.886 71.5939L45.3774 71.6832L45.4244 71.729Z" fill="#FCDCFA"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M41.7574 68.1947L45.3777 71.6841L46.8863 71.5948L41.1318 66.0492L41.7574 68.1947Z" fill="#FCDCFA"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M40.6964 73.5857L41.7578 68.1947L41.1322 66.0492L39.4658 74.507L40.6964 73.5857Z" fill="#BD57B3"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M43.3078 73.5003L41.4404 72.2696L42.0472 69.1853L44.1457 71.2088L43.3078 73.5003Z" fill="#BD57B3"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M43.9596 75.7348L43.3077 73.5L43.0447 73.3279L41.4402 72.2693L40.6963 73.585L42.8662 75.0138L43.1292 75.1859L43.783 75.6172L43.9596 75.7348Z" fill="#BD57B3"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M43.3076 73.4999L43.9595 75.7347L44.0384 75.5169L44.3334 74.7153L44.4499 74.3907L45.4249 71.729L44.1455 71.2084L43.4241 73.1775L43.3076 73.4999Z" fill="#B247A9"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M28.0992 85.8072L23.75 83.6399L24.4282 76.709L29.4537 80.5447L28.0992 85.8072Z" fill="#BD57B3"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M27.4351 84.1561L24.6621 82.7752L25.0961 78.3578L28.2974 80.8039L27.4351 84.1561Z" fill="#FCDCFA"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M26.5042 83.6945L24.6612 82.7753L23.75 83.6401L26.6056 85.0624L27.3251 85.4218L28.0992 85.8073L27.4341 84.1563L27.2838 84.0822L26.5042 83.6945Z" fill="#FCDCFA"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M28.2959 80.8038L27.7229 83.0321L27.4806 83.9752L27.4336 84.156L28.0987 85.8071L28.341 84.8683L28.5646 83.9992L29.4532 80.5446L28.2546 80.7733L28.2959 80.8038Z" fill="#FCDCFA"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M25.0956 78.358L28.2556 80.7736L29.4542 80.5449L24.4287 76.7092L25.0956 78.358Z" fill="#FCDCFA"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M24.6612 82.7752L25.0951 78.3579L24.4282 76.709L23.75 83.6399L24.6612 82.7752Z" fill="#BD57B3"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M26.7408 82.4375L25.1533 81.649L25.4013 79.1202L27.2349 80.5207L26.7408 82.4375Z" fill="#BD57B3"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M27.4351 84.1561L26.7418 82.4375L26.5183 82.3264L25.1543 81.649L24.6621 82.7751L26.5051 83.6943L26.7287 83.8054L27.2848 84.082L27.4351 84.1561Z" fill="#BD57B3"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M26.7412 82.4374L27.4344 84.1559L27.4814 83.9751L27.6543 83.3021L27.7238 83.032L28.2968 80.8037L27.2353 80.5206L26.8107 82.1694L26.7412 82.4374Z" fill="#B247A9"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M12.3187 95.5653L9.6416 93.6246L10.0587 87.4081L13.151 90.8496L12.3187 95.5653Z" fill="#BD57B3"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M11.909 94.0868L10.2031 92.8496L10.468 88.8896L12.4388 91.0809L11.909 94.0868Z" fill="#FCDCFA"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M11.3362 93.6724L10.2033 92.8491L9.6416 93.6245L11.3982 94.8987L11.8415 95.2211L12.3187 95.5652L11.9092 94.0863L11.8152 94.0187L11.3362 93.6724Z" fill="#FCDCFA"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M12.439 91.0803L12.0858 93.0777L11.9374 93.9228L11.9092 94.0862L12.3187 95.5652L12.4653 94.7266L12.6043 93.9468L13.151 90.8494L12.4145 91.052L12.439 91.0803Z" fill="#FCDCFA"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M10.4681 88.8894L12.4145 91.0523L13.1509 90.8497L10.0586 87.4082L10.4681 88.8894Z" fill="#FCDCFA"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M10.2033 92.8492L10.4682 88.8892L10.0587 87.4081L9.6416 93.6246L10.2033 92.8492Z" fill="#BD57B3"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M11.4809 92.5466L10.5059 91.8387L10.658 89.5712L11.7852 90.8259L11.4809 92.5466Z" fill="#BD57B3"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M11.909 94.0866L11.4806 92.5467L11.3435 92.4465L10.5056 91.8387L10.2031 92.8494L11.336 93.6728L11.4731 93.7708L11.815 94.0191L11.909 94.0866Z" fill="#BD57B3"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M11.4805 92.5467L11.9088 94.0866L11.937 93.9233L12.0422 93.3221L12.0854 93.0782L12.4386 91.0808L11.7848 90.8259L11.5237 92.3049L11.4805 92.5467Z" fill="#B247A9"/>
</svg>
`;

export const DiamondReaction = `<svg width="166" height="159" viewBox="0 0 166 159" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_6331_8071)">
<path d="M82.6715 159.227L109.863 89.2426L93.0278 85.8726L58.6209 92.4795L82.6715 159.227Z" fill="#F3C8F6"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M135.731 29.9798L113.681 15.2265H83.4767H81.7886H51.5842L29.4287 30.2222L42.5526 51.0099L81.5419 57.0572C81.6163 57.2374 81.6983 57.3401 81.7886 57.3573C81.9634 57.3908 82.4245 57.3692 83.1201 57.302L83.4767 57.3573V57.2658C91.3333 56.4318 122.713 51.0099 122.713 51.0099L135.731 29.9798Z" fill="#F3C8F6"/>
<path d="M58.8301 92.6645L46.9473 50.2004L10.6276 65.8961L58.8301 92.6645Z" fill="#E59BEB"/>
<path d="M82.3516 158.608L58.7937 92.5798L10.6233 65.8916L82.3516 158.608Z" fill="#CA63D1"/>
<path d="M83.7979 57.2219L46.9216 50.1724L58.7801 92.608L83.7979 57.2219Z" fill="#FBD5FF"/>
<path d="M83.766 57.2042L58.7754 92.5794H106.539L83.766 57.2042Z" fill="#FAB0FF"/>
<path d="M46.9512 50.19L29.579 29.9667L10.6259 65.8964L46.9512 50.19Z" fill="#9A40A0"/>
<path d="M106.508 92.5851L118.356 50.2004L154.655 65.9069L106.508 92.5851Z" fill="#CA63D1"/>
<path d="M82.9502 158.608L106.508 92.5798L154.678 65.8916L82.9502 158.608Z" fill="#953296"/>
<path d="M83.7598 57.2219L118.38 50.1724L106.51 92.5734L83.7598 57.2219Z" fill="#E59BEB"/>
<path d="M118.352 50.19L135.724 29.9667C135.724 29.9667 154.707 65.7952 154.668 65.9022C154.63 66.0092 118.352 50.19 118.352 50.19Z" fill="#FBD5FF"/>
</g>
<defs>
<clipPath id="clip0_6331_8071">
<rect width="165.302" height="157.804" fill="white" transform="translate(0 0.353516)"/>
</clipPath>
</defs>
</svg>
`;

export const KissReaction = `<svg width="166" height="158" viewBox="0 0 166 158" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M17.7969 77.1361H143.111V90.1118H17.7969V77.1361Z" fill="#3C0642"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M106.099 116.29L82.4033 120.571V127.006L106.099 121.266V116.29Z" fill="#7789A4"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M82.4033 120.571L106.099 116.29L82.4033 111.854V120.571Z" fill="#7789A4"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M82.4033 111.854L106.099 116.29L105.918 106.103L82.4033 111.854Z" fill="#7789A4"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M105.918 106.103L106.098 116.29L124.714 109.004L124.833 108.9L105.918 106.103Z" fill="#7789A4"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M124.714 109.004L106.099 116.289V121.266L126.556 112.894L124.833 108.9L124.714 109.004Z" fill="#7789A4"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M124.833 108.9L126.556 112.894L141.58 99.0053L139.585 95.9321L124.833 108.9Z" fill="#7789A4"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M124.83 108.9L139.582 95.932L122.923 83.9424L124.83 108.9Z" fill="#7789A4"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M124.833 108.9L122.926 83.9424L106.098 87.8827L105.918 106.103L124.833 108.9Z" fill="#7789A4"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M105.918 106.103L106.099 87.8827H106.03L82.4033 98.7789V111.854L105.918 106.103Z" fill="#7789A4"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M82.4033 98.7789L106.03 87.8827L106.099 87.852L99.1159 83.9424L82.4033 90.9981V98.7789Z" fill="#7789A4"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M82.4033 90.998L99.1159 83.9424L99.6761 81.9012L99.5955 81.9089L87.2759 87.8289L82.4033 88.2778V90.998Z" fill="#7789A4"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M99.1162 83.9425L115.568 81.2223L115.622 80.0483L115.583 80.0444L99.6764 81.9014L99.1162 83.9425Z" fill="#7789A4"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M115.568 81.2224L99.1162 83.9426L106.099 87.8522L115.568 81.2224Z" fill="#7789A4"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M115.564 81.2224L106.099 87.8522V87.8829L122.923 83.9426L115.564 81.2224Z" fill="#7789A4"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M115.563 81.2224L122.922 83.9426L132.123 81.6828L115.563 81.2224Z" fill="#7789A4"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M115.563 81.2221L132.123 81.6825L131.866 80.7962L115.621 80.0481L115.563 81.2221Z" fill="#7789A4"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M132.123 81.6826L122.923 83.9424L139.582 95.9321L132.123 81.6826Z" fill="#7789A4"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M132.122 81.6825L139.584 95.9319L142.696 80.9075L132.122 81.6825Z" fill="#7789A4"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M142.693 80.9077L139.581 95.9322L154.429 80.0444L142.693 80.9077Z" fill="#7789A4"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M142.696 80.9077L154.433 80.0444H154.225H146.226L142.37 80.2478L142.696 80.9077Z" fill="#7789A4"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M142.692 80.9076L142.37 80.2477L131.865 80.7963L132.122 81.6826L142.692 80.9076Z" fill="#7789A4"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M154.429 80.0444L139.581 95.9322L141.58 99.0054L153.9 81.0995L154.429 80.0444Z" fill="#7789A4"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M106.099 116.29L82.4033 120.571V127.006L106.099 121.266V116.29Z" fill="#3C0642"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M82.4033 120.571L106.099 116.29L82.4033 111.854V120.571Z" fill="#4D0953"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M82.4033 111.854L106.099 116.29L105.918 106.103L82.4033 111.854Z" fill="#4D0953"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M105.918 106.103L106.098 116.29L124.714 109.004L124.833 108.9L105.918 106.103Z" fill="#5D0C65"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M124.714 109.004L106.099 116.289V121.266L126.556 112.894L124.833 108.9L124.714 109.004Z" fill="#4D0953"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M124.833 108.9L126.556 112.894L141.58 99.0053L139.585 95.9321L124.833 108.9Z" fill="#5D0C65"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M124.83 108.9L139.582 95.932L122.923 83.9424L124.83 108.9Z" fill="#7C2183"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M124.833 108.9L122.926 83.9424L106.098 87.8827L105.918 106.103L124.833 108.9Z" fill="#70137A"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M105.918 106.103L106.099 87.8827H106.03L82.4033 98.7789V111.854L105.918 106.103Z" fill="#7C2183"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M82.4033 98.7789L89.8427 96.4577L106.03 87.8827L106.099 87.852L99.1159 83.9424L82.4033 90.9981V98.7789Z" fill="#8E4096"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M82.4033 90.998L87.6903 90.1118L99.1159 83.9424L99.6761 81.9012L99.5955 81.9089L87.2759 87.8289L82.4033 88.2778V90.998Z" fill="#3C0642"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M99.1162 83.9425L115.568 81.2223L115.622 80.0483L115.583 80.0444L99.6764 81.9014L99.1162 83.9425Z" fill="#3F0645"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M115.568 81.2224L99.1162 83.9426L106.099 87.8522L115.568 81.2224Z" fill="#813489"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M115.564 81.2224L106.099 87.8522V87.8829L122.923 83.9426L115.564 81.2224Z" fill="#8E4096"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M115.563 81.2224L122.922 83.9426L132.123 81.6828L115.563 81.2224Z" fill="#8E4096"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M115.563 81.2221L132.123 81.6825L131.866 80.7962L115.621 80.0481L115.563 81.2221Z" fill="#4D0953"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M132.123 81.6826L122.923 83.9424L139.582 95.9321L132.123 81.6826Z" fill="#8E4096"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M132.122 81.6825L139.584 95.9319L142.696 80.9075L132.122 81.6825Z" fill="#701379"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M142.693 80.9077L139.581 95.9322L154.429 80.0444L142.693 80.9077Z" fill="#5D0C65"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M142.696 80.9077L154.433 80.0444H154.225H146.226L142.37 80.2478L142.696 80.9077Z" fill="#5D0C65"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M142.692 80.9076L142.37 80.2477L131.865 80.7963L132.122 81.6826L142.692 80.9076Z" fill="#4C0A53"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M154.429 80.0444L139.581 95.9322L141.58 99.0054L153.9 81.0995L154.429 80.0444Z" fill="#4C0953"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M58.708 116.29L82.4034 120.571V127.006L58.708 121.266V116.29Z" fill="#7789A4"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M82.4034 120.571L58.708 116.29L82.4034 111.854V120.571Z" fill="#7789A4"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M82.4034 111.854L58.708 116.29L58.8883 106.103L82.4034 111.854Z" fill="#7789A4"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M58.8837 106.103L58.7072 116.29L40.0916 109.004L39.9727 108.9L58.8837 106.103Z" fill="#7789A4"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M40.0926 109.004L58.7082 116.289V121.266L38.251 112.894L39.9736 108.9L40.0926 109.004Z" fill="#7789A4"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M39.9727 108.9L38.2501 112.894L23.2256 99.0053L25.2207 95.9321L39.9727 108.9Z" fill="#7789A4"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M39.9766 108.9L25.2207 95.932L41.8834 83.9424L39.9766 108.9Z" fill="#7789A4"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M39.9727 108.9L41.8795 83.9424L58.7072 87.8827L58.8837 106.103L39.9727 108.9Z" fill="#7789A4"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M58.8883 106.103L58.708 87.8827H58.7732L82.4034 98.7789V111.854L58.8883 106.103Z" fill="#7789A4"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M82.4034 98.7789L58.7732 87.8827L58.708 87.852L65.6908 83.9424L82.4034 90.9981V98.7789Z" fill="#7789A4"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M82.4027 90.998L65.69 83.9424L65.1299 81.9012L65.2105 81.9089L76.8778 87.5719L82.4027 88.2778V90.998Z" fill="#7789A4"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M65.69 83.9425L49.2383 81.2223L49.1846 80.0483L49.2229 80.0444L65.1298 81.9014L65.69 83.9425Z" fill="#7789A4"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M49.2383 81.2224L65.69 83.9426L58.7072 87.8522L49.2383 81.2224Z" fill="#7789A4"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M49.2387 81.2224L58.7077 87.8522V87.8829L41.8838 83.9426L49.2387 81.2224Z" fill="#7789A4"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M49.2389 81.2224L41.884 83.9426L32.6836 81.6828L49.2389 81.2224Z" fill="#7789A4"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M49.2389 81.2221L32.6836 81.6825L32.9407 80.7962L49.1852 80.0481L49.2389 81.2221Z" fill="#7789A4"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M32.6831 81.6826L41.8834 83.9424L25.2207 95.9321L32.6831 81.6826Z" fill="#7789A4"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M32.6833 81.6825L25.2209 95.9319L22.1094 80.9075L32.6833 81.6825Z" fill="#7789A4"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M22.1095 80.9077L25.221 95.9322L10.373 80.0444L22.1095 80.9077Z" fill="#7789A4"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M22.1095 80.9077L10.373 80.0444H10.5802H18.5759L22.4356 80.2478L22.1095 80.9077Z" fill="#7789A4"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M22.1094 80.9076L22.4355 80.2477L32.9403 80.7963L32.6833 81.6826L22.1094 80.9076Z" fill="#7789A4"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M10.373 80.0444L25.221 95.9322L23.226 99.0054L10.9063 81.0995L10.373 80.0444Z" fill="#7789A4"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M58.708 116.29L82.4034 120.571V127.006L58.708 121.266V116.29Z" fill="#3C0642"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M82.4034 120.571L58.708 116.29L82.4034 111.854V120.571Z" fill="#4D0953"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M82.4034 111.854L58.708 116.29L58.8883 106.103L82.4034 111.854Z" fill="#4D0953"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M58.8837 106.103L58.7072 116.29L40.0916 109.004L39.9727 108.9L58.8837 106.103Z" fill="#5D0C65"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M40.0926 109.004L58.7082 116.289V121.266L38.251 112.894L39.9736 108.9L40.0926 109.004Z" fill="#4D0953"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M39.9727 108.9L38.2501 112.894L23.2256 99.0053L25.2207 95.9321L39.9727 108.9Z" fill="#5D0C65"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M39.9766 108.9L25.2207 95.932L41.8834 83.9424L39.9766 108.9Z" fill="#7C2183"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M39.9727 108.9L41.8795 83.9424L58.7072 87.8827L58.8837 106.103L39.9727 108.9Z" fill="#70137A"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M58.8883 106.103L58.708 87.8827H58.7732L82.4034 98.7789V111.854L58.8883 106.103Z" fill="#7C2183"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M82.4034 98.7789L74.0701 96.0471L58.7732 87.8827L58.708 87.852L65.6908 83.9424L82.4034 90.9981V98.7789Z" fill="#8E4096"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M82.4027 90.998L76.5709 89.87L65.69 83.9424L65.1299 81.9012L65.2105 81.9089L76.8778 87.5719L82.4027 88.2778V90.998Z" fill="#3C0642"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M65.69 83.9425L49.2383 81.2223L49.1846 80.0483L49.2229 80.0444L65.1298 81.9014L65.69 83.9425Z" fill="#3F0645"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M49.2383 81.2224L65.69 83.9426L58.7072 87.8522L49.2383 81.2224Z" fill="#813489"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M49.2387 81.2224L58.7077 87.8522V87.8829L41.8838 83.9426L49.2387 81.2224Z" fill="#8E4096"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M49.2389 81.2224L41.884 83.9426L32.6836 81.6828L49.2389 81.2224Z" fill="#8E4096"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M49.2389 81.2221L32.6836 81.6825L32.9407 80.7962L49.1852 80.0481L49.2389 81.2221Z" fill="#4D0953"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M32.6831 81.6826L41.8834 83.9424L25.2207 95.9321L32.6831 81.6826Z" fill="#8E4096"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M32.6833 81.6825L25.2209 95.9319L22.1094 80.9075L32.6833 81.6825Z" fill="#701379"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M22.1095 80.9077L25.221 95.9322L10.373 80.0444L22.1095 80.9077Z" fill="#5D0C65"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M22.1095 80.9077L10.373 80.0444H10.5802H18.5759L22.4356 80.2478L22.1095 80.9077Z" fill="#5D0C65"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M22.1094 80.9076L22.4355 80.2477L32.9403 80.7963L32.6833 81.6826L22.1094 80.9076Z" fill="#4C0A53"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M10.373 80.0444L25.221 95.9322L23.226 99.0054L10.9063 81.0995L10.373 80.0444Z" fill="#4C0953"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M139.336 80.4204L142.463 80.2439L151.755 81.2376L154.433 80.0444L150.125 77.4316L145.348 75.0414L140 75.4212L139.336 80.4204Z" fill="#4B0A52"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M139.335 80.4204L138.073 69.5319L116.027 67.345L139.335 80.4204Z" fill="#7789A4"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M139.336 80.4204L116.028 67.345L105.454 80.547L121.929 80.5086L139.336 80.4204Z" fill="#7789A4"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M116.028 67.3448L138.073 69.5317L127.473 56.2146L90.3066 62.8751L116.028 67.3448Z" fill="#7789A4"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M127.473 56.2146L138.073 69.5317L142.194 71.1355L154.226 80.0481L142.812 70.2185L131.524 58.0217L127.473 56.2146Z" fill="#7789A4"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M127.473 56.2145L131.524 58.0216L118.537 50.3137H111.7L127.473 56.2145Z" fill="#7789A4"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M127.473 56.2145L111.7 50.3137L93.9863 52.7654L127.473 56.2145Z" fill="#7789A4"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M127.473 56.2148L93.986 52.7656L90.3066 62.8791L127.473 56.2148Z" fill="#7789A4"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M116.028 67.345L90.3069 62.8752L82.4033 66.4242L105.454 80.547L116.028 67.345Z" fill="#7789A4"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M105.454 80.5472L82.4033 66.4243V75.4444H84.9586L105.454 80.5472Z" fill="#7789A4"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M105.454 80.5471L84.9586 75.4443H82.4033V85.9338L105.454 80.5471Z" fill="#7789A4"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M82.4033 66.4242L90.3069 62.8753L93.9863 52.7656L82.4033 57.926V66.4242Z" fill="#7789A4"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M82.4033 57.9257L93.9863 52.7654L99.8257 47.6012L82.4033 57.9257Z" fill="#7789A4"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M82.4033 57.9259L99.8257 47.6013L101.571 45.353L82.4033 54.1045V57.9259Z" fill="#7789A4"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M99.8252 47.6013L111.7 50.3139H118.537L101.571 45.353L99.8252 47.6013Z" fill="#7789A4"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M111.7 50.3137L99.8258 47.6012L93.9863 52.7654L111.7 50.3137Z" fill="#7789A4"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M139.336 80.4204L148.82 79.4535L154.429 80.0482L142.194 71.1356L138.073 69.5319L139.336 80.4204Z" fill="#570D5E"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M139.335 80.4204L138.073 69.5319L116.027 67.345L139.335 80.4204Z" fill="#520758"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M139.336 80.4204L116.028 67.345L105.454 80.547L121.929 80.5086L139.336 80.4204Z" fill="#5D0C65"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M116.028 67.3448L138.073 69.5317L127.473 56.2146L90.3066 62.8751L116.028 67.3448Z" fill="#5D0C65"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M127.473 56.2146L138.073 69.5317L142.194 71.1355L154.226 80.0481L142.812 70.2185L131.524 58.0217L127.473 56.2146Z" fill="#6C1274"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M127.473 56.2145L131.524 58.0216L118.537 50.3137H111.7L127.473 56.2145Z" fill="#904196"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M127.473 56.2145L111.7 50.3137L93.9863 52.7654L127.473 56.2145Z" fill="#7C2183"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M127.473 56.2148L93.986 52.7656L90.3066 62.8791L127.473 56.2148Z" fill="#70137A"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M116.028 67.345L90.3069 62.8752L82.4033 66.4242L105.454 80.547L116.028 67.345Z" fill="#590A60"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M105.454 80.5472L82.4033 66.4243V75.4444H84.9586L105.454 80.5472Z" fill="#520758"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M105.454 80.5471L84.9586 75.4443H82.4033V85.9338L105.454 80.5471Z" fill="#4B0A52"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M82.4033 66.4242L90.3069 62.8753L93.9863 52.7656L82.4033 57.926V66.4242Z" fill="#76197E"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M82.4033 57.9257L93.9863 52.7654L99.8257 47.6012L82.4033 57.9257Z" fill="#94499A"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M82.4033 57.9259L99.8257 47.6013L101.571 45.353L82.4033 54.1045V57.9259Z" fill="#AB6DB1"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M99.8252 47.6013L111.7 50.3139H118.537L101.571 45.353L99.8252 47.6013Z" fill="#A564AA"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M111.7 50.3137L99.8258 47.6012L93.9863 52.7654L111.7 50.3137Z" fill="#83308B"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M25.4704 80.4204L22.3435 80.2439L13.0511 81.2376L10.373 80.0444L14.6816 77.4316L19.4583 75.0414L24.8067 75.4212L25.4704 80.4204Z" fill="#4B0A52"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M25.4707 80.4204L26.733 69.5319L48.7747 67.345L25.4707 80.4204Z" fill="#7789A4"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M25.4707 80.4204L48.7786 67.345L59.3487 80.547L44.1285 80.5086L25.4707 80.4204Z" fill="#7789A4"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M48.7742 67.3448L26.7324 69.5317L37.3332 56.2146L74.4992 62.8751L48.7742 67.3448Z" fill="#7789A4"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M37.3333 56.2146L26.7325 69.5317L22.6081 71.1355L10.5801 80.0481L22.4009 70.3605L33.2818 58.0217L37.3333 56.2146Z" fill="#7789A4"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M37.3338 56.2145L33.2822 58.0216L46.2656 50.3137H53.1064L37.3338 56.2145Z" fill="#7789A4"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M37.334 56.2145L53.1066 50.3137L70.8206 52.7654L37.334 56.2145Z" fill="#7789A4"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M37.334 56.2148L70.8206 52.7656L74.5 62.8791L37.334 56.2148Z" fill="#7789A4"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M48.7744 67.345L74.4994 62.8752L82.403 66.4242L59.3483 80.547L48.7744 67.345Z" fill="#7789A4"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M59.3486 80.5472L82.4033 66.4243V75.4444H79.8481L59.3486 80.5472Z" fill="#7789A4"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M59.3486 80.5471L79.8481 75.4443H82.4033V85.9338L59.3486 80.5471Z" fill="#7789A4"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M82.4033 66.4242L74.4997 62.8753L70.8203 52.7656L82.4033 57.926V66.4242Z" fill="#7789A4"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M82.4029 57.9257L70.8199 52.7654L64.9805 47.6012L82.4029 57.9257Z" fill="#7789A4"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M82.4025 57.9259L64.9801 47.6013L63.2344 45.353L82.4025 54.1045V57.9259Z" fill="#7789A4"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M64.981 47.6013L53.1026 50.3139H46.2656L63.2353 45.353L64.981 47.6013Z" fill="#7789A4"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M53.1064 50.3137L64.981 47.6012L70.8204 52.7654L53.1064 50.3137Z" fill="#7789A4"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M25.4704 80.4204L15.9861 79.4535L10.373 80.0482L22.6082 71.1356L26.7327 69.5319L25.4704 80.4204Z" fill="#570D5E"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M25.4707 80.4204L26.733 69.5319L48.7747 67.345L25.4707 80.4204Z" fill="#520758"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M25.4707 80.4204L48.7786 67.345L59.3487 80.547L44.1285 80.5086L25.4707 80.4204Z" fill="#5D0C65"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M48.7742 67.3448L26.7324 69.5317L37.3332 56.2146L74.4992 62.8751L48.7742 67.3448Z" fill="#5D0C65"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M37.3333 56.2146L26.7325 69.5317L22.6081 71.1355L10.5801 80.0481L22.4009 70.3605L33.2818 58.0217L37.3333 56.2146Z" fill="#6C1274"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M37.3338 56.2145L33.2822 58.0216L46.2656 50.3137H53.1064L37.3338 56.2145Z" fill="#904196"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M37.334 56.2145L53.1066 50.3137L70.8206 52.7654L37.334 56.2145Z" fill="#7C2183"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M37.334 56.2148L70.8206 52.7656L74.5 62.8791L37.334 56.2148Z" fill="#70137A"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M48.7744 67.345L74.4994 62.8752L82.403 66.4242L59.3483 80.547L48.7744 67.345Z" fill="#590A60"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M59.3486 80.5472L82.4033 66.4243V75.4444H79.8481L59.3486 80.5472Z" fill="#520758"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M59.3486 80.5471L79.8481 75.4443H82.4033V85.9338L59.3486 80.5471Z" fill="#590A60"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M82.4033 66.4242L74.4997 62.8753L70.8203 52.7656L82.4033 57.926V66.4242Z" fill="#76197E"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M82.4029 57.9257L70.8199 52.7654L64.9805 47.6012L82.4029 57.9257Z" fill="#94499A"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M82.4025 57.9259L64.9801 47.6013L63.2344 45.353L82.4025 54.1045V57.9259Z" fill="#AB6DB1"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M64.981 47.6013L53.1026 50.3139H46.2656L63.2353 45.353L64.981 47.6013Z" fill="#A564AA"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M53.1064 50.3137L64.981 47.6012L70.8204 52.7654L53.1064 50.3137Z" fill="#83308B"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M59.3486 80.5471L79.8481 75.4443H82.4033V85.9338L59.3486 80.5471Z" fill="#4B0A52"/>
</svg>
`;

export const ConfettiReaction = `<svg width="22" height="20" viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M3.26722 17.7079L3.6443 17.7361L15.1624 14.2916L14.4004 14.3191L12.9404 13.7221L9.98689 11.4015L8.23381 9.40929L7.33713 7.9772L7.02966 6.82468L7.1112 6.35254L3.23242 17.3783L3.26722 17.7079Z" fill="#932D95"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M7.34842 6.08435L7.11084 6.35256L7.0293 6.8247L7.33677 7.97722L8.23344 9.40931L9.98652 11.4015L12.94 13.7221L14.4 14.3191L15.1621 14.2916L15.4638 13.9689L15.558 13.3847L15.4291 12.5785L15.0201 11.449L14.3333 10.3094L13.4802 9.23012L12.4 8.10305L11.2936 7.27598L10.019 6.49666L8.76699 6.03445L7.97904 5.94629L7.34842 6.08435Z" fill="#D9A0D2"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M7.50292 6.34781L7.38283 6.48357L7.32617 6.8116L7.60603 7.86058L8.4669 9.23557L10.1868 11.1901L13.0875 13.4691L14.4521 14.0271L15.032 14.0061L15.1915 13.8356L15.2641 13.3849L15.1469 12.6519L14.757 11.5747L14.0943 10.4752L13.261 9.42124L12.207 8.32135L11.1307 7.51672L9.89194 6.75926L8.69973 6.31919L7.99447 6.24023L7.50292 6.34781Z" fill="#490149"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M6.22689 16.5484L4.22242 14.8301L4.15785 14.7464L3.71289 16.0114L5.14325 17.2765L5.15505 17.2842L6.68766 16.8258L6.22703 16.5486L6.22689 16.5484ZM8.2919 13.6274L9.99436 14.7482L11.4292 15.4077L13.5716 14.767L12.955 14.6404L11.0827 13.9576L9.37567 12.9114L8.31692 12.1892L7.37121 11.1712L6.03144 9.4209L5.54032 10.8169L6.29016 11.8354L7.45433 13.048L8.2919 13.6276V13.6274ZM4.5936 13.5443L5.8058 14.6827L6.59534 15.3148L8.221 16.3672L10.0054 15.8335L9.38603 15.6144L7.67897 14.5867L6.68392 13.8617L5.6745 12.8684L5.08631 12.1065L4.58469 13.5325L4.59346 13.5443H4.5936Z" fill="#DAB8D6"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M3.64883 17.7361L15.1628 14.2916L14.4007 14.3191L12.9407 13.7221L11.563 12.6396L9.21439 14.2344L8.15909 14.8752L6.91727 15.5227L3.26758 17.7079L3.64883 17.7361Z" fill="#640067"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M9.9956 14.7488L11.4304 15.4084L12.1788 15.1846L13.5728 14.7677L12.9563 14.641L11.084 13.9581L10.3157 13.4873L9.21484 14.2348L9.9956 14.7488Z" fill="#C88AC5"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M6.22804 16.5485L5.80695 16.1875L4.67773 16.8637L5.14441 17.2765L5.1562 17.2841L6.06337 17.0127L6.68867 16.8257L6.22804 16.5485Z" fill="#DAB8D6"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M6.91797 15.5234L8.22264 16.368L9.3533 16.0299L10.0071 15.8343L9.38781 15.6152L8.15979 14.876L6.91797 15.5234Z" fill="#DAB8D6"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M6.22804 16.5485L5.80695 16.1875L4.67773 16.8637L5.14441 17.2765L5.1562 17.2841L6.06337 17.0127L6.68867 16.8257L6.22804 16.5485Z" fill="#C88AC5"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M6.91602 15.5224L8.22069 16.3671L9.35135 16.029L10.0051 15.8334L9.38586 15.6142L8.15784 14.875L6.91602 15.5224Z" fill="#C88AC5"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M7.61797 15.9766L6.6875 16.8256L8.22112 16.367L7.61797 15.9766Z" fill="#490149"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M6.68836 16.8256L7.61883 15.9766L5.80664 16.1874L6.22773 16.5484L6.68836 16.8256Z" fill="#540054"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M5.14364 17.277L4.67696 16.8643L3.64453 17.7362H3.64511L5.15543 17.2846L5.14364 17.277Z" fill="#540054"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M8.1582 14.8749L9.38622 15.6141L10.0055 15.8333L9.99426 14.748L8.1582 14.8749Z" fill="#540054"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M9.99414 14.748L10.0054 15.8333L11.429 15.4076L9.99414 14.748Z" fill="#490149"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M11.0827 13.9581L12.955 14.641L13.5715 14.7677L12.9399 13.7226L10.3145 13.4873L11.0827 13.9581Z" fill="#540054"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M12.9395 13.7217L13.5711 14.7668L15.1615 14.2912L14.3994 14.3186L12.9395 13.7217Z" fill="#490149"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M12.1788 15.1844L13.5728 14.7675L12.9563 14.6408L11.084 13.958L11.4304 15.4082L12.1788 15.1844Z" fill="#BE78BE"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M10.0055 15.8334L9.38622 15.6142L8.1582 14.875L8.22105 16.3671L9.35171 16.029L10.0055 15.8334Z" fill="#BE78BE"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M6.68887 16.8257L6.22823 16.5485L5.80715 16.1875L5.15625 17.2841L6.06357 17.0127L6.68887 16.8257Z" fill="#BE78BE"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M8.23394 9.40929L7.33727 7.9772L7.02979 6.82468L7.11134 6.35254L3.71289 16.013L3.72454 16.0215L6.29074 11.8354L8.23394 9.40929Z" fill="#BD73BD"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M4.37544 14.961L4.223 14.8303L4.15842 14.7466L3.71289 16.013L3.7254 16.0221L4.37558 14.961H4.37544ZM5.00764 13.9284L5.66544 12.8559L5.08717 12.1066L4.58555 13.5327L4.59432 13.5444L5.00778 13.9284H5.00764ZM6.29074 11.8354L7.10343 10.8208L6.03202 9.4209L5.5409 10.8169L6.29074 11.8354Z" fill="#FFEBFE"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M7.33736 7.97754L7.10352 10.821L8.23403 9.40963L7.33736 7.97754Z" fill="#BD73BD"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M7.10266 10.821L7.3365 7.97754L6.03125 9.42128L7.10266 10.821Z" fill="#C585C5"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M6.03125 9.42093L7.3365 7.9772L7.02903 6.82468L7.11057 6.35254L6.03125 9.42093Z" fill="#D9A0D2"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M5.08789 12.1071L6.29146 11.8359L5.54162 10.8174L5.08789 12.1071Z" fill="#C585C5"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M4.67702 16.8643L3.23242 17.3787L3.26722 17.7083L4.67702 16.8643Z" fill="#6C026D"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M5.82606 14.6982L5.80664 16.1877L6.91731 15.5227L6.59632 15.3149L5.82606 14.6982Z" fill="#6C026D"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M4.37543 14.9605L5.82564 14.6978L5.80637 14.6824L5.00605 13.9307L4.375 14.9601L4.37543 14.9605Z" fill="#891B8B"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M7.30273 14.3119L7.6801 14.5869L8.15928 14.8752L9.21459 14.2344L7.30273 14.3119Z" fill="#6C026D"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M7.30273 14.3117L9.21459 14.2342L8.29289 13.6274L7.45532 13.0479L7.30273 14.3117Z" fill="#891B8B"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M6.36091 13.5425L7.45518 13.0476L6.29102 11.835L6.36091 13.5425Z" fill="#891B8B"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M7.45575 13.0479L6.36133 13.5427L6.68548 13.8617L7.30316 14.3117L7.45575 13.0479Z" fill="#932D95"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M7.37187 11.1712L8.31759 12.1891L8.23403 9.40918L7.10352 10.8206L7.37187 11.1712Z" fill="#891B8B"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M8.23438 9.40918L8.31793 12.1891L9.98746 11.4014L8.23438 9.40918Z" fill="#932D95"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M9.98593 11.4014L8.31641 12.189L9.37516 12.9113L10.314 13.4867L9.98593 11.4014Z" fill="#891B8B"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M9.98633 11.4014L10.3144 13.4867L11.5621 12.6395L9.98633 11.4014Z" fill="#6C026D"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M9.37642 12.9117L8.31767 12.1895L7.45508 13.0483L8.29265 13.6279L9.21434 14.2346L10.3152 13.4871L9.37642 12.9117Z" fill="#D0A6CC"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M7.3027 14.3115L5.82617 14.6978L6.59643 15.3145L6.91743 15.5223L8.15925 14.8748L7.68007 14.5865L7.3027 14.3115Z" fill="#D0A6CC"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M10.7578 9.83618L11.0206 10.4252L11.4122 10.933L12.0052 10.6893L12.5276 10.3108L12.8845 9.76671L12.941 9.20024L12.6341 8.84487L12.2651 8.84257L11.9087 9.08087L11.7195 8.69617L11.3988 8.51367L10.9553 8.66784L10.7207 9.18657L10.7578 9.83618Z" fill="#DAB8D6"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M14.726 7.85906L14.6035 8.72007L14.6862 9.58064L15.5382 9.72791L16.4059 9.67053L17.2038 9.30567L17.6746 8.6995L17.5793 8.07363L17.1598 7.80614L16.5821 7.82253L16.6419 7.24771L16.4068 6.80922L15.7898 6.66699L15.1499 7.09095L14.726 7.85906Z" fill="#891B8B"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M11.9736 4.69677L12.6175 5.66477L13.4657 6.44942L14.4463 5.83836L15.2609 5.00884L15.732 3.93513L15.6623 2.91147L15.0102 2.37217L14.3539 2.4786L13.7923 3.00898L13.3406 2.38238L12.7158 2.1543L11.9741 2.56143L11.7129 3.5536L11.9736 4.69677Z" fill="#A147A0"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M17.0469 3.94879L17.2415 4.68627L17.6017 5.35327L18.3403 5.1817L19.0219 4.83943L19.542 4.27252L19.7156 3.62219L19.4253 3.14905L18.995 3.07599L18.5339 3.28625L18.3862 2.80145L18.0467 2.52734L17.4997 2.62298L17.1273 3.18356L17.0469 3.94879Z" fill="#640067"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M8.91797 6.97471L9.19798 7.24307L9.53119 7.43707L9.80616 7.16685L10.0111 6.83751L10.0865 6.45368L9.98952 6.12535L9.738 5.99707L9.53191 6.07948L9.38767 6.29304L9.19496 6.12176L8.97492 6.09285L8.76294 6.27923L8.75 6.62136L8.91797 6.97471Z" fill="#BD73BD"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M4.53742 8.00662L4.9936 8.26519L5.4948 8.40863L5.7653 7.96306L5.92436 7.46332L5.89548 6.93523L5.65927 6.53719L5.28698 6.45271L5.0443 6.62923L4.92628 6.95708L4.61678 6.79669L4.3188 6.83198L4.10298 7.14672L4.19978 7.5994L4.53742 8.00662Z" fill="#BD73BD"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M14.7943 10.9217L14.5877 11.25L14.4648 11.6154L14.785 11.8304L15.1487 11.9649L15.54 11.9614L15.8419 11.8002L15.9169 11.528L15.7948 11.3429L15.5565 11.2444L15.6855 11.0213L15.6696 10.8L15.4442 10.6299L15.1065 10.6861L14.7943 10.9217Z" fill="#BD73BD"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M14.9439 15.3396L14.9026 15.8458L14.9812 16.3444L15.483 16.4002L15.9866 16.3363L16.4388 16.0954L16.6917 15.7256L16.6141 15.3641L16.3603 15.2231L16.024 15.253L16.0388 14.9159L15.8864 14.6686L15.5218 14.6075L15.1638 14.8771L14.9439 15.3396Z" fill="#891B8B"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M10.3536 4.05086L10.1158 4.55177L9.76888 4.98018L9.26424 4.75871L8.82302 4.42277L8.52734 3.94804L8.49023 3.46008L8.76118 3.16095L9.07843 3.16627L9.37972 3.3784L9.55014 3.05165L9.82942 2.90137L10.2075 3.04288L10.3985 3.4933L10.3536 4.05086Z" fill="#640067"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M18.4227 6.55574L18.3167 6.91757L18.2988 7.29178L18.6552 7.40769L19.031 7.43688L19.3958 7.32974L19.6353 7.09892L19.6333 6.82453L19.4698 6.68373L19.221 6.65512L19.2822 6.41222L19.2086 6.20958L18.9527 6.11035L18.652 6.25258L18.4227 6.55574Z" fill="#BD73BD"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M17.5859 12.9436L17.7342 13.5056L18.0087 14.014L18.5716 13.8832L19.0909 13.6222L19.4874 13.1903L19.6197 12.6946L19.3984 12.3341L19.0705 12.2784L18.7192 12.4388L18.6066 12.0692L18.3479 11.8604L17.9311 11.9331L17.6472 12.3605L17.5859 12.9436Z" fill="#DAB8D6"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M10.5355 17.0523L10.7096 17.3143L10.9391 17.5266L11.2045 17.3612L11.4249 17.1368L11.5524 16.8462L11.5335 16.5692L11.3571 16.4232L11.1795 16.4521L11.0276 16.5957L10.9052 16.4261L10.7362 16.3643L10.5355 16.4746L10.4648 16.7429L10.5355 17.0523Z" fill="#BD73BD"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M3.69008 11.9407L3.99079 12.0332L4.30287 12.0522L4.40354 11.7561L4.43187 11.4428L4.34659 11.1372L4.15661 10.9347L3.92751 10.9336L3.80829 11.0683L3.78169 11.2757L3.57963 11.2219L3.40979 11.2812L3.32422 11.4936L3.43956 11.746L3.69008 11.9407Z" fill="#DAB8D6"/>
</svg>
`;

export const DaddyCrownReaction = `<svg width="146" height="135" viewBox="0 0 146 135" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M131.484 92.6863L135.871 82.9463L134.911 71.8895L123.437 64.0341L93.7498 54.0026L72.1187 50.2684L50.4826 54.0026L20.8001 64.0341L9.32112 71.8895L8.36621 82.9463L12.7537 92.6863H72.1187H131.484Z" fill="#C88AC5"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M9.32014 71.8895L8.36523 82.9463L12.7528 92.6864H72.1177H74.7311L75.8569 90.7464L73.8014 83.072H53.2759L24.2418 80.5038L9.32014 71.8895Z" fill="#BD73BD"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M134.91 71.8895L135.87 82.9463L131.482 92.6864H72.1171H69.4987L68.3779 90.7464L70.4335 83.072H90.9539L119.993 80.5038L134.91 71.8895Z" fill="#BD73BD"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M135.548 98.9637L141.227 87.5903L144.56 73.1964L143.74 55.9679L136.553 44.8055L124.562 40.3476L114.219 40.7547L106.494 42.956L94.6029 36.8497L83.1139 34.3066L76.8467 33.7286V42.2775L83.3903 42.8505L93.0147 45.3081L102.75 50.7762L107.886 59.5161L109.092 69.8492L107.931 81.9564L105.765 91.3898L103.358 98.3054H112.329L114.782 90.1987L116.676 80.6899L117.531 69.7637L116.36 58.6768L114.224 52.3895L112.952 50.0022L119.526 48.8815L128.025 49.6353L134.231 55.2994L136.136 65.1802L135.121 74.9001L132.106 85.6252L127.386 96.3101L125.899 98.3104L135.548 98.9637Z" fill="#932D95"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M113.27 49.1731L119.492 48.1127L128.348 48.8967L134.937 54.9076L136.912 65.1452L135.871 75.046L132.826 85.8867L131.499 88.8922L129.539 92.9229H137.691L138.093 92.1589L140.505 87.3291L143.792 73.1262L142.988 56.2093L136.047 45.4289L124.443 41.1168L114.341 41.5138L108.013 43.3181L107.099 43.5141L108.536 44.6147L111.506 47.8463L112.436 49.344L113.27 49.1731Z" fill="#7A007C"/>
<path d="M72.1133 94.4706V23.6015C72.1133 23.6015 80.1295 23.2849 80.9838 27.9388C81.8382 32.5977 80.9838 94.4655 80.9838 94.4655H72.1133V94.4706Z" fill="#932D95"/>
<path d="M72.9473 94.4706V23.6015C72.9473 23.6015 79.5261 23.2849 80.2247 27.9388C80.9232 32.5977 80.2247 94.4655 80.2247 94.4655H72.9473V94.4706Z" fill="#7A007C"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M102.75 50.776L105.721 55.832L107.887 59.5159L109.093 69.849L108.188 79.2774L107.932 81.9562L106.163 89.6558H114.888L116.677 80.6897L116.928 77.4279L117.531 69.7636L116.431 59.3451L116.36 58.6766L114.224 52.3893L112.953 50.0021L112.435 49.3437L111.505 47.846L108.535 44.6144L107.098 43.5137L106.369 42.8956L94.6035 36.8495L83.1144 34.3064L81.275 34.1406L81.2549 34.1356V42.6644L81.3554 42.6744L83.3909 42.8503L93.0153 45.308L102.75 50.776Z" fill="#932D95"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M107.635 88.4848L107.384 89.6558H113.686L113.928 88.4848L115.511 80.5289L116.355 69.7787L115.204 58.933L113.144 52.8568L110.57 48.5547L107.741 45.4839L105.716 43.8756L94.2014 37.9603L82.9335 35.4675L81.2549 35.2212L81.3504 41.4733L83.5869 41.6944L93.4576 44.2124L103.6 49.9066L109.017 59.134L110.274 69.839L109.093 82.1472L107.635 88.4848Z" fill="#7A007C"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M115.556 79.9459L107.384 89.6558H113.686L113.927 88.4848L115.511 80.5289L115.556 79.9459Z" fill="#640067"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M107.384 89.6557L115.556 79.9458L114.556 78.026L110.274 69.8389L109.384 79.0764L109.093 82.1472L107.635 88.4847L107.384 89.6557Z" fill="#7A007C"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M110.273 69.839L114.555 78.0261L115.556 79.9459L115.731 77.7396L116.355 69.7787L115.204 58.933L110.273 69.839Z" fill="#640067"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M110.274 69.839L115.204 58.933L109.018 59.134L110.274 69.839Z" fill="#7A007C"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M109.018 59.1342L115.204 58.9331L115.194 58.8979L113.144 52.8569L110.571 48.5548L109.344 56.9278L109.018 59.1342Z" fill="#640067"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M109.344 56.9278L110.57 48.5548L103.6 49.9068L107.364 56.3197L109.017 59.1342L109.344 56.9278Z" fill="#7A007C"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M103.6 49.9066L110.571 48.5546L107.741 45.4839L105.716 43.8756L100.846 41.3727L103.6 49.9066Z" fill="#640067"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M103.599 49.9066L100.845 41.3727L93.457 44.2123L103.599 49.9066Z" fill="#7A007C"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M93.457 44.2127L100.845 41.3731L94.2008 37.9606L89.4766 36.9152L93.457 44.2127Z" fill="#640067"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M93.4578 44.2127L89.4773 36.9152L81.3506 41.4736L83.5871 41.6948L93.4578 44.2127Z" fill="#7A007C"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M81.3504 41.4734L89.4771 36.915L82.9335 35.4676L81.2951 35.2263L81.2549 35.2213L81.3504 41.4734Z" fill="#640067"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M140.504 87.3295L132.186 89.3046L131.181 89.5459L131.126 89.6564H131.483V90.8224V90.8274V92.9232H137.69L138.092 92.1593L140.504 87.3295Z" fill="#640067"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M132.187 89.3044L140.505 87.3292L135.871 75.0461V75.0512L132.825 85.8868L131.498 88.8923L131.182 89.5456L132.187 89.3044Z" fill="#7A007C"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M135.87 75.0459L140.504 87.329L143.791 73.1261L143.565 68.3716L135.87 75.0459Z" fill="#640067"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M135.87 75.046L143.565 68.3717L136.91 65.1451L135.87 75.046Z" fill="#7A007C"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M136.911 65.1454L143.565 68.372L142.987 56.2095L136.911 65.1454Z" fill="#640067"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M136.912 65.1452L142.988 56.2093L134.937 54.9077L136.912 65.1452Z" fill="#7A007C"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M134.937 54.9077L142.988 56.2094L136.047 45.429L134.937 54.9077Z" fill="#640067"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M134.936 54.9077L136.046 45.429L128.347 48.8968L134.936 54.9077Z" fill="#7A007C"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M128.346 48.8968L136.046 45.429L124.441 41.1169L128.346 48.8968Z" fill="#640067"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M128.347 48.8968L124.442 41.1169L119.491 48.1128L128.347 48.8968Z" fill="#7A007C"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M119.491 48.1128L124.442 41.1169L114.34 41.5139L119.491 48.1128Z" fill="#640067"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M119.491 48.1129L114.34 41.514L112.38 49.2538L112.435 49.3443L113.269 49.1734L119.491 48.1129Z" fill="#7A007C"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M112.38 49.2538L114.34 41.514L108.012 43.3183L107.098 43.5143L108.535 44.615L111.505 47.8466L112.38 49.2538Z" fill="#640067"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M79.8578 89.6562L72.9473 87.3041V89.6562H79.8578Z" fill="#7A007C"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M72.9473 87.3037L79.8578 89.6558H80.2749C80.3 87.3791 80.3252 84.4139 80.3553 81.0114L72.9473 87.2987V87.3037Z" fill="#640067"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M72.9473 87.3041L80.3553 81.0118L72.9473 74.7747V87.3041Z" fill="#7A007C"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M72.9473 74.7744L80.3553 81.0165C80.3905 76.7696 80.4307 71.8393 80.4659 66.7331L72.9473 74.7744Z" fill="#640067"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M72.9473 74.7747L80.4609 66.7284L72.9473 59.3655V74.7747Z" fill="#7A007C"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M72.9473 59.3656L80.4659 66.7284C80.496 62.2555 80.5162 57.6468 80.5312 53.2341L72.9473 59.3656Z" fill="#640067"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M72.9473 59.3657L80.5262 53.2342L74.2238 50.2991L72.9473 49.7061V50.274V59.3657Z" fill="#7A007C"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M74.2238 50.2988L80.5262 53.2288C80.5262 52.3845 80.5312 51.5502 80.5312 50.721C80.5363 48.0925 80.5363 45.5544 80.5312 43.1873L72.9473 49.7007L74.2238 50.2938V50.2988Z" fill="#640067"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M72.9473 49.706L80.5262 43.1926L79.707 42.5291L76.8473 40.2072L72.9473 37.041V49.706Z" fill="#7A007C"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M76.8474 40.207L79.7071 42.5289L80.5263 43.1923C80.5263 42.9913 80.5263 42.7953 80.5263 42.5993C80.5163 39.3476 80.4962 36.4326 80.456 34.0604C80.4359 32.5979 80.4057 31.3414 80.3705 30.3413L72.9424 37.0357L76.8424 40.202L76.8474 40.207Z" fill="#640067"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M72.9473 37.0406L80.3754 30.3462L72.9473 25.3304V37.0406Z" fill="#7A007C"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M72.9473 25.3306L80.3754 30.3413C80.3352 29.1653 80.285 28.341 80.2247 27.939C79.903 25.798 78.34 24.7074 76.7418 24.1545C74.8671 23.5062 72.9473 23.5967 72.9473 23.5967V25.3255V25.3306Z" fill="#640067"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M9.51129 98.9636L3.83212 87.5901L0.5 73.1962L1.31921 55.9677L8.50613 44.8054L20.4927 40.3475L30.8409 40.7545L38.5655 42.9559L50.4566 36.8495L61.9406 34.3064L68.2128 33.7285V42.2774L61.6692 42.8503L52.0397 45.3079L42.3098 50.776L37.1734 59.5159L35.9622 69.849L37.1282 81.9562L39.2943 91.3896L41.7016 98.3052H32.7255L30.278 90.1985L28.3832 80.6897L27.5288 69.7636L28.6999 58.6766L30.8358 52.3893L32.1074 50.002L25.5336 48.8813L17.0349 49.6352L10.823 55.2993L8.92327 65.18L9.93848 74.8999L12.954 85.625L17.6732 96.3099L19.1609 98.3102L9.51129 98.9636Z" fill="#932D95"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M31.7853 49.173L25.5633 48.1125L16.7079 48.8965L10.124 54.9074L8.1489 65.145L9.18924 75.0459L12.2349 85.8865L13.5617 88.892L15.5218 92.9227H7.3699L6.9628 92.1588L4.55544 87.329L1.26855 73.126L2.07269 56.2091L9.01334 45.4287L20.6179 41.1166L30.7198 41.5136L37.0473 43.3179L37.962 43.5139L36.5247 44.6145L33.5544 47.8461L32.6246 49.3438L31.7853 49.173Z" fill="#7A007C"/>
<path d="M72.9466 94.4704V23.6013C72.9466 23.6013 64.9304 23.2847 64.076 27.9386C63.2216 32.5976 64.076 94.4654 64.076 94.4654H72.9466V94.4704Z" fill="#932D95"/>
<path d="M72.1123 94.4704V23.6013C72.1123 23.6013 65.5335 23.2847 64.8349 27.9386C64.1363 32.5976 64.8349 94.4654 64.8349 94.4654H72.1123V94.4704Z" fill="#7A007C"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M42.3092 50.7759L39.339 55.8318L37.1729 59.5157L35.9616 69.8488L36.8713 79.2773L37.1276 81.956L38.8967 89.6556H30.1719L28.3827 80.6895L28.1264 77.4278L27.5283 69.7634L28.629 59.3449L28.6993 58.6764L30.8353 52.3891L32.1068 50.0019L32.6245 49.3435L33.5543 47.8458L36.5245 44.6142L37.9619 43.5136L38.6907 42.8954L50.4561 36.8493L61.9401 34.3063L63.7846 34.1404L63.8047 34.1354V42.6642L63.7042 42.6742L61.6687 42.8501L52.0392 45.3078L42.3092 50.7759Z" fill="#932D95"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M37.4249 88.4846L37.6762 89.6556H31.3738L31.1325 88.4846L29.5444 80.5287L28.7051 69.7785L29.851 58.9328L31.9115 52.8566L34.4898 48.5545L37.3193 45.4837L39.3447 43.8755L50.8589 37.9601L62.1217 35.4673L63.8054 35.221L63.7099 41.4731L61.4734 41.6943L51.6027 44.2122L41.4556 49.9064L36.0377 59.1338L34.7863 69.8388L35.9674 82.1471L37.4249 88.4846Z" fill="#7A007C"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M29.5 79.9457L37.677 89.6556H31.3746L31.1334 88.4846L29.5452 80.5287L29.5 79.9457Z" fill="#640067"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M37.677 89.6555L29.5 79.9457L30.5052 78.0258L34.7872 69.8387L35.6717 79.0762L35.9682 82.147L37.4257 88.4845L37.677 89.6555Z" fill="#7A007C"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M34.7863 69.8389L30.5043 78.0259L29.4992 79.9458L29.3283 77.7395L28.7051 69.7786L29.851 58.9329L34.7863 69.8389Z" fill="#640067"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M34.7859 69.8388L29.8506 58.9328L36.0374 59.1338L34.7859 69.8388Z" fill="#7A007C"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M36.0374 59.134L29.8506 58.9329L29.8657 58.8978L31.9112 52.8567L34.4894 48.5546L35.7157 56.9276L36.0374 59.134Z" fill="#640067"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M35.7165 56.9276L34.4902 48.5546L41.456 49.9066L37.6917 56.3195L36.0382 59.134L35.7165 56.9276Z" fill="#7A007C"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M41.456 49.9064L34.4902 48.5545L37.3198 45.4837L39.3452 43.8754L44.2102 41.3726L41.456 49.9064Z" fill="#640067"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M41.4561 49.9064L44.2102 41.3726L51.6032 44.2121L41.4561 49.9064Z" fill="#7A007C"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M51.6029 44.2125L44.21 41.3729L50.8591 37.9604L55.5834 36.915L51.6029 44.2125Z" fill="#640067"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M51.6035 44.2125L55.584 36.915L63.7107 41.4735L61.4742 41.6946L51.6035 44.2125Z" fill="#7A007C"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M63.7098 41.4732L55.583 36.9148L62.1216 35.4674L63.765 35.2262L63.8052 35.2211L63.7098 41.4732Z" fill="#640067"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M4.55566 87.3293L12.8734 89.3045L13.8786 89.5457L13.9338 89.6563H13.572V90.8223V90.8273V92.9231H7.37012L6.96303 92.1592L4.55566 87.3293Z" fill="#640067"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M12.8734 89.3042L4.55566 87.3291L9.18947 75.046V75.051L12.2351 85.8866L13.5619 88.8921L13.8786 89.5454L12.8734 89.3042Z" fill="#7A007C"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M9.18924 75.0458L4.55544 87.3289L1.26855 73.1259L1.49472 68.3715L9.18924 75.0458Z" fill="#640067"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M9.18964 75.0459L1.49512 68.3716L8.1493 65.145L9.18964 75.0459Z" fill="#7A007C"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M8.1493 65.1453L1.49512 68.3719L2.07309 56.2094L8.1493 65.1453Z" fill="#640067"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M8.14945 65.1451L2.07324 56.2092L10.1246 54.9075L8.14945 65.1451Z" fill="#7A007C"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M10.1246 54.9075L2.07324 56.2092L9.0139 45.4288L10.1246 54.9075Z" fill="#640067"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M10.1244 54.9075L9.01367 45.4288L16.7082 48.8966L10.1244 54.9075Z" fill="#7A007C"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M16.7082 48.8967L9.01367 45.4289L20.6183 41.1168L16.7082 48.8967Z" fill="#640067"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M16.708 48.8967L20.6181 41.1168L25.5635 48.1127L16.708 48.8967Z" fill="#7A007C"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M25.5636 48.1127L20.6182 41.1168L30.7201 41.5138L25.5636 48.1127Z" fill="#640067"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M25.5635 48.1128L30.72 41.5139L32.68 49.2537L32.6247 49.3441L31.7854 49.1733L25.5635 48.1128Z" fill="#7A007C"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M32.6808 49.2537L30.7207 41.5139L37.0482 43.3182L37.9629 43.5142L36.5255 44.6148L33.5553 47.8464L32.6808 49.2537Z" fill="#640067"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M65.2031 89.656L72.1136 87.304V89.656H65.2031Z" fill="#7A007C"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M72.1122 87.3035L65.2017 89.6556H64.7845C64.7594 87.3789 64.7343 84.4137 64.7041 81.0112L72.1122 87.2985V87.3035Z" fill="#640067"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M72.1122 87.3039L64.7041 81.0116L72.1122 74.7745V87.3039Z" fill="#7A007C"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M72.1134 74.7742L64.7053 81.0163C64.6701 76.7695 64.6299 71.8391 64.5947 66.7329L72.1134 74.7742Z" fill="#640067"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M72.1134 74.7745L64.5947 66.7282L72.1134 59.3654V74.7745Z" fill="#7A007C"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M72.1123 59.3654L64.5937 66.7283C64.5635 62.2553 64.5434 57.6466 64.5283 53.2339L72.1123 59.3654Z" fill="#640067"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M72.1123 59.3655L64.5283 53.234L70.8357 50.2989L72.1123 49.7059V50.2738V59.3655Z" fill="#7A007C"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M70.8356 50.2986L64.5332 53.2287C64.5332 52.3843 64.5282 51.55 64.5282 50.7208C64.5232 48.0923 64.5232 45.5542 64.5282 43.1871L72.1121 49.7005L70.8356 50.2936V50.2986Z" fill="#640067"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M72.1123 49.7058L64.5283 43.1924L65.3526 42.529L68.2122 40.207L72.1123 37.0408V49.7058Z" fill="#7A007C"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M68.2131 40.2068L65.3534 42.5287L64.5342 43.1921C64.5342 42.9911 64.5342 42.7951 64.5342 42.5991C64.5442 39.3474 64.5643 36.4324 64.6045 34.0602C64.6246 32.5977 64.6548 31.3413 64.69 30.3411L72.1181 37.0355L68.2181 40.2018L68.2131 40.2068Z" fill="#640067"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M72.1127 37.0404L64.6846 30.346L72.1127 25.3303V37.0404Z" fill="#7A007C"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M72.1127 25.3304L64.6846 30.3411C64.7248 29.1651 64.775 28.3408 64.8353 27.9388C65.157 25.7978 66.72 24.7072 68.3182 24.1543C70.1929 23.506 72.1127 23.5965 72.1127 23.5965V25.3254V25.3304Z" fill="#640067"/>
<path d="M12.4111 89.656H131.483V123.982H12.4111V89.656Z" fill="#640067"/>
<path d="M7.09375 94.4706H137.333V119.168H7.09375V94.4706Z" fill="#F7B235"/>
<path d="M137.333 117.011V121.324L82.6621 119.168L137.333 117.011Z" fill="#C88AC5"/>
<path d="M7.14941 117.011V121.324L61.8152 119.168L7.14941 117.011Z" fill="#C88AC5"/>
<path d="M7.14941 94.4709H137.383V119.168H7.14941V94.4709Z" fill="#922C94"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M137.182 119.168L118.692 108.453V106.221L137.182 94.4711V119.168Z" fill="#640067"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M82.6631 119.168L101.148 108.453V106.221L82.6631 94.4711V119.168Z" fill="#932D95"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M82.663 94.4711L72.1641 99.3411V94.4711H82.663Z" fill="#C88AC5"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M82.6629 119.168L72.0635 114.298V119.168H82.6629Z" fill="#7A007C"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M75.9139 107.141L82.6636 119.168L72.0742 114.298L73.9941 112.665L75.9139 107.141Z" fill="#932D95"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M82.663 94.4711L75.9133 107.141L74.1141 101.628L72.1641 99.3411L82.663 94.4711Z" fill="#BD73BD"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M75.9141 107.141L82.6637 94.4711V119.168L75.9141 107.141Z" fill="#7A007C"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M122.312 119.168L117.23 109.915L118.693 108.453L137.183 119.168H122.312Z" fill="#7A007C"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M102.611 109.915L97.5345 119.168H82.6631L101.148 108.453L102.611 109.915Z" fill="#7A007C"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M97.5342 119.168L102.61 109.915H117.23L122.311 119.168H97.5342Z" fill="#640067"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M117.23 104.759L122.975 94.4711H137.183L118.693 106.221L117.23 104.759Z" fill="#932D95"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M122.975 94.4711L117.231 104.759H102.611L96.8711 94.4711H122.975Z" fill="#AC49AE"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M96.8711 94.4711L102.611 104.759L101.148 106.221L82.6631 94.4711H96.8711Z" fill="#7A007C"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M74.0882 112.665L76.1186 107.141L74.2189 101.628L72.1633 99.3411L70.2133 101.628L68.4141 107.141L70.339 112.665L72.2638 114.298L72.3694 114.207L74.0882 112.665Z" fill="#FCEB89"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M68.4141 107.141H70.5701L72.103 108.84L72.2538 114.298L70.3339 112.665L68.4141 107.141Z" fill="#E39507"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M76.0936 107.141H73.9325L72.4047 108.84L72.2539 114.298L74.1687 112.665L76.0936 107.141Z" fill="#FCA608"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M70.5701 107.141L72.0578 104.593L72.1633 99.3411L70.2133 101.628L68.4141 107.141H70.5701Z" fill="#FFD266"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M73.9573 107.141L72.4697 104.593L72.1631 99.3413L74.3141 101.628L76.1184 107.141H73.9573Z" fill="#FCEB89"/>
<path d="M117.43 104.759H102.815C102.007 104.759 101.353 105.414 101.353 106.221V108.448C101.353 109.256 102.007 109.91 102.815 109.91H117.43C118.238 109.91 118.893 109.256 118.893 108.448V106.221C118.893 105.414 118.238 104.759 117.43 104.759Z" fill="#FCEB89"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M118.893 106.222V108.453L117.43 109.916H102.81L101.353 108.453L102.634 107.92L103.343 108.634H116.902L117.611 107.92V106.754L118.893 106.222Z" fill="#E39507"/>
<path d="M41.7114 104.759H27.0963C26.2886 104.759 25.6338 105.414 25.6338 106.221V108.448C25.6338 109.256 26.2886 109.91 27.0963 109.91H41.7114C42.5191 109.91 43.1739 109.256 43.1739 108.448V106.221C43.1739 105.414 42.5191 104.759 41.7114 104.759Z" fill="#FCEB89"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M25.6338 106.222V108.453L27.0963 109.916H41.7164L43.1789 108.453L41.8973 107.92L41.1837 108.634H27.624L26.9154 107.92V106.754L25.6338 106.222Z" fill="#E39507"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M138.794 111.353L140.367 107.071L138.895 102.794L137.301 101.025L135.789 102.794L134.396 107.071L135.889 111.353L137.382 112.615L137.462 112.549L138.794 111.353Z" fill="#FCEB89"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M134.396 107.071H136.07L137.256 108.388L137.372 112.615L135.884 111.353L134.396 107.071Z" fill="#E39507"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M140.346 107.071H138.673L137.487 108.388L137.371 112.615L138.859 111.353L140.346 107.071Z" fill="#FCA608"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M136.07 107.071L137.221 105.091L137.301 101.025L135.789 102.794L134.396 107.071H136.07Z" fill="#FFD266"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M138.694 107.071L137.543 105.091L137.302 101.025L138.97 102.794L140.367 107.071H138.694Z" fill="#FCEB89"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M7.14941 119.168L25.6344 108.453V106.221L7.14941 94.4709V119.168Z" fill="#640067"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M61.6646 119.168L43.1797 108.453V106.221L61.6646 94.4711V119.168Z" fill="#932D95"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M61.6641 94.4711L72.163 99.3411V94.4711H61.6641Z" fill="#C88AC5"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M61.6641 119.168L72.2635 114.298V119.168H61.6641Z" fill="#640067"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M68.4137 107.141L61.6641 119.168L72.2535 114.298L70.3336 112.665L68.4137 107.141Z" fill="#932D95"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M61.6641 94.4711L68.4137 107.141L70.213 101.628L72.163 99.3411L61.6641 94.4711Z" fill="#BD73BD"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M68.4137 107.141L61.6641 94.4711V119.168L68.4137 107.141Z" fill="#7A007C"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M22.0208 119.168L27.0969 109.916L25.6344 108.453L7.14941 119.168H22.0208Z" fill="#7A007C"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M41.7168 109.916L46.7929 119.168H61.6643L43.1793 108.453L41.7168 109.916Z" fill="#7A007C"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M46.7937 119.168L41.7177 109.915H27.0976L22.0215 119.168H46.7937Z" fill="#640067"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M27.0969 104.759L21.3574 94.4709H7.14941L25.6344 106.221L27.0969 104.759Z" fill="#932D95"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M21.3574 94.4711L27.0969 104.759H41.717L47.4565 94.4711H21.3574Z" fill="#AC49AE"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M47.4563 94.4711L41.7168 104.759L43.1793 106.221L61.6643 94.4711H47.4563Z" fill="#7A007C"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M5.63167 111.353L4.05859 107.071L5.53116 102.794L7.12434 101.025L8.63711 102.794L10.0343 107.071L8.5366 111.353L7.04393 112.614L6.96352 112.549L5.63167 111.353Z" fill="#FCEB89"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M10.034 107.071H8.36042L7.1693 108.388L7.05371 112.614L8.54135 111.353L10.034 107.071Z" fill="#E39507"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M4.0791 107.071H5.7527L6.93879 108.388L7.05438 112.614L5.56674 111.353L4.0791 107.071Z" fill="#FCA608"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M8.36135 107.071L7.20541 105.091L7.125 101.025L8.63777 102.794L10.0349 107.071H8.36135Z" fill="#FFD266"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M5.73219 107.071L6.8831 105.09L7.12434 101.025L5.45577 102.794L4.05859 107.071H5.73219Z" fill="#FCEB89"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M66.3481 121.318L61.6641 119.167H72.0625H72.2635V121.318V123.947V123.952V124.037L72.2535 124.032L72.163 123.992L72.158 123.987L72.0625 123.947L66.3481 121.318Z" fill="#7A007C"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M78.1781 121.318L82.8621 119.167H72.4637H72.2627V121.318V123.947V123.952V124.037L72.2727 124.032L72.3632 123.992L72.3682 123.987L72.4637 123.947L78.1781 121.318Z" fill="#BD73BD"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M137.332 121.324L131.482 123.982L82.7471 119.223L137.332 121.324Z" fill="#BD73BD"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M7.14941 121.324L12.9944 123.982L61.7348 119.223L7.14941 121.324Z" fill="#BD73BD"/>
<path d="M138.092 92.9226H6.33984V94.4706H138.092V92.9226Z" fill="#C88AC5"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M72.5302 10.127L78.1893 13.3937L83.8534 16.6655V23.1991V29.7377L78.1893 33.0045L72.5302 36.2763L66.8661 33.0045L61.207 29.7377V23.1991V16.6655L66.8661 13.3937L72.5302 10.127Z" fill="#640067"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M72.5292 8.93604L78.1883 12.2028L83.8524 15.4746V22.0082V28.5468L78.1883 31.8135L72.5292 35.0854L66.8651 31.8135L61.2061 28.5468V22.0082V15.4746L66.8651 12.2028L72.5292 8.93604Z" fill="#C88AC5"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M72.5294 13.6052L75.1429 15.113L77.7513 16.6207V19.6362V22.6517L75.1429 24.1594L72.5294 25.6672L69.916 24.1594L67.3076 22.6517V19.6362V16.6207L69.916 15.113L72.5294 13.6052Z" fill="#BD73BD"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M72.5293 25.6668V35.0852L72.9464 34.8439L78.1884 31.8184L79.7162 30.9339L80.3796 30.5519L81.1586 30.1046L83.8324 28.5566L77.7511 22.6513L75.6604 23.8625L75.6051 23.8927L75.1427 24.1591L73.0218 25.3853L72.9464 25.4306L72.5293 25.6668Z" fill="#A74FA6"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M72.5296 35.0852V25.6668L72.1125 25.4306L72.0371 25.3853L69.9162 24.1591L69.4538 23.8927L69.3935 23.8625L67.3078 22.6513L61.2266 28.5566L63.9003 30.1046L64.6793 30.5519L65.3427 30.9339L66.8655 31.8184L72.1125 34.8439L72.5296 35.0852Z" fill="#932D95"/>
</svg>
`;

export const HeartReaction = `<svg width="145" height="135" viewBox="0 0 145 135" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M72.0303 23.8943L99.2845 26.556L90.1907 5.36023L72.0303 23.8943Z" fill="#99379A"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M99.2842 26.5559L113.15 0L90.1904 5.36018L99.2842 26.5559Z" fill="#600061"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M99.2842 26.5559L138.13 15.1616L113.15 0L99.2842 26.5559Z" fill="#48014B"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M99.2842 26.5561L144.06 47.4521L138.13 15.1617L99.2842 26.5561Z" fill="#310131"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M72.0303 23.8856V35.9878L99.2845 26.556L72.0303 23.8856Z" fill="#600161"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M72.0303 68.9876L99.2845 26.5558L72.0303 35.9247V68.9876Z" fill="#580059"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M72.0303 68.9876L114.621 63.8097L99.2845 26.5558L72.0303 68.9876Z" fill="#410244"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M114.62 63.8097L144.06 47.4519L99.2842 26.5558L114.62 63.8097Z" fill="#490149"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M114.62 63.8097L130.943 82.9643L144.06 47.452L114.62 63.8097Z" fill="#310131"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M104.808 111.756L114.62 63.8097L130.943 82.9643L104.808 111.756Z" fill="#310131"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M72.0303 104.814L104.808 111.756L72.0303 134.115V104.814Z" fill="#310131"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M72.0303 104.814L114.621 63.8097L104.808 111.756L72.0303 104.814Z" fill="#490149"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M72.0303 68.9876V104.814L114.621 63.8097L72.0303 68.9876Z" fill="#610062"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M72.0298 23.8943L44.7754 26.556L53.8692 5.36023L72.0298 23.8943Z" fill="#600060"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M44.7756 26.5559L30.9102 0L53.8694 5.36018L44.7756 26.5559Z" fill="#A147A0"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M44.7757 26.5559L5.92969 15.1616L30.9103 0L44.7757 26.5559Z" fill="#7D017F"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M44.7755 26.5561L0 47.4521L5.92942 15.1617L44.7755 26.5561Z" fill="#7B007D"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M72.0298 23.8856V35.9878L44.7754 26.556L72.0298 23.8856Z" fill="#5E0163"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M72.0298 68.9876L44.7754 26.5558L72.0298 35.9247V68.9876Z" fill="#410244"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M72.0299 68.9876L29.4395 63.8097L44.7755 26.5558L72.0299 68.9876Z" fill="#59005A"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M29.4395 63.8097L0 47.4519L44.7755 26.5558L29.4395 63.8097Z" fill="#7F0380"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M29.4395 63.8097L13.1162 82.9643L0 47.452L29.4395 63.8097Z" fill="#5E0163"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M39.2521 111.756L29.4394 63.8097L13.1162 82.9643L39.2521 111.756Z" fill="#48004B"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M72.0298 104.814L39.252 111.756L72.0298 134.115V104.814Z" fill="#490149"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M72.0299 104.814L29.4395 63.8097L39.2521 111.756L72.0299 104.814Z" fill="#610062"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M72.0299 68.9876V104.814L29.4395 63.8097L72.0299 68.9876Z" fill="#7A007B"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M44.7755 26.5561L0 47.4521L5.92942 15.1617L44.7755 26.5561Z" fill="black" fill-opacity="0.058824"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M44.7757 26.5559L5.92969 15.1616L30.9103 0L44.7757 26.5559Z" fill="white" fill-opacity="0.211765"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M72.0298 23.8943L44.7754 26.556L53.8692 5.36023L72.0298 23.8943Z" fill="black" fill-opacity="0.109804"/>
</svg>
`;
