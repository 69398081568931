import {Component, Input, OnInit} from '@angular/core';
import {FormControl} from '@angular/forms';
import {DtLocation} from '@ui/components/dt-location-select/models/dt-location-select.model';
import {NzModalRef} from 'ng-zorro-antd/modal';

export interface FiltersConfig {
  location: boolean;
  radius: boolean;
  dates: boolean;
  age: boolean;
  keywords: boolean;
  ethnicity: boolean;
  values: Filters;
}

export interface Filters {
  location: DtLocation;
  radius: number;
  keywords: string;
  age: [number, number];
  ethnicity: string;
  startDate: string;
  endDate: string;
}

@Component({
  selector: 'app-filter-dialog',
  templateUrl: './filter-dialog.component.html',
  styleUrls: ['./filter-dialog.component.scss']
})
export class FilterDialogComponent implements OnInit {
  @Input() filterConfig: FiltersConfig;

  currentLocation: DtLocation = null;
  currentLocations: DtLocation[] = [];
  currentRadius = 50;
  ageRange = [18, 50];
  startDate: null | string = null;
  endDate: null | string = null;
  keywordsControl = new FormControl('');
  ethnicityFields = [
    {
      name: 'ethnicity',
      label: 'GENERAL',
      value: ''
    },
    {
      name: 'ethnicity',
      label: 'PROFILE.EDIT_DETAILS.ASIAN',
      value: 'asian'
    },
    {
      name: 'ethnicity',
      label: 'PROFILE.EDIT_DETAILS.AFRICAN',
      value: 'black'
    },
    {
      name: 'ethnicity',
      label: 'PROFILE.EDIT_DETAILS.CAUCASIAN',
      value: 'caucasian'
    },
    {
      name: 'ethnicity',
      label: 'PROFILE.EDIT_DETAILS.LATIN',
      value: 'latin'
    },
    {
      name: 'ethnicity',
      label: 'PROFILE.EDIT_DETAILS.INDIAN',
      value: 'indian'
    },
    {
      name: 'ethnicity',
      label: 'PROFILE.EDIT_DETAILS.MIDDLE_EASTERN',
      value: 'middle-eastern'
    },
    {
      name: 'ethnicity',
      label: 'PROFILE.EDIT_DETAILS.MIXED',
      value: 'mixed'
    },
    {
      name: 'ethnicity',
      label: 'PROFILE.EDIT_DETAILS.AMERICAN',
      value: 'american'
    }
  ];
  ethnicityFieldsControl = new FormControl('');

  private globalRadius = 15000;

  get isMaxRadius(): boolean {
    return this.currentRadius >= 300;
  }

  get ageTo(): string {
    if (this.ageRange[1] === 75) {
      return '75+';
    }
    return String(this.ageRange[1]);
  }

  constructor(private dialogRef: NzModalRef) {}

  ngOnInit(): void {
    this.applyConfig();
  }

  private applyConfig(): void {
    this.currentLocation = this.filterConfig?.values?.location ?? null;
    this.currentLocations = this.filterConfig?.values?.location ? [this.currentLocation] : [];
    this.currentRadius = this.filterConfig?.values?.radius ?? 50;
    this.ageRange = this.filterConfig?.values?.age ?? [18, 50];
    this.startDate = this.filterConfig?.values?.startDate ?? null;
    this.endDate = this.filterConfig?.values?.endDate ?? null;
    this.keywordsControl.patchValue(this.filterConfig?.values?.keywords ?? '');
    this.ethnicityFieldsControl.patchValue(this.filterConfig?.values?.ethnicity ?? '');
  }

  setSelectedLocation(location: DtLocation): void {
    this.currentLocation = location;
  }

  closeDialog(updated = false) {
    if (updated) {
      this.dialogRef.close({
        location: this.currentLocation,
        radius: this.isMaxRadius ? this.globalRadius : this.currentRadius,
        keywords: this.keywordsControl.value,
        age: this.ageRange,
        ethnicity: this.ethnicityFieldsControl.value,
        startDate: this.startDate,
        endDate: this.endDate
      });
    }
    this.dialogRef.close(null);
  }

  resetFilters(): void {
    delete this.currentLocation;
    this.currentRadius = 50;
    this.keywordsControl.patchValue('');
    this.ageRange = [18, 75];
    this.ethnicityFieldsControl.patchValue('');
    this.startDate = null;
    this.endDate = null;
  }
}
