import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Store} from '@ngrx/store';
import SendbirdChat, {
  ApplicationUserListQuery,
  ApplicationUserListQueryParams,
  MetaData,
  User,
  UserOnlineState
} from '@sendbird/chat';
import {
  GroupChannel,
  GroupChannelCreateParams,
  GroupChannelListOrder,
  GroupChannelListQuery,
  GroupChannelListQueryParams,
  GroupChannelModule,
  MyMemberStateFilter,
  SendbirdGroupChat,
  UnreadChannelFilter
} from '@sendbird/chat/groupChannel';
import {BaseMessageCreateParams} from '@sendbird/chat/lib/__definition';
import {
  BaseMessage,
  FileMessage,
  FileMessageCreateParams,
  MessageListParams,
  MessageRequestHandler,
  MessageSearchOrder,
  MessageSearchQuery,
  MessageSearchQueryParams,
  MessageType,
  MessageTypeFilter,
  PreviousMessageListQuery,
  PreviousMessageListQueryParams,
  ReactionEvent,
  ReplyType,
  UserMessage,
  UserMessageCreateParams
} from '@sendbird/chat/message';
import {DtProfileType} from '@ui/models/dt-profile.model';
import {chatActions} from 'projects/dating-app/src/app/core/store/actions';
import {catchError, forkJoin, from, map, Observable, of, switchMap, take, tap, throwError} from 'rxjs';

import {environment} from '../../../../../../environments/environment';
import {NotificationsService} from '../../../../../core/services/notifications.service';
import {AuthService} from '../../../../auth/services/auth.service';
import {BulkProfileResponse} from '../../profile/models/profile.model';
import {ProfileService} from '../../profile/services/profile.service';
import {activeChatActions} from '../active-chat/store/actions';
import {fromActiveChat} from '../active-chat/store/selectors';
import {
  AccessTokenResponse,
  CHAT_MESSAGES_QUERY_SIZE,
  ChatProfiletDisplayModel,
  CheckAllowedResponse,
  ConversationFilters,
  MessageCustomType,
  QuickMessage,
  ReportMessagePayload,
  TranslationsSettings
} from '../models/chat.model';
import {ChatContentService} from './chat-content.service';
import {ChatDisplayService} from './chat-display.service';

@Injectable({
  providedIn: 'root'
})
export class ChatService {
  private _sbChat: SendbirdGroupChat;
  static CHAT_PAGINATION_LIMIT = 15;
  defaultMessagesQuery = {
    reverse: false,
    messageTypeFilter: MessageTypeFilter.ALL,
    replyType: ReplyType.ALL,
    includeThreadInfo: false,
    includeParentMessageInfo: false,
    includeReactions: true
  };

  defaultChatQuery = {
    includeEmpty: true,
    myMemberStateFilter: MyMemberStateFilter.ALL,
    order: GroupChannelListOrder.LATEST_LAST_MESSAGE,
    includeMetaData: true,
    limit: ChatService.CHAT_PAGINATION_LIMIT
  };

  constructor(
    private http: HttpClient,
    private store: Store,
    private authService: AuthService,
    private profileService: ProfileService,
    private chatContentService: ChatContentService,
    private notificationsService: NotificationsService
  ) {
    this._sbChat = SendbirdChat.init({
      appId: environment.SENDBIRD.APP_ID,
      modules: [new GroupChannelModule()]
    }) as SendbirdGroupChat;
  }

  get currentUser(): User {
    return this._sbChat.currentUser;
  }

  get sbChat(): SendbirdGroupChat {
    return this._sbChat;
  }

  getChatByUlr(chatUrl: string): Observable<GroupChannel> {
    return from(this._sbChat.groupChannel.getChannel(chatUrl));
  }

  getChatsList(segment: ConversationFilters = null): Observable<GroupChannel[]> {
    const query: GroupChannelListQuery = this.getChatsListQuery(segment);
    return from(query.next());
  }

  async getAllChatsByQuery(query: GroupChannelListQuery) {
    let all: GroupChannel[] = [];
    while (query.hasNext) {
      const res = await query.next();
      all = [...all, ...res];
    }
    return all;
  }

  getChatsListQuery(segment: ConversationFilters = null): GroupChannelListQuery {
    const params: GroupChannelListQueryParams = {
      ...this.defaultChatQuery
    };
    if (segment == ConversationFilters.UNREAD) {
      params.unreadChannelFilter = UnreadChannelFilter.UNREAD_MESSAGE;
    }
    if (segment === ConversationFilters.NEW_CONNECTIONS) {
      const now = new Date().getTime();
      const weekBefore = now - 7 * 24 * 60 * 60 * 1000;
      params.createdAfter = weekBefore;
    }

    const query: GroupChannelListQuery = this._sbChat.groupChannel.createMyGroupChannelListQuery(params);
    return query;
  }

  getPinnedChats(segment: ConversationFilters = null): Observable<GroupChannel[]> {
    const query: GroupChannelListQuery = this.getPinnedChatsQuery(segment);
    return from(query.next());
  }

  getPinnedChatsQuery(segment: ConversationFilters = null): GroupChannelListQuery {
    const params: GroupChannelListQueryParams = {
      ...this.defaultChatQuery,
      metadataKey: this.pinnedKey,
      metadataValues: ['true']
    };
    if (segment == ConversationFilters.UNREAD) {
      params.unreadChannelFilter = UnreadChannelFilter.UNREAD_MESSAGE;
    }
    if (segment === ConversationFilters.NEW_CONNECTIONS) {
      const now = new Date().getTime();
      const weekBefore = now - 7 * 24 * 60 * 60 * 1000;
      params.createdAfter = weekBefore;
    }
    const query: GroupChannelListQuery = this._sbChat.groupChannel.createMyGroupChannelListQuery(params);
    return query;
  }

  updateCurrentUserData(avatarUrl: string, username: string, profileType: string): Observable<[User, object]> {
    return forkJoin([
      from(
        this._sbChat.updateCurrentUserInfo({
          profileUrl: avatarUrl,
          nickname: username
        })
      ),
      from(
        this._sbChat.currentUser.updateMetaData(
          {
            profileType
          },
          true
        )
      )
    ]);
  }

  getChatProfiles(profileIds: string[]): Observable<ChatProfiletDisplayModel[]> {
    return this.profileService.getBulkProfiles(profileIds).pipe(
      map((res: BulkProfileResponse) => {
        const profiles = res.profileDetails.map((p) => {
          const profile: ChatProfiletDisplayModel = {
            isFollowedByViewer: !!res.followedByViewer.find((u) => u === p.profileId),
            isFollowerToViewer: !!res.followerToViewer.find((u) => u === p.profileId),
            isFanToViewer: true,
            isViewerFan: true,
            likesFromMe: true,
            likesYou: true,
            profileShort: p,
            profileId: p.profileId,
            targetUserHasPremium: true,
            viewerUserHasPremium: true
          };
          return profile;
        });
        return profiles;
      })
    );
  }

  getTotalUnreadMessageCount(): Observable<number> {
    return from(this._sbChat.groupChannel.getTotalUnreadMessageCount());
  }

  connectUser(userId: string, token: string): Observable<User> {
    return from(this._sbChat.connect(userId, token));
  }

  createChat(senderProfileId: string, recepientProfileId: string): Observable<GroupChannel> {
    const params: GroupChannelCreateParams = {
      invitedUserIds: [senderProfileId, recepientProfileId],
      channelUrl: this.buildChatUrl(senderProfileId, recepientProfileId),
      operatorUserIds: [senderProfileId, recepientProfileId],
      isDistinct: true
    };

    // const parsed = {...params}
    return from(this._sbChat.groupChannel.createChannel(params)).pipe(
      // switchMap((chat) => chat.inviteWithUserIds([recepientProfileId])),
      tap((chat) => {
        this.createBackendChat(chat.url, recepientProfileId).subscribe();
      }),
      catchError((e) => {
        return of({} as GroupChannel);
      })
    );
  }

  buildChatUrl(profileIdUserA: string, profileIdUserB: string): string {
    const ids = [profileIdUserA, profileIdUserB].sort((a, b) => a.localeCompare(b));
    return ids.join('');
  }

  getRecipientIdByChatUrl(chatUrl: string): string {
    const currectUserId = this._sbChat.currentUser.userId;
    return chatUrl.replaceAll(currectUserId, '');
  }

  getChatUrlByRecipientId(profileId: string): string {
    const currectUserId = this._sbChat.currentUser.userId;
    return this.buildChatUrl(profileId, currectUserId);
  }

  getMessageHandler(params: UserMessageCreateParams, channelUrl: string): Observable<MessageRequestHandler> {
    return from(this._sbChat.groupChannel.getChannel(channelUrl)).pipe(
      switchMap((ch) => {
        return of(ch.sendUserMessage(params));
      })
    );
  }

  getChannelByUrl(url: string): Observable<GroupChannel> {
    return from(this._sbChat.groupChannel.getChannel(url)) as Observable<GroupChannel>;
  }

  deleteMessages(messages: BaseMessage[], chat: GroupChannel): Observable<void[]> {
    const requests: Array<Observable<void>> = [];
    messages.forEach((m) => {
      if (m.customType === MessageCustomType.MEDIA) {
        const keys = this.chatContentService.extractMediaKeys(m as UserMessage);
        const req = this.chatContentService
          .deleteFileByKey(m.channelUrl, keys[0])
          .pipe(switchMap(() => from(chat.deleteMessage(m))));
        requests.push(req);
      } else {
        requests.push(from(chat.deleteMessage(m)));
      }
    });
    if (requests?.length) {
      return forkJoin(requests).pipe(
        catchError((error) => {
          this.notificationsService.createWarningNotification(error.message);
          return throwError(() => error);
        })
      );
    }
    return of([]);
  }

  getSearchUsersQuery(queryValue: string, currentUserType: DtProfileType): ApplicationUserListQuery {
    const targetType = currentUserType === DtProfileType.BABY ? DtProfileType.DADDY : DtProfileType.BABY;
    const params: ApplicationUserListQueryParams = {
      nicknameStartsWithFilter: queryValue,
      metaDataKeyFilter: 'profileType',
      metaDataValuesFilter: [targetType],
      limit: ChatService.CHAT_PAGINATION_LIMIT
    };
    this._sbChat.createApplicationUserListQuery();
    const query = this._sbChat.createApplicationUserListQuery(params);
    return query;
  }

  getSearchMessagesQuery(queryValue: string, chatUrl?: string): MessageSearchQuery {
    const params: MessageSearchQueryParams = {
      keyword: queryValue,
      channelUrl: chatUrl,
      channelCustomType: '',
      limit: CHAT_MESSAGES_QUERY_SIZE,
      exactMatch: false,
      messageTimestampFrom: null,
      messageTimestampTo: null,
      order: MessageSearchOrder.TIMESTAMP,
      reverse: false
    };
    const query = this._sbChat.createMessageSearchQuery(params);
    return query;
  }

  getMessagesQuery(channel: GroupChannel) {
    const params: PreviousMessageListQueryParams = {
      limit: CHAT_MESSAGES_QUERY_SIZE,
      reverse: false,
      messageTypeFilter: MessageTypeFilter.ALL,
      replyType: ReplyType.ALL,
      includeThreadInfo: false,
      includeParentMessageInfo: true,
      includeReactions: true
    };
    const query = channel.createPreviousMessageListQuery(params);
    return query;
  }

  getMessages(channelUrl: string): Observable<BaseMessage[]> {
    return this.getChannelByUrl(channelUrl).pipe(
      switchMap((channel: GroupChannel) => {
        const query: PreviousMessageListQuery = this.getMessagesQuery(channel);
        return from(query.load());
      })
    );
  }

  getPreviousMessages(channelUrl: string, timestamp: number): Observable<any> {
    const params: MessageListParams = {
      prevResultSize: CHAT_MESSAGES_QUERY_SIZE,
      nextResultSize: 0,
      reverse: false,
      messageTypeFilter: MessageTypeFilter.ALL,
      replyType: ReplyType.ALL,
      includeThreadInfo: false,
      includeReactions: true,
      includeParentMessageInfo: true
    };
    return this.getChannelByUrl(channelUrl).pipe(
      switchMap((channel: GroupChannel) => channel.getMessagesByTimestamp(timestamp, params))
    );
  }

  likeMessage(message: BaseMessage, chat: GroupChannel): Observable<ReactionEvent> {
    const emojiKey = 'like';
    return from(chat.addReaction(message, emojiKey));
  }

  removeLikeFromMessage(message: BaseMessage, chat: GroupChannel): Observable<ReactionEvent> {
    const emojiKey = 'like';
    return from(chat.deleteReaction(message, emojiKey));
  }

  reportMessage(payload: ReportMessagePayload): Observable<void> {
    return this.http.put<void>(`${environment.API_URL}/profiles/profile/actions/rep-block`, payload);
  }

  getQuickMessages(): Observable<QuickMessage[]> {
    return this.http.get<QuickMessage[]>(`${environment.API_URL}/profiles/chat/quick-messages`);
  }

  givePersonalAccess(profileId: string): Observable<void> {
    return this.http.post<void>(`${environment.API_URL}/profiles/subscriptions/fan-personal`, {
      subscriberProfileId: profileId
    });
  }

  sendMessageFromActiveChat(
    params: BaseMessageCreateParams,
    type: MessageType,
    profileId?: string,
    chat?: GroupChannel
  ): void {
    if (chat) {
      this.store
        .select(fromActiveChat.selectReplyMessage)
        .pipe(take(1))
        .subscribe((replyMessage) => {
          if (replyMessage) {
            params.parentMessageId = replyMessage.messageId;
          }
          this.store.dispatch(activeChatActions.cancelReply());
          this.store.dispatch(activeChatActions.cancelGifSearch());
          if (type === MessageType.USER) {
            this.handleMessageSend(chat, params as UserMessageCreateParams);
            return;
          }
          if (type === MessageType.FILE) {
            this.handleFileMessageSend(chat, params as FileMessageCreateParams);
            return;
          }
        });
    } else {
      this.store.dispatch(chatActions.startNewChatWithMessage({messageToCreate: params, messageType: type, profileId}));
    }
  }

  sendMessage(params: UserMessageCreateParams, profileId: string, chat?: GroupChannel): void {
    if (!chat) {
      this.store.dispatch(
        chatActions.startNewChatWithMessage({messageToCreate: params, messageType: MessageType.USER, profileId})
      );
      return;
    }
    this.handleMessageSend(chat, params as UserMessageCreateParams);
  }

  handleMessageSend(chat: GroupChannel, params: UserMessageCreateParams): void {
    chat
      .sendUserMessage(params)
      .onSucceeded((message) => {
        if (message instanceof UserMessage) {
          this.store.dispatch(chatActions.messageRecieved({message: message, chat}));
          this.sendNewMessageEmail(chat);
        } else {
          console.error('Unknown message type');
        }
      })
      .onFailed((error, message) => {
        console.error(error);
      });
  }

  handleFileMessageSend(chat: GroupChannel, params: FileMessageCreateParams): void {
    chat
      .sendFileMessage(params)
      .onSucceeded((message) => {
        if (message instanceof FileMessage || message instanceof UserMessage) {
          this.store.dispatch(chatActions.messageRecieved({message: message, chat}));
          this.sendNewMessageEmail(chat);
        } else {
          console.error('Unknown message type');
        }
      })
      .onFailed((error, message) => {
        console.error(error);
      });
  }

  private sendNewMessageEmail(chat: GroupChannel): void {
    const receiver = chat.members.find((m) => m.userId !== this.authService.user.profileId);
    if (receiver && receiver.connectionStatus === UserOnlineState.OFFLINE) {
      this.http
        .post(`${environment.API_URL}/profiles/chat/track/message`, {
          profileId: receiver.userId,
          userId: ''
        })
        .subscribe();
    }
  }

  get pinnedKey(): string {
    const key = 'pinnedBy' + this._sbChat.currentUser.userId;
    return key;
  }

  updateChatPinState(chat: GroupChannel, value: boolean): Observable<MetaData> {
    const chatMetaData: MetaData = {
      [this.pinnedKey]: value ? 'true' : ''
    };
    return from(chat.updateMetaData(chatMetaData, true));
  }

  updateTranslationSettings(chat: GroupChannel, settings: TranslationsSettings): Observable<any[]> {
    const apiCalls = [];
    const {channelTranslationDisabled, preferredLanguage} = settings;
    let disabledUsers = ChatDisplayService.getDisableTranslationForUsersMetaData(chat);
    if (channelTranslationDisabled) {
      disabledUsers.push(this._sbChat.currentUser.userId);
    } else {
      disabledUsers = disabledUsers.filter((id) => id !== this._sbChat.currentUser.userId);
    }

    const chatMetaData: MetaData = {
      disableTranslationForUsers: JSON.stringify(Array.from(new Set(disabledUsers)))
    };

    apiCalls.push(from(chat.updateMetaData(chatMetaData, true)));

    if (preferredLanguage) {
      apiCalls.push(
        from(
          this._sbChat.currentUser.updateMetaData(
            {
              preferredLanguage
            },
            true
          )
        )
      );
    } else {
      apiCalls.push(from(this._sbChat.currentUser.deleteMetaData('preferredLanguage')));
    }

    return forkJoin(apiCalls);
  }

  markMessagesAsRead(chat: GroupChannel): Observable<void> {
    return from(chat.markAsRead());
  }

  createBackendChat(chatUrl: string, recipientId: string): Observable<any> {
    return this.http.post(`${environment.API_URL}/profiles/chat/settings`, {channelId: chatUrl, recipientId});
  }

  checkAllowed(recipientId: string): Observable<CheckAllowedResponse> {
    return this.http
      .get<CheckAllowedResponse>(`${environment.API_URL}/profiles/chat/check-allowed`, {
        params: {recipientId, ignoreErrorWarning: true}
      })
      .pipe(
        catchError(() =>
          of({
            conversationAllowed: false,
            isFanToViewer: false,
            isViewerFan: false,
            viewerUserHasPremium: false
          })
        )
      );
  }

  transferTokens(recipientProfileId: string, amount: number): Observable<void> {
    return this.http.post<void>(`${environment.API_URL}/profiles/chat/transfer-tokens`, {
      recipientProfileId,
      amount
    });
  }

  getSessionToken(): Observable<AccessTokenResponse> {
    return this.http.get<AccessTokenResponse>(`${environment.API_URL}/profiles/chat/access-token`);
  }

  updateNotificationSettings(chatUrl: string, newState: 'enable' | 'disable', endedAt: string) {
    return this.http.post(`${environment.API_URL}/profiles/chat/${chatUrl}/notifications/${newState}`, {endedAt});
  }
}
