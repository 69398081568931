import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {map, Observable} from 'rxjs';

import {environment} from '../../../environments/environment';
import {SettingsService} from '../../features/main/pages/settings/services/settings.service';
import {UserAgreement, UserBalance, UserPaymentsBlocks} from '../models/user.model';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  constructor(
    private http: HttpClient,
    private settingsService: SettingsService
  ) {}

  getUserBalance(): Observable<UserBalance> {
    return this.http.get<UserBalance>(`${environment.API_URL}/profiles/user/my/balance`).pipe(
      map((balance) => {
        balance.baseCurrency = this.settingsService.getCurrencyById(balance.baseCurrency as string);
        return balance;
      })
    );
  }

  getUserAgreement(): Observable<UserAgreement> {
    return this.http
      .get<{agreement: UserAgreement}>(`${environment.API_URL}/profiles/settings/my/agreement`)
      .pipe(map((response) => response.agreement));
  }

  getUserPaymentsBlocks(): Observable<UserPaymentsBlocks> {
    return this.http.get<UserPaymentsBlocks>(`${environment.API_URL}/profiles/store/request/blocks`).pipe(
      map((paymentsBlocks) => {
        const currentTime = new Date().getTime();
        return {
          request_lock_ps_purchase_with_tokens:
            currentTime + paymentsBlocks.request_lock_ps_purchase_with_tokens * 1000,
          request_lock_ps_purchase_with_card: currentTime + paymentsBlocks.request_lock_ps_purchase_with_card * 1000,
          request_lock_token_purchase: currentTime + paymentsBlocks.request_lock_token_purchase * 1000,
          request_lock_token_withdraw: currentTime + paymentsBlocks.request_lock_token_withdraw * 1000,
          request_lock_attach_card: currentTime + paymentsBlocks.request_lock_attach_card * 1000
        };
      })
    );
  }
}
