import {GroupChannel} from '@sendbird/chat/groupChannel';
import {MessageSearchQuery, PreviousMessageListQuery} from '@sendbird/chat/lib/__definition';
import {BaseMessage, UserMessage} from '@sendbird/chat/message';

import {ProfileMainInfoV2} from '../../../../profile/models/profile.model';
import {ActiveChatFooter, RequestState} from '../../../models/chat.model';

export const ACTIVE_CHAT_FEATURE_KEY = 'activeChat';

export interface ActiveChatState {
  isStarted: boolean;
  messagesState: {
    list: BaseMessage[];
    prevMessagesQuery: PreviousMessageListQuery;
    searchQuery: MessageSearchQuery;
    requestState: RequestState;
    selectedList: BaseMessage[];
    allLoaded: boolean;
  };
  recipientId: string;
  chatUrl: string;
  sbChat: GroupChannel;
  profile: ProfileMainInfoV2;
  replyMessage: UserMessage;
  footerState: ActiveChatFooter;
}

export const activeChatInitialState: ActiveChatState = {
  isStarted: false,
  recipientId: null,
  chatUrl: null,
  messagesState: {
    list: [],
    prevMessagesQuery: null,
    searchQuery: null,
    requestState: RequestState.INITIAL,
    selectedList: [],
    allLoaded: false
  },
  sbChat: null,
  profile: null,
  replyMessage: null,
  footerState: ActiveChatFooter.INITIAL
};
