import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {catchError, Observable, of} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoggerService {
  private lokiUrl = 'https://loki.hunsy.dev/loki/api/v1/push';

  constructor(private http: HttpClient) {}

  sendErrorLog(log: {userId: string; message: string}): Observable<void> {
    const payload = {
      streams: [
        {
          stream: {
            user_id: log.userId || 'guest'
          },
          values: [[new Date().toISOString(), log.message]]
        }
      ]
    };
    return this.http
      .post<void>(this.lokiUrl, payload, {
        params: {
          ignoreErrorWarning: true,
          ignoreSessionRedirect: true
        }
      })
      .pipe(
        catchError(() => {
          return of(null);
        })
      );
  }
}
