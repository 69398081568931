import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {DtDialogBodyComponent} from '@ui/components/dt-dialogs/components/dt-dialog/dt-dialog-body/dt-dialog-body.component';
import {DtDialogFooterComponent} from '@ui/components/dt-dialogs/components/dt-dialog/dt-dialog-footer/dt-dialog-footer.component';
import {DtDialogTitleComponent} from '@ui/components/dt-dialogs/components/dt-dialog/dt-dialog-title/dt-dialog-title.component';
import {NzIconModule} from 'ng-zorro-antd/icon';

import {DtDialogComponent} from './components/dt-dialog/dt-dialog.component';

@NgModule({
  declarations: [DtDialogComponent, DtDialogTitleComponent, DtDialogBodyComponent, DtDialogFooterComponent],
  exports: [DtDialogComponent, DtDialogTitleComponent, DtDialogBodyComponent, DtDialogFooterComponent],
  imports: [CommonModule, NzIconModule]
})
export class DtDialogModule {}
