import {Injectable, Type} from '@angular/core';
import {NzModalRef, NzModalService} from 'ng-zorro-antd/modal';

interface DtDialogsModel<DtType> {
  data?: Partial<DtType>;
  options?: {
    noMask?: boolean;
    noMaskClose?: boolean;
    noAnimation?: boolean;
    keyboardClose?: boolean;
  };
}

@Injectable({
  providedIn: 'root'
})
export class DtDialogService {
  constructor(private modalService: NzModalService) {}

  openDialog<DtT>(content: Type<DtT>, config?: DtDialogsModel<DtT>): NzModalRef {
    const modalRef = this.modalService.create({
      nzContent: content,
      nzData: config?.data,
      nzFooter: null,
      nzClosable: false,
      nzMask: !config?.options?.noMask,
      nzMaskClosable: !config?.options?.noMaskClose,
      nzNoAnimation: !!config?.options?.noAnimation,
      nzKeyboard: !!config?.options?.keyboardClose,
      nzAutofocus: null
    });

    const instance = modalRef.getContentComponent() as DtT;
    if (instance && config?.data) {
      Object.assign(instance, config.data);
    }

    return modalRef;
  }

  closeAllDialogs(): void {
    this.modalService.closeAll();
  }
}
