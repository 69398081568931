import {isPlatformServer} from '@angular/common';
import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {DtUtmService} from '@ui/services/dt-utm.service';
import {DtDeviceUtil} from '@ui/utils/dt-device.util';
import {Userpilot} from 'userpilot';

@Injectable({
  providedIn: 'root'
})
export class DtUserpilotService {
  private isProduction: boolean;

  private userId: string;
  private userInfo: object = {};

  constructor(
    @Inject(PLATFORM_ID) private platformId: string,
    private dtUtmService: DtUtmService
  ) {}

  init(key: string, isProduction: boolean): void {
    if (isPlatformServer(this.platformId)) {
      return;
    }
    Userpilot.initialize(key);
    this.isProduction = isProduction;
  }

  identify(userId: string, userInfo: object): void {
    const params = {
      ...userInfo,
      ...this.dtUtmService.getUtmList(),
      device: DtDeviceUtil.isMobile(navigator.userAgent) ? 'mobile' : 'desktop',
      environment: this.isProduction ? 'production' : 'development',
      referralSource: document.referrer
    };
    if (userId) {
      this.userId = userId;
      this.userInfo = params;
      Userpilot.identify(userId, params);
    } else {
      // Userpilot.anonymous(params);
    }
  }

  updateInfo(additionalInfo: object): void {
    const params = {
      ...this.userInfo,
      ...additionalInfo
    };
    this.identify(this.userId, params);
  }

  reload(): void {
    Userpilot.reload();
  }

  track(eventName: string): void {
    Userpilot.track(eventName);
  }
}
