import {createAction, props} from '@ngrx/store';
import {ApplicationUserListQuery} from '@sendbird/chat';
import {GroupChannelListQuery} from '@sendbird/chat/groupChannel';
import {MessageSearchQuery} from '@sendbird/chat/message';

import {ProfileMainInfoV2} from '../../../../profile/models/profile.model';
import {ChatListItemData, ConversationFilters, UserMessageWithProfile} from '../../../models/chat.model';

const actor = '[CHAT LIST]';

export const segmentChanged = createAction(`${actor} Segment changed`, props<{segment: ConversationFilters}>());

export const chatListLoadedSuccesfully = createAction(
  `${actor} List loaded succesfully`,
  props<{
    chats: ChatListItemData[];
    prevChatsQuery: GroupChannelListQuery;
    prevPinnedChatsQuery: GroupChannelListQuery;
  }>()
);

export const loadMoreChatsSuccess = createAction(
  `${actor} Load more chats succesfully`,
  props<{chats: ChatListItemData[]}>()
);

export const searchQueryChanged = createAction(
  `${actor} Search query changed`,
  props<{messagesQuery: MessageSearchQuery; usersQuery: ApplicationUserListQuery; queryText: string}>()
);

export const cancelSearch = createAction(`${actor} Cancel search`);

export const loadSearchResultsSuccess = createAction(
  `${actor} Search results loaded succesfully`,
  props<{messages: UserMessageWithProfile[]; users: ProfileMainInfoV2[]}>()
);

export const loadMoreSearchedMessagesSuccess = createAction(
  `${actor} Load more searched messages loaded succesfully`,
  props<{messages: UserMessageWithProfile[]}>()
);

export const loadMoreChats = createAction(`${actor} Load more chats`);

export const loadeMoreSearchedMessages = createAction(`${actor} Load more searched messages`);

export const loadMoreSearchedSearchedUsers = createAction(`${actor} Load more searched users`);
