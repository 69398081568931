import {isPlatformBrowser} from '@angular/common';
import {ErrorHandler, inject, Injectable, Injector, PLATFORM_ID} from '@angular/core';

import {environment} from '../../../environments/environment';
import {AuthService} from '../../features/auth/services/auth.service';
import {LoggerService} from '../services/logger.service';

@Injectable({
  providedIn: 'root'
})
export class GlobalErrorHandler implements ErrorHandler {
  private platformId = inject(PLATFORM_ID);

  constructor(
    private injector: Injector,
    private loggerService: LoggerService
  ) {}

  handleError(error: Error) {
    const authService = this.injector.get(AuthService);
    const errorLog = {userId: authService?.user?.userId, message: error.message};
    if (isPlatformBrowser(this.platformId)) {
      if (environment.production) {
        // this.loggerService.sendErrorLog(errorLog).subscribe();
      } else {
        console.error(error);
      }
    } else {
      console.log(errorLog);
    }
  }
}
