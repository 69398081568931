<ng-container *ngIf="media?.type">
  <img *ngIf="media?.type === 'image'" alt="" [src]="media.url" (load)="updateMediaLoad()" />
  <video
    *ngIf="media?.type === 'video'"
    #video
    loop
    playsinline
    [muted]="muted"
    [autoplay]="true"
    [controls]="controls"
    [src]="media.url"
    (loadeddata)="updateMediaLoad(video)"></video>
</ng-container>
<ng-container *ngIf="!media || !media?.type">
  <img src="assets/images/avatars/default-avatar.png" alt="" (load)="updateMediaLoad()" />
</ng-container>
