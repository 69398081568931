import {Action, createReducer, on} from '@ngrx/store';
import {chatActions} from 'projects/dating-app/src/app/core/store/actions';

import {activeChatActions} from '../../active-chat/store/actions';
import {chatPageActions} from '../actions';
import {MobileActiveTab, userDataInitailState, UserDataState} from '../state/user-data.state';

const featureReducer = createReducer(
  userDataInitailState,
  on(chatActions.userConnected, (state: UserDataState, {user}) => {
    return {
      ...state,
      user
    };
  }),

  on(activeChatActions.enterChat, (state: UserDataState) => {
    return {
      ...state,
      mobileTab: 'chat' as MobileActiveTab
    };
  }),

  on(chatPageActions.currentUserProfileLoaded, (state: UserDataState, {profile}) => {
    return {
      ...state,
      profile
    };
  }),

  on(activeChatActions.leaveConversation, (state: UserDataState) => {
    return {
      ...state,
      mobileTab: 'list' as MobileActiveTab
    };
  }),

  on(chatPageActions.chatsStartingDataLoadedSuccesfully, (state: UserDataState, {quickMessages}) => {
    return {
      ...state,
      quickMessages
    };
  })
);

export function UserDataReducer(state: UserDataState = userDataInitailState, action: Action) {
  return featureReducer(state, action);
}
