import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {catchError, Observable, throwError} from 'rxjs';

import {NotificationsService} from '../services/notifications.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  private errorsCodes = [404, 500, 502];

  constructor(private notificationsService: NotificationsService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if (this.errorsCodes.includes(error.status) && !request.params.get('ignoreErrorWarning')) {
          this.notificationsService.createWarningNotification(`Error code: ${error.status}`, 'Something went wrong');
        }
        return throwError(() => error);
      })
    );
  }
}
