import {createAction, props} from '@ngrx/store';

import {Profile, ProfileShort} from '../../models/profile.model';

const actor = '[Profile]';

export const loadProfile = createAction(`${actor} Load Profile`, props<{profileShort: ProfileShort}>());
export const loadProfileSuccess = createAction(`${actor} Load Profile Success`, props<{profile: Profile}>());
export const unsetProfile = createAction(`${actor} Unset Profile`);
export const toggleProfileFollowed = createAction(`${actor} Profile Followed Toggle`);
export const setProfileSentFanRequest = createAction(
  `${actor} Profile Sent Fan Request Set`,
  props<{value: boolean}>()
);
export const setProfileIsViewerFan = createAction(`${actor} Profile Is Viewer Fan Set`, props<{value: boolean}>());
export const toggleProfileLiked = createAction(`${actor} Profile Like Toggle`);
