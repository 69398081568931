import {User} from '@sendbird/chat';

import {ProfileMainInfoV2} from '../../../profile/models/profile.model';
import {QuickMessage} from '../../models/chat.model';

export const USER_DATA_FEATURE_KEY = 'userData';

export type MobileActiveTab = 'list' | 'chat';

export interface UserDataState {
  user: User;
  profile: ProfileMainInfoV2;
  mobileTab: MobileActiveTab;
  quickMessages: QuickMessage[];
}

export const userDataInitailState: UserDataState = {
  profile: null,
  user: null,
  mobileTab: 'list',
  quickMessages: []
};
