<div class="data-picker-container">
  <div class="date-picker">
    <i nz-icon nzType="ng-zorro:calendar"></i>
    <nz-date-picker
      nzFormat="yyyy-MM-dd HH:mm"
      [nzPlaceHolder]="'FROM' | dtTranslate"
      [nzShowTime]="{nzFormat: 'HH:mm'}"
      [nzShowNow]="!onlyFutureDates"
      [nzShowToday]="!onlyFutureDates"
      [nzAllowClear]="false"
      [nzDisabledDate]="disabledStartDate"
      [(ngModel)]="startValue"
      (ngModelChange)="startValueOnChange.emit(startValue)">
    </nz-date-picker>
    <i nz-icon nzType="ng-zorro:arrow-down"></i>
  </div>
  <div class="date-picker">
    <i nz-icon nzType="ng-zorro:calendar"></i>
    <nz-date-picker
      #endDatePicker
      nzFormat="yyyy-MM-dd HH:mm"
      [nzPlaceHolder]="'TO' | dtTranslate"
      [nzShowTime]="{nzFormat: 'HH:mm'}"
      [nzShowNow]="!onlyFutureDates"
      [nzShowToday]="!onlyFutureDates"
      [nzAllowClear]="false"
      [nzDisabledDate]="disabledEndDate"
      [(ngModel)]="endValue"
      (ngModelChange)="endValueOnChange.emit(endValue)">
    </nz-date-picker>
    <i nz-icon nzType="ng-zorro:arrow-down"></i>
  </div>
</div>
