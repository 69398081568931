import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {FormControl} from '@angular/forms';
import {UserMessageCreateParams} from '@sendbird/chat/message';
import {DtPost} from '@ui/components/dt-post/models/dt-post.model';
import {DtProfileShort} from '@ui/models/dt-profile.model';
import {NzModalRef} from 'ng-zorro-antd/modal';
import {AuthService} from 'projects/dating-app/src/app/features/auth/services/auth.service';
import {MessageCustomType} from 'projects/dating-app/src/app/features/main/pages/chat/models/chat.model';
import {catchError, debounceTime, map, Observable, startWith, Subject, switchMap, takeUntil} from 'rxjs';

import {environment} from '../../../../../../environments/environment';
import {ChatService} from '../../../../../features/main/pages/chat/services/chat.service';
import {Profile, ProfileShort} from '../../../../../features/main/pages/profile/models/profile.model';
import {ProfileService} from '../../../../../features/main/pages/profile/services/profile.service';

@Component({
  selector: 'app-share-dialog',
  templateUrl: './share-dialog.component.html',
  styleUrls: ['./share-dialog.component.scss']
})
export class ShareDialogComponent implements OnInit, OnDestroy {
  @Input() post: DtPost;
  @Input() profile: Profile;

  link: string;
  linkIsCopied = false;

  searchControl = new FormControl('');

  displayProfiles: DtProfileShort[] = [];
  displayProfilesLoaded = false;
  selectedProfileId: string;

  private destroyed$ = new Subject<void>();

  constructor(
    private dialogRef: NzModalRef,
    private chatService: ChatService,
    private authService: AuthService,
    private profileService: ProfileService
  ) {}

  ngOnInit(): void {
    this.buildLink();
    this.initSearch();
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  private buildLink() {
    if (this.post) {
      this.link = `${environment.APP_URL}/profile/${this.post?.profile?.username}?post=${this?.post?.postUUID}`;
      return;
    }

    if (this.profile) {
      this.link = `${environment.APP_URL}/profile/${this.profile?.profileSummary?.username}`;
    }
  }

  copyLink(input: HTMLInputElement) {
    input.select();
    document.execCommand('copy');
    input.setSelectionRange(0, 0);
    this.linkIsCopied = true;
  }

  private initSearch() {
    this.searchControl.valueChanges
      .pipe(
        startWith(''),
        debounceTime(300),
        switchMap((value) => {
          this.displayProfiles = [];
          this.displayProfilesLoaded = false;
          if (value) {
            return this.profileService.searchShareProfiles(value).pipe(
              catchError(() => {
                return [];
              })
            );
          } else {
            return this.loadActiveChats();
          }
        }),
        takeUntil(this.destroyed$)
      )
      .subscribe((profiles) => {
        this.displayProfiles = profiles;
        this.displayProfilesLoaded = true;
      });
  }

  private loadActiveChats(): Observable<ProfileShort[]> {
    return this.chatService.getChatsList().pipe(
      map((list) => {
        return list.map((chat) => {
          const user = chat.members.find((member) => member.userId !== this.authService.user.profileId);
          return {
            profileId: user.userId,
            username: user.nickname,
            avatar: {url: user.profileUrl, type: 'image'}
          };
        });
      })
    );
  }

  toggleCheck(profile: DtProfileShort): void {
    this.selectedProfileId = profile.profileId;
  }

  sendMessage(): void {
    const post: DtPost = this.post;
    const profile: Profile = this.profile;

    let messageType = MessageCustomType.POST;
    let messageData;
    if (post) {
      messageData = JSON.stringify({
        postId: post?.postUUID,
        ownerId: post?.profile?.profileId,
        ownerName: (post?.profile as DtProfileShort)?.username,
        ownerProfileType: (post.profile as DtProfileShort).profileType
      });
    }

    if (profile) {
      messageType = MessageCustomType.PROFILE;
      messageData = JSON.stringify({
        profileId: profile.profileId,
        username: profile.profileSummary.username,
        profileType: profile?.profileSummary.type
      });
    }

    const params: UserMessageCreateParams = {
      customType: messageType,
      data: messageData,
      message: 'custom'
    };
    const chatUrl = this.chatService.buildChatUrl(this.authService.user.profileId, this.selectedProfileId);
    this.chatService.getChannelByUrl(chatUrl).subscribe({
      next: (chat) => {
        this.chatService.sendMessage(params, this.selectedProfileId, chat);
        this.closeDialog(true);
      },
      error: (e) => {
        // not found, create a new one
        if (e.code === 400201) {
          this.chatService.sendMessage(params, this.selectedProfileId, null);
          this.closeDialog(true);
        }
      }
    });
  }

  closeDialog(updated: boolean) {
    this.dialogRef.close(updated);
  }
}
