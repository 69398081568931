import {Component, ContentChild, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {DtDialogBodyComponent} from '@ui/components/dt-dialogs/components/dt-dialog/dt-dialog-body/dt-dialog-body.component';
import {DtDialogFooterComponent} from '@ui/components/dt-dialogs/components/dt-dialog/dt-dialog-footer/dt-dialog-footer.component';
import {NzModalRef} from 'ng-zorro-antd/modal';

import {DtDialogTitleComponent} from './dt-dialog-title/dt-dialog-title.component';

@Component({
  selector: 'dt-dialog',
  templateUrl: './dt-dialog.component.html',
  styleUrls: ['./dt-dialog.component.scss']
})
export class DtDialogComponent implements OnInit {
  @Input() closable = true;

  @Output() closeDialog = new EventEmitter<boolean>();

  @ContentChild(DtDialogTitleComponent) title: DtDialogTitleComponent;
  @ContentChild(DtDialogBodyComponent) body: DtDialogBodyComponent;
  @ContentChild(DtDialogFooterComponent) footer: DtDialogFooterComponent;

  constructor(private dialogRef: NzModalRef) {}

  ngOnInit(): void {}

  handleCloseDialog(updated = false) {
    this.dialogRef.close(updated);
    this.closeDialog.emit(updated);
  }
}
