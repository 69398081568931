import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {catchError, Observable, switchMap, throwError} from 'rxjs';

import {AuthService} from '../../features/auth/services/auth.service';

@Injectable()
export class SessionInterceptor implements HttpInterceptor {
  constructor(
    private router: Router,
    private authService: AuthService
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    request = request.clone({
      withCredentials: true
    });
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 403 || error.status === 401 || error.status === 0) {
          const isRefreshRequest = request.url.includes('/token/refresh');
          if (!isRefreshRequest) {
            return this.handleSessionError(request, next);
          }
        }
        return throwError(() => error);
      })
    );
  }

  private handleSessionError(request: HttpRequest<any>, next: HttpHandler) {
    return this.authService.refreshToken().pipe(
      switchMap(() => {
        return next.handle(request);
      }),
      catchError((err) => {
        if (!request.params.get('ignoreSessionRedirect')) {
          this.router.navigateByUrl('/auth').then();
        }
        return throwError(() => err);
      })
    );
  }
}
